
































import { Component } from "vue-property-decorator"
import VueTrans from "@/mixins/VueTrans.vue"
import Button from "@/components/inputs/Button.vue"
import VueI18n from "vue-i18n"
import TemplateModule from "@/store/modules/form/TemplateModule"
import { Template } from "@/model/interfaces/form/template/template"

@Component({
  components: { Button }
})
export default class TemplateDescriptorForm extends VueTrans {
  fileRule = [
    (value: File): boolean | string | VueI18n.LocaleMessages => !value || value.size < 100000000 || this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.file.error.max")
  ]
  transKey = "dashboard.surveymanagement.template.fileForm."

  get fileLoading(): boolean {
    return TemplateModule.fileLoading
  }

  get fileDialogForm(): boolean {
    return TemplateModule.fileDialogForm
  }

  get file(): File | null {
    return TemplateModule.file
  }

  set file(File: File | null) {
    TemplateModule.setFile(File)
  }

  get fileError(): string | null {
    return TemplateModule.fileError
  }

  get templateDialogForm(): Template | null {
    return TemplateModule.templateDialogForm
  }

  async uploadFile(): Promise<void> {
    const isDone = await TemplateModule.postFileDescriptor()
    if (isDone) {
      this.close()
    }
  }

  close(): void {
    TemplateModule.setFile(null)
    TemplateModule.setFileError(null)
    TemplateModule.setFileLoading(false)
    TemplateModule.setFileDialogForm(false)
  }
}
