






import { Component, Vue } from "vue-property-decorator"

import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button
  }
})
export default class ButtonLogout extends Vue {
  inheritAttrs = false

  isScrollbarEnhanced = false

  toggleScrollbarSize() {
    this.isScrollbarEnhanced = !this.isScrollbarEnhanced
    if (this.isScrollbarEnhanced) {
      document.body.classList.add("scrollbar--large")
    } else {
      document.body.classList.remove("scrollbar--large")
    }
  }
  get text(): string {
    return this.isScrollbarEnhanced ? this.$i18n.t("scrollbar.hide").toString() : this.$i18n.t("scrollbar.show").toString()
  }
}
