import { CanContent } from "@/model/interfaces/submission/templateSubmission/CanContent"
import { Vue } from "vue-property-decorator"

export default class CanService {
  public static URI = "can_contents"

  static async patch(can: CanContent): Promise<CanContent> {
    const response = await Vue.$axios.patch(this.URI + "/" + can.id, can)
    return response.data
  }
}
