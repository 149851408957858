

























































































import { Component, Ref, Vue } from "vue-property-decorator"
import TemplateModule from "@/store/modules/form/TemplateModule"

import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"
import { Template } from "@/model/interfaces/form/template/template"
import Button from "@/components/inputs/Button.vue"
import TemplateDescriptorForm from "@/components/dashboard/SurveyManagement/TemplateDescriptorForm.vue"
import TemplateDescriptorType from "@/model/enum/form/template/templateDescriptorType"
import TemplateService from "@/services/form/TemplateService"
import { TemplateDescriptor } from "@/model/interfaces/form/template/templateDescriptor"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
import ConfirmDeletionDialog from "@/components/elements/dialog/ConfirmDeletionDialog.vue"
import TextField from "@/components/inputs/TextField.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import TemplateYearForm from "@/components/dashboard/SurveyManagement/TemplateYearForm.vue"

@Component({
  computed: {
    TemplateDescriptorType() {
      return TemplateDescriptorType
    }
  },
  components: {
    TemplateYearForm,
    TextField,
    ConfirmDeletionDialog,
    ButtonGroup,
    TemplateDescriptorForm,
    Button,
    LayoutLoadable,
    ValidationObserver,
    ValidationProvider
  }
})
export default class SurveyManagementTemplate extends Vue {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>

  readonly HEADER = [
    this.$t("dashboard.surveymanagement.template.columns.koboId"),
    this.$t("dashboard.surveymanagement.template.columns.title"),
    this.$t("dashboard.surveymanagement.template.columns.year"),
    this.$t("dashboard.surveymanagement.template.columns.created"),
    this.$t("dashboard.surveymanagement.template.columns.lastUpdate"),
    this.$t("dashboard.surveymanagement.template.columns.numberOfSubmissions"),
    this.$t("dashboard.surveymanagement.template.columns.url"),
    this.$t("dashboard.surveymanagement.template.columns.fileReport"),
    this.$t("dashboard.surveymanagement.template.columns.fileSummary")
  ]

  get templates(): Template[] {
    return TemplateModule.templates
  }

  templateDescriptorByType(template: Template, type: TemplateDescriptorType): TemplateDescriptor | null {
    return TemplateService.getTemplateDescriptorByType(template, type)
  }

  templateDescriptorContentUrlByType(template: Template, type: TemplateDescriptorType): string | null {
    return TemplateService.getTemplateDescriptorByType(template, type)?.contentUrl ?? null
  }

  templateDescriptorFileNameByType(template: Template, type: TemplateDescriptorType): string | null {
    return TemplateService.getTemplateDescriptorByType(template, type)?.filePath ?? "templateDescriptor.json"
  }

  get loading(): boolean {
    return TemplateModule.loading
  }

  get fileLoading(): boolean {
    return TemplateModule.fileLoading
  }

  async mounted(): Promise<void> {
    await TemplateModule.getTemplates()
  }

  openFormDialog(template: Template, type: TemplateDescriptorType): void {
    TemplateModule.setTemplateDialogForm(template)
    TemplateModule.setTemplateDescriptorTypeDialogForm(type)
    TemplateModule.setFileDialogForm(true)
  }

  async remove(template: Template, type: TemplateDescriptorType): Promise<void> {
    TemplateModule.setFileLoading(true)
    TemplateModule.setTemplateDescriptorTypeDialogForm(type)
    await TemplateModule.deleteFileDescriptor(template)
    await TemplateModule.getTemplates()
    TemplateModule.setFileLoading(false)
  }
}
