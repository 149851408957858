




















































































































import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsCategoryBanner from "../reusableComponents/GlobalIndicatorsCategoryBanner.vue"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import { GlobalIndicatorsCategoryMainTrends } from "@/model/interfaces/globalIndicators/globalIndicatorsCategoryMainTrends"
import GlobalIndicatorsFigure from "../reusableComponents/GlobalIndicatorsFigure.vue"
import GlobalIndicatorsChart from "../reusableComponents/GlobalIndicatorsChart.vue"
import { GlobalIndicatorsChartDefinitions } from "@/services/globalIndicators/GlobalIndicatorsChartDefinitions"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import GlobalIndicatorsChartLegend from "../reusableComponents/GlobalIndicatorsChartLegend.vue"
@Component({
  components: {
    GlobalIndicatorsCategoryBanner,
    GlobalIndicatorsFigure,
    GlobalIndicatorsChart,
    GlobalIndicatorsChartLegend
  }
})
export default class GlobalIndicatorsEmploymentProfile extends Vue {
  get titleStats(): GlobalIndicatorsCategoryMainTrends | null {
    return GlobalIndicatorsModule.employmentSurveyedTitleStats
  }

  get employmentSurveyedChartsConfig() {
    return GlobalIndicatorsModule.surveyedChartsConfig
  }

  getChartLegends(chartId: GlobalIndicatorsDatasetKey) {
    return GlobalIndicatorsChartDefinitions.getChartDefinitions()[chartId]?.legend
  }

  get employmentMultipliersSVGs() {
    return GlobalIndicatorsModule.secondaryEmploymentMultipliersSVGs
  }
}
