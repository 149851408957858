import TemplateSubmissionFilter from "@/model/interfaces/submission/templateSubmission/templateSubmissionFilter"

export default class TemplateSubmissionFilterService {
  static normalizeSearchFilter(search: string, filter: TemplateSubmissionFilter): void {
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter.id = intValue
    }
    filter.country = search
    filter.currency = search
    filter.dutyStation = search
    filter.focalPointEmail = search
    filter.focalPointName = search
    filter.focalPointPartner = search
  }

  static normalizeFilters(filter: TemplateSubmissionFilter): TemplateSubmissionFilter {
    const normalizedFilter: TemplateSubmissionFilter = {}
    if (filter.year) {
      normalizedFilter.year = filter.year
    }
    if (filter.status) {
      normalizedFilter.status = filter.status.map(value => value.toLowerCase())
    }
    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }
    return normalizedFilter
  }
}
