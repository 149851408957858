import UserService from "@/services/UserService"
import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import { User } from "@/model/interfaces/user/user"
import VuexModuleSearch from "../VuexModuleSearch"

@Module({ dynamic: true, store, name: "UserModule", namespaced: true })
class UserModule extends VuexModuleSearch {
  public user: any = null
  public users: User[] = []

  @Action
  public async getUsers() {
    await UserService.fetchUsers().then(response => {
      this.setUsers(response)
    })
  }

  @Mutation
  private setUsers(users: User[]) {
    this.users = users
  }

  @Mutation
  private setUser(user: User) {
    const existsAtIndex = this.users.findIndex(u => u.id === user.id)

    if (existsAtIndex !== -1) {
      this.users[existsAtIndex] = user
    } else {
      this.users.push(user)
    }

    this.users = [...this.users]
  }

  @Mutation
  private removeUser(user: User) {
    const existsAtIndex = this.users.findIndex(u => u.id === user.id)

    if (existsAtIndex !== -1) {
      this.users.splice(existsAtIndex, 1)
    }
  }

  @Action
  public async registerUser(user: User) {
    await UserService.registerUser(user).then((user: User) => {
      getModule(UserModule).setUser(user)
    })
  }

  @Action
  public async editUser(user: User) {
    await UserService.editUser(user).finally(() => {
      getModule(UserModule).setUser(user)
    })
  }

  @Action
  public async deleteUser(user: User) {
    await UserService.deleteUser(user).finally(() => {
      getModule(UserModule).removeUser(user)
    })
  }
}

export default getModule(UserModule)
