import { Vue } from "vue-property-decorator"
import { AxiosResponse } from "axios"

export default class SecurityService {
  static async login(user: { email: string; password: string }): Promise<AxiosResponse> {
    return Vue.$axios.post("authentication_token", user)
  }

  static async logout(): Promise<AxiosResponse> {
    return Vue.$axios.get("logout")
  }
}
