import { GlobalIndicatorsCategoryMainTrends } from "@/model/interfaces/globalIndicators/globalIndicatorsCategoryMainTrends"

export class GlobalIndicatorsTitleStats {
  public static getStats(start: number, end: number, IndicatorName: string): GlobalIndicatorsCategoryMainTrends {
    const diff = ((end - start) / start) * 100
    return {
      start: {
        label: "Baseline",
        value: start.toString()
      },
      end: {
        label: "Endline",
        value: end.toString()
      },
      result: {
        label: IndicatorName,
        value: diff.toFixed(2) + "%",
        trend: diff > 0 ? "up" : "down"
      }
    }
  }
}
