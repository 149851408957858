import { Vue } from "vue-property-decorator"
import GitInfo from "@/model/interfaces/gitInfo"

export default class GitInfoService {
  private static URI = "git_infos"

  static async get(): Promise<GitInfo> {
    const response = await Vue.$axios.get(this.URI)

    return response.data as GitInfo
  }
}
