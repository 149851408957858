import AdminPanelView from "@/views/AdminPanel/AdminPanelView.vue"
import NotificationView from "@/views/AdminPanel/Notification/NotificationView.vue"
import UserManagementView from "@/views/AdminPanel/UserManagement/UserManagementView.vue"
import WorkInProgress from "@/components/elements/WorkInProgress.vue"

export const routesAdmin = {
  path: "/admin",
  component: AdminPanelView,
  meta: {
    requiresAuth: true,
    requiresPrivacyNoticyAcceptance: true,
    requiresRoleAdmin: true
  },
  children: [
    {
      path: "",
      name: "admin",
      redirect: { name: "notification" }
    },
    {
      path: "notification",
      name: "notification",
      meta: {
        title: "notification.admin.title",
        requiresRoleSupportTeam: true
      },
      component: NotificationView
    },
    {
      path: "usermanagement",
      name: "usermanagement",
      component: UserManagementView,
      props: { title: "User Management" }
    },
    {
      path: "useraccess",
      name: "useraccess",
      component: WorkInProgress,
      props: { title: "User Access" },
      meta: {
        requiresRoleSupportTeam: true
      }
    },
    {
      path: "logstats",
      name: "logstats",
      component: WorkInProgress,
      props: { title: "Log Stats" },
      meta: {
        requiresRoleSupportTeam: true
      }
    }
  ]
}
