import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import {
  mdiAccountMultipleOutline,
  mdiAccountOutline,
  mdiAlert,
  mdiArrowLeft,
  mdiBellOutline,
  mdiCached,
  mdiCalendar,
  mdiCheck,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClockFast,
  mdiClose,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDelete,
  mdiDownload,
  mdiExitToApp,
  mdiEye,
  mdiEyeOff,
  mdiFindReplace,
  mdiGreaterThanOrEqual,
  mdiLessThanOrEqual,
  mdiMagnify,
  mdiMenuDown,
  mdiOpenInNew,
  mdiPencil,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiRedo,
  mdiRefresh,
  mdiRestore,
  mdiContentSave,
  mdiUpload,
  mdiUndo
} from "@mdi/js"
import OverviewIcon from "@/components/icon/OverviewIcon.vue"

Vue.use(Vuetify)

/*
theme.options.customProperties: generates css var
primary color --> var(--v-primary-base);
primary: because it's primary field
base: among 11 values:
*/

export enum LVColors {
  ALERT = "#dc143c",
  DISCRETE = "#464a53",
  PRIMARY_GREY = "#e6e6e6",
  LIGHT_GREY_1 = "#e9ebec",
  LIGHT_GREY_2 = "#828282",
  LIGHT_GREY_3 = "#c4c4c4",
  LIGHT_GREY_4 = "#c2c9d1",
  LIGHT_GREY_5 = "#fafafa",
  PRIMARY_BLUE = "#0072bc",
  DARK_BLUE_1 = "#044f85",
  DARK_BLUE_2 = "#18375f",
  LIGHT_BLUE_1 = "#589be5",
  LIGHT_BLUE_2 = "#8ebeff",
  LIGHT_BLUE_3 = "#DCE9FF",
  PRIMARY_RED = "#EF4A60",
  LIGHT_RED = "#FFE7E8",
  PRIMARY_GREEN = "#6AE8CC",
  DARK_GREEN = "#00B398",
  LIGHT_GREEN = "#CFFFF2",
  WHITE = "#fff",
  BLACK = "#000000",
  SUCCESS = "#008000",
  VIOLET = "#3e66fb",
  WARNING = "#ffcc00"
}

export const THEMES = {
  light: {
    alert: LVColors.ALERT,
    discrete: LVColors.DISCRETE,
    grey: {
      base: LVColors.PRIMARY_GREY,
      lighten1: LVColors.LIGHT_GREY_1,
      lighten2: LVColors.LIGHT_GREY_2,
      lighten3: LVColors.LIGHT_GREY_3,
      lighten4: LVColors.LIGHT_GREY_4,
      lighten5: LVColors.LIGHT_GREY_5,
    },
    green: {
      lighten1: LVColors.PRIMARY_GREEN,
      lighten2: LVColors.LIGHT_GREEN,
    },
    navy: LVColors.DARK_BLUE_2,
    primary: {
      darken1: LVColors.DARK_BLUE_1,
      base: LVColors.PRIMARY_BLUE,
      lighten1: LVColors.LIGHT_BLUE_1,
      lighten2: LVColors.LIGHT_BLUE_3
    },
    red: {
      lighten1: LVColors.PRIMARY_RED,
      lighten2: LVColors.LIGHT_RED
    },
    primaryText: LVColors.WHITE,
    secondary: LVColors.BLACK,
    success: LVColors.SUCCESS,
    violet: {
      base: LVColors.VIOLET
    },
    warning: LVColors.WARNING
  }
}

export default new Vuetify({
  theme: {
    options: { customProperties: true }, // Generates CSS var
    dark: false,
    themes: THEMES
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      // KEEP THEM SORTED BY ALPHA
      mdiAccountMultipleOutline,
      mdiAccountOutline,
      mdiAlert,
      mdiArrowLeft,
      mdiBellOutline,
      mdiCached,
      mdiCalendar,
      mdiCheck,
      mdiCheckboxBlankOutline,
      mdiCheckboxMarkedOutline,
      mdiClockFast,
      mdiClose,
      mdiCloseCircle,
      mdiContentCopy,
      mdiDelete,
      mdiDownload,
      mdiExitToApp,
      mdiEye,
      mdiEyeOff,
      mdiFindReplace,
      mdiMagnify,
      mdiMenuDown,
      mdiOpenInNew,
      mdiPencil,
      mdiPlus,
      mdiPlusCircleOutline,
      mdiRedo,
      mdiRefresh,
      mdiRestore,
      mdiContentSave,
      mdiUpload,
      mdiUndo,
      mdiLessThanOrEqual,
      mdiGreaterThanOrEqual,
      overview: {
        component: OverviewIcon
      }
    }
  }
})
