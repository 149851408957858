




















import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class LayoutNavbarHorizontalItemMultiple extends Vue {
  @Prop({ default: [] })
  tos!: Array<{
    route: string
    name: string
  }>

  tabFocus = false

  @Watch("tos")
  @Watch("$route.name")
  handleFocus() {
    if (this.$route.name) {
      this.tabFocus = this.tos.map(item => item.route).includes(this.$route.name)
      if (this.tabFocus) {
        ;(this.$refs.LayoutNavbarHorizontalItemMultiple as any).toggle()
      }
    }
  }
  mounted(): void {
    this.handleFocus()
  }
}
