


























import { GlobalIndicatorsCategoryMainTrends } from "@/model/interfaces/globalIndicators/globalIndicatorsCategoryMainTrends"
import { Component, Prop, Vue } from "vue-property-decorator"
@Component
export default class GlobalIndicatorsCategoryBanner extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly subtitle: string | undefined
  @Prop() readonly stats: GlobalIndicatorsCategoryMainTrends | undefined
}
