

























import { Component, Vue } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import CanDictionaryModule from "@/store/modules/submission/CanDictionaryModule"
import { Dictionary } from "@/model/interfaces/submission/templateSubmission/Dictionary"
import DictionaryItemForm from "../../../../views/TemplateSubmission/ReportDataPublication/DictionaryItemForm.vue"
import { DictionaryItemType } from "@/model/interfaces/submission/templateSubmission/DictionaryItemType"

import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button,
    DictionaryItemForm
  }
})
export default class CanDictionary extends Vue {
  get loading(): boolean {
    return CanDictionaryModule.loading
  }
  mounted() {
    CanDictionaryModule.fetch()
  }

  get size(): number {
    return CanDictionaryModule.size
  }

  get headers(): DataTableHeader[] {
    return [
      { text: "", value: "actions", sortable: false },
      { text: "Key", value: "key", sortable: true, width: "20%" },
      { text: "Value", value: "value", sortable: true }
    ]
  }

  get items(): Dictionary {
    return CanDictionaryModule.items
  }

  showItemEditForm = false
  itemToBeEdited: DictionaryItemType | null = null
  triggerCreateItem(): void {
    this.itemToBeEdited = null
    this.showItemEditForm = true
  }

  triggerEditItem(item: DictionaryItemType): void {
    this.itemToBeEdited = { ...item }
    this.showItemEditForm = true
  }

  triggerDeleteItem(item: DictionaryItemType) {
    CanDictionaryModule.delete(item)
  }
}
