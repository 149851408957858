import { Vue } from "vue-property-decorator"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { AxiosResponse } from "axios"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import HarmonizationSettingSubmissionUnitFilter from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnitFilter"
import { HarmonizationSettingSubmissionUnit } from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnit"
import HarmonizationSettingsUnitFilterService from "@/services/harmonizationSettings/harmonizationSettingsUnit/HarmonizationSettingsUnitFilterService"

export default class HarmonizationSettingsUnitService {
  private static URI = "harmonization_setting_submission_units"

  static async getByTemplateSubmission(
    filter: HarmonizationSettingSubmissionUnitFilter,
    pagination: Pagination,
    templateSubmission: TemplateSubmission
  ): Promise<{ units: HarmonizationSettingSubmissionUnit[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI, {
      params: {
        ...HarmonizationSettingsUnitFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  static async get(filter: HarmonizationSettingSubmissionUnitFilter, pagination: Pagination): Promise<{ units: HarmonizationSettingSubmissionUnit[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...HarmonizationSettingsUnitFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  private static getResultWihPagination(response: AxiosResponse, pagination: Pagination) {
    return {
      units: response.data["hydra:member"],
      pagination: PaginationUtils.getPaginationResponse(response, pagination)
    }
  }

  static async delete(ham: HarmonizationSettingSubmissionUnit): Promise<AxiosResponse> {
    return await Vue.$axios.delete(this.URI + "/" + ham.id)
  }

  static async post(ham: HarmonizationSettingSubmissionUnit): Promise<HarmonizationSettingSubmissionUnit> {
    const response = await Vue.$axios.post(this.URI, ham)
    return response.data
  }

  static async patch(ham: HarmonizationSettingSubmissionUnit): Promise<HarmonizationSettingSubmissionUnit> {
    const response = await Vue.$axios.patch(this.URI + "/" + ham.id, ham)
    return response.data
  }
}
