import { Vue } from "vue-property-decorator"
import FaoValueFilterService from "@/services/fao/FaoItemFilterService"
import FaoItemFilter from "@/model/interfaces/fao/faoItemFilter"
import { FaoItem } from "@/model/interfaces/fao/faoItem"

export default class FaoItemService {
  private static URI = "fao_items"

  static async get(filter: FaoItemFilter): Promise<FaoItem[]> {
    const response = await Vue.$axios.get(this.URI, {
      params: FaoValueFilterService.normalizeFilters(filter)
    })

    return response.data["hydra:member"]
  }
}
