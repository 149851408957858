




































import { Component, Vue } from "vue-property-decorator"
import SecurityModule from "@/store/modules/SecurityModule"
import router from "@/router"

@Component
export default class HeaderTop extends Vue {
  get isAuthenticated(): boolean {
    return SecurityModule.isAuthenticated
  }

  get user(): any {
    return SecurityModule.user
  }

  get isAdmin(): any {
    return SecurityModule.isAdmin
  }

  async logout(): Promise<void> {
    await SecurityModule.logOut()
    router.push({ name: "home" })
  }
}
