









































import { Component } from "vue-property-decorator"
import UserService from "@/services/UserService"
import Vue from "vue"
import SecurityModule from "@/store/modules/SecurityModule"
import router from "@/router"

@Component
export default class PrivacyNotice extends Vue {
  async beforeCreate(): Promise<void> {
    await SecurityModule.checkAuthenticated()
  }

  get hasAcceptedPrivacyNotice(): boolean {
    return SecurityModule.hasAcceptedPrivacyNotice
  }

  get isAuthenticated(): boolean {
    return SecurityModule.isAuthenticated
  }

  async acceptPrivacyNotice(): Promise<void> {
    await UserService.acceptPrivacyNotice().then(response => {
      if (response && response.status === 200) {
        SecurityModule.setHasAcceptedPrivacyNotice(true)
        router.push({ name: "home" }).catch(error => {
          console.info(error.message)
        })
      }
    })
  }
}
