













import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

import { Component } from "vue-property-decorator"
import LayoutOverviewColumn from "@/components/layouts/LayoutOverviewColumn.vue"
import OverviewTableReportDataPublicationLine from "@/components/dashboard/Overview/reportDataPublication/OverviewTableReportDataPublicationLine.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import VueTrans from "@/mixins/VueTrans.vue"

@Component({
  components: {
    LayoutOverviewColumn,
    OverviewTableReportDataPublicationLine
  }
})
export default class OverviewTableReportDataPublication extends VueTrans {
  transKey = "dashboard.overview.reportDataPublication."

  get submissions(): Array<TemplateSubmission> {
    return TemplateSubmissionModule.submissions
  }
}
