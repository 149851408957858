



























import { Component, Vue } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import Combobox from "@/components/inputs/Combobox.vue"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationLabel from "@/components/elements/PaginationLabel.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import TemplateSummaryModule from "@/store/modules/summary/TemplateSummaryModule"
import { Template } from "@/model/interfaces/form/template/template"

@Component({
  components: {
    Button,
    Combobox,
    ComboboxMulti,
    ComboboxPagination,
    PaginationLabel,
    SearchField,
    LayoutToolbar
  }
})
export default class TemplateSummaryToolbar extends Vue {
  async mounted(): Promise<void> {
    await TemplateSummaryModule.updateTemplates()
    await TemplateSummaryModule.updateTemplateSummary()
  }

  // Active template
  get activeTemplate(): Template {
    return TemplateSummaryModule.activeTemplate
  }

  set activeTemplate(template: Template) {
    TemplateSummaryModule.setActiveTemplate(template)
    TemplateSummaryModule.updateTemplateSummary()
  }

  // Last update
  get lastUpdate(): string {
    return this.activeTemplate && this.activeTemplate.lastUpdate ? " " + new Date(this.activeTemplate.lastUpdate).toLocaleString() : ""
  }

  // Pagination
  get pagination(): Pagination {
    return TemplateSummaryModule.pagination
  }

  // Search
  get search(): string {
    return TemplateSummaryModule.filters.search ?? ""
  }

  set search(search: string) {
    TemplateSummaryModule.setSearch(search)
    TemplateSummaryModule.updateTemplateSummary()
  }

  // Selected items per page
  get selectedItemPerPage(): number | null {
    return TemplateSummaryModule.pagination.itemsPerPage
  }

  set selectedItemPerPage(itemsPerPage: number | null) {
    TemplateSummaryModule.setItemsPerPage(itemsPerPage)
    TemplateSummaryModule.updateTemplateSummary()
  }

  // Templates
  get templates(): Template[] {
    return TemplateSummaryModule.templates
  }

  // Years
  set selectedYears(years: number[]) {
    TemplateSummaryModule.setYears(years)
    TemplateSummaryModule.updateTemplateSummary()
  }

  get selectedYears(): number[] {
    return TemplateSummaryModule.filters.year ?? []
  }

  get years(): number[] {
    return this.activeTemplate.submissionYears ?? []
  }

  // Export
  async exportContent(): Promise<void> {
    const xlsxContent = await TemplateSubmissionService.getSummaryExport(TemplateSummaryModule.filters)
    if (xlsxContent) {
      const link = document.createElement("a")
      link.href = xlsxContent
      link.download = "export.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
