import SurveySubmissionFilter from "@/model/interfaces/submission/surveySubmission/surveySubmissionFilter"

export default class SurveySubmissionFilterService {
  private static readonly harmonizedFields = "harmonized"

  static normalizeSearchFilter(search: string, filter: SurveySubmissionFilter): void {
    filter.fields = search
    filter.harmonizedFields = search
  }

  static normalizeFilters(filter: SurveySubmissionFilter): SurveySubmissionFilter {
    const normalizedFilter: SurveySubmissionFilter = {}

    if (filter.status) {
      normalizedFilter.status = filter.status.map(value => value.toLowerCase())
    }

    if (filter["survey.formType"]) {
      normalizedFilter["survey.formType"] = filter["survey.formType"]
    }

    if (filter["templateSubmission.template.uid"]) {
      normalizedFilter["templateSubmission.template.uid"] = filter["templateSubmission.template.uid"]
    }

    if (filter.dataCleaned === true || filter.dataCleaned === false) {
      normalizedFilter.dataCleaned = filter.dataCleaned
    }
    if (filter.publishable === true || filter.publishable === false) {
      normalizedFilter.publishable = filter.publishable
    }

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    if (filter.fieldsType) {
      normalizedFilter.fieldsType = filter.fieldsType
    }

    return normalizedFilter
  }

  static setFieldsType(isHarmonizedData: boolean, filter: SurveySubmissionFilter): void {
    if (isHarmonizedData) {
      filter.fieldsType = this.harmonizedFields
    }
  }
}
