import { Action, getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import { Notification } from "@/model/interfaces/notification/notification"
import { NotificationFilter } from "@/model/interfaces/notification/notificationFilter"
import NotificationMercureService from "@/services/mercure/NotificationMercureService"
import NotificationService from "@/services/notification/NotificationService"
import NotificationType from "@/model/enum/notification/notificationType"
import router from "@/router"
import SecurityModule from "@/store/modules/SecurityModule"
import store from "@/store"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import FaoValueModule from "@/store/modules/fao/FaoValueModule"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

@Module({ dynamic: true, store, name: "NotificationModule", namespaced: true })
class NotificationModule extends VuexModuleSearch {
  hasEventSourceOnCreate = false

  filters: NotificationFilter = { year: [] }
  public notifications: Notification[] = []
  public notificationsLast10: Notification[] = []

  @Action
  async onCreate() {
    if (!this.hasEventSourceOnCreate) {
      NotificationMercureService.onCreate()
      this.setHasEventSourceOnCreate(true)
    }
  }

  @Mutation
  setHasEventSourceOnCreate(bool: boolean) {
    this.hasEventSourceOnCreate = bool
  }

  @MutationAction({
    mutate: ["notifications"]
  })
  async get() {
    getModule(NotificationModule).setLoading(true)
    const results = await NotificationService.get(this.filters, this.pagination).finally(() => {
      getModule(NotificationModule).setLoading(false)
    })
    getModule(NotificationModule).mergePagination(results.pagination)
    return {
      notifications: results.notifications
    }
  }

  @MutationAction({
    mutate: ["notificationsLast10"]
  })
  async getLast10() {
    const notifications = await NotificationService.getLast10()
    return {
      notificationsLast10: notifications
    }
  }

  @Action
  async add(notification: Notification) {
    if (router.currentRoute.matched[0].name === "dashboardLayout") {
      this.notificationsLast10.unshift(notification)
      this.notificationsLast10.pop()
    }
    if (
      router.currentRoute.name === "notification" &&
      this.pagination.current === 1 &&
      (this.filters.type === undefined || this.filters.type.includes(notification.type as NotificationType)) &&
      (this.filters.year.length === 0 || this.filters.year.includes(notification.year as number)) &&
      (this.filters.search === undefined || this.filters.search === "")
    ) {
      getModule(NotificationModule).notifications.unshift(notification)
      await SecurityModule.setLastNotification(notification)
      return
    }

    if ((await NotificationService.canSeeNotificationCountUpdated(notification)) && SecurityModule.user && SecurityModule.user.nbUnseenNotifications !== undefined) {
      SecurityModule.setNbUnseenNotifications(++SecurityModule.user.nbUnseenNotifications)
    }

    if (notification.type === NotificationType.FAO_IMPORT_SUCCESS && router.currentRoute.name === "data-analysis") {
      await FaoValueModule.get()
    }

    if (router.currentRoute.name === "survey-data-management-beneficiary-datasheet") {
      if (notification.type === NotificationType.UNIT_HARMONIZATION_SUCCESS) {
        await HarmonizationSettingsUnitModule.reloadByTemplateSubmission()
      }
      if (notification.type === NotificationType.HAM_HARMONIZATION_SUCCESS) {
        await HarmonizationSettingsHamModule.reloadByTemplateSubmission()
      }

      if (notification.entityId === TemplateSubmissionModule.currentSubmission?.id && notification.type === NotificationType.BENEFICIARY_SURVEY_SUBMISSION_CREATED) {
        await SurveySubmissionModule.reloadByCurrentSubmission()
        await HarmonizationSettingsHamModule.getByTemplateSubmission()
        await HarmonizationSettingsUnitModule.getByTemplateSubmission()
      }
    }

    if (router.currentRoute.name === "survey-data-management-partner-datasheet") {
      if (
        notification.type === NotificationType.PARTNER_HARMONIZATION_SUCCESS ||
        (notification.entityId === TemplateSubmissionModule.currentSubmission?.id && notification.type === NotificationType.PARTNER_SURVEY_SUBMISSION_CREATED)
      ) {
        await SurveySubmissionModule.reloadByCurrentSubmission()
      }
    }
  }

  @Mutation
  setType(type: NotificationType[]) {
    this.filters.type = type
  }

  @Mutation
  setYears(years: number[]) {
    this.filters.year = years
  }

  @Mutation
  resetFilters() {
    this.filters = {
      year: [],
      type: undefined,
      search: undefined,
      country: undefined,
      dutyStation: undefined,
      entityId: undefined
    }
  }
}

export default getModule(NotificationModule)
