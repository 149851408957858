import { Vue } from "vue-property-decorator"
import Year from "@/model/interfaces/year"

export default class AvailableYearService {
  private static AVAILABLE_YEAR_URI = "available_years"

  static async get(): Promise<Year[]> {
    return Vue.$axios.get(this.AVAILABLE_YEAR_URI).then(response => {
      return response.data["hydra:member"] as Year[]
    })
  }
}
