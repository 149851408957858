

























































import VueTrans from "@/mixins/VueTrans.vue"
import { Component, Emit, Prop, PropSync } from "vue-property-decorator"
import TextField from "@/components/inputs/TextField.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"

@Component({
  components: {
    ComboboxMulti,
    TextField,
    SwitchCustom
  }
})
export default class ReplaceDialog extends VueTrans {
  transKey = "replaceDialog."
  dialog = false
  replace = ""
  selectedColumns: string[] = []
  @Prop({ default: null }) totalFoundValues!: number | null
  @PropSync("search", { type: String, default: "" }) searchSync!: string
  @PropSync("isExactMatchSearch", { type: Boolean, default: false }) isExactMatchSearchSync!: string
  @Prop({ type: Array }) withColumns!: string[] | undefined

  mounted(): void {
    this.makeDialogDraggable()
  }

  @Emit()
  replaceAll(): string {
    this.dialog = false
    return this.replace
  }

  @Emit()
  onSelectedColumnsChange(): string[] {
    return this.selectedColumns
  }

  makeDialogDraggable(): void {
    ;(function() {
      // make vuetify dialogs movable
      const d: any = {}
      document.addEventListener("mousedown", (e: MouseEvent) => {
        const htmlElement = e.target as HTMLElement | null
        if (htmlElement) {
          const closestDialog = htmlElement.closest(".v-dialog.v-dialog--active")
          if (e.button === 0 && closestDialog && htmlElement.classList.contains("v-card__title")) {
            // element which can be used to move element
            d.el = closestDialog // element which should be moved
            d.mouseStartX = e.clientX
            d.mouseStartY = e.clientY
            d.elStartX = d.el.getBoundingClientRect().left
            d.elStartY = d.el.getBoundingClientRect().top
            d.el.style.position = "fixed"
            d.el.style.margin = 0
            d.oldTransition = d.el.style.transition
            d.el.style.transition = "none"
          }
        }
      })
      document.addEventListener("mousemove", (e: MouseEvent) => {
        if (d.el === undefined) {
          return
        }
        d.el.style.left = Math.min(Math.max(d.elStartX + e.clientX - d.mouseStartX, 0), window.innerWidth - d.el.getBoundingClientRect().width) + "px"
        d.el.style.top = Math.min(Math.max(d.elStartY + e.clientY - d.mouseStartY, 0), window.innerHeight - d.el.getBoundingClientRect().height) + "px"
      })
      document.addEventListener("mouseup", () => {
        if (d.el === undefined) {
          return
        }
        d.el.style.transition = d.oldTransition
        d.el = undefined
      })
      setInterval(() => {
        // prevent out of bounds
        const dialog = document.querySelector<HTMLElement>(".v-dialog.v-dialog--active")
        if (!dialog) {
          return
        }
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px"
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px"
      }, 100)
    })()
  }
}
