import { Vue } from "vue-property-decorator"
import { AxiosError, AxiosResponse } from "axios"
import VueI18n from "@/plugins/i18n"
import { TemplateDescriptor } from "@/model/interfaces/form/template/templateDescriptor"

export default class TemplateDescriptorService {
  private static URI = "template_descriptors"
  private static RESOURCES_DESCRIPTOR_PATH = "/resources/form/template/descriptor"

  static async postFile(form: FormData): Promise<{ error: string } | { message: string } | TemplateDescriptor> {
    try {
      const response = await Vue.$axios.post(this.URI, form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return response.data
    } catch (error) {
      const err = error as AxiosError
      if (err.response?.data["hydra:description"]) {
        return { error: err.response.data["hydra:description"] }
      }

      return { error: VueI18n.t("error.server").toString() }
    }
  }

  static async delete(templateDescriptor: TemplateDescriptor): Promise<AxiosResponse> {
    return await Vue.$axios.delete(this.URI + "/" + templateDescriptor.id)
  }
}
