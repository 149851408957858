













import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import VueTrans from "@/mixins/VueTrans.vue"
import HarmonizationSettingsCurrencyModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsCurrencyModule"
import { DataTableHeader } from "vuetify"
import { HarmonizationSettingSubmissionCurrency } from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrency"
import CopyUtils from "@/services/utils/CopyUtils"
import HarmonizationSettingsCurrencyService from "@/services/harmonizationSettings/harmonizationSettingsCurrency/HarmonizationSettingsCurrencyService"

@Component
export default class CurrencyTable extends mixins(VueTrans) {
  transKey = "templatesubmission.surveydatamanagement.partnerDatasheet.harmonizationSettings.table.currency."

  async mounted(): Promise<void> {
    await HarmonizationSettingsCurrencyModule.getByTemplateSubmission()
  }

  get loading(): boolean {
    return HarmonizationSettingsCurrencyModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("convertFrom"), value: "name", sortable: false },
      { text: this.t("convertTo"), value: "exchangeCountry.currency", sortable: false },
      { text: this.t("rate"), value: "rate", sortable: false },
      { text: this.t("year"), value: "year", sortable: false },
      { text: this.t("actions"), value: "actions", sortable: false }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionCurrency[] {
    return HarmonizationSettingsCurrencyModule.currencies
  }

  getRateByYear(item: HarmonizationSettingSubmissionCurrency): string | undefined {
    return HarmonizationSettingsCurrencyService.getRateByYear(item)
  }

  edit(currency: HarmonizationSettingSubmissionCurrency): void {
    HarmonizationSettingsCurrencyModule.setCurrent((CopyUtils.create((currency as unknown) as Record<string, unknown>) as unknown) as HarmonizationSettingSubmissionCurrency)
    HarmonizationSettingsCurrencyModule.setDialogForm(true)
  }
}
