











import { Component, Vue } from "vue-property-decorator"

import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"

import OverviewContent from "@/components/dashboard/Overview/OverviewContent.vue"
import OverviewToolbar from "@/components/dashboard/Overview/OverviewToolbar.vue"

import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { Pagination } from "@/model/interfaces/pagination"

@Component({
  components: {
    LayoutPaginable,
    OverviewContent,
    OverviewToolbar
  }
})
export default class DashboardOverview extends Vue {
  async mounted(): Promise<void> {
    await TemplateSubmissionModule.updateTemplateSubmissions()
  }

  async destroyed(): Promise<void> {
    await TemplateSubmissionModule.closeEventSources()
  }

  get pagination(): Pagination {
    return TemplateSubmissionModule.pagination
  }

  set pagination(pagination: Pagination) {
    TemplateSubmissionModule.updateTemplateSubmissions() // This will update the whole pagination item
  }
}
