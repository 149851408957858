import Vue from "vue"
import VueI18n, { LocaleMessages } from "vue-i18n"

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const matched = key.match(/([A-Za-z0-9-_]+)\//i)
      const matchedModule = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1 && matchedModule && matchedModule.length > 1) {
        const locale = matched[1]
        const module = matchedModule[1]
        if (!messages[locale]) {
          messages[locale] = {}
        }
        if (module === "main") {
          messages[locale] = { ...messages[locale], ...locales(key) }
        } else {
          messages[locale][module] = locales(key)
        }
      }
    }
  })
  return messages
}

export default new VueI18n({
  locale: navigator.language || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  dateTimeFormats: {
    en: {
      digit: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric"
      }
    },
    fr: {
      digit: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      },
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric"
      }
    }
  }
})
