import { LVColors } from "@/plugins/vuetify"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import { GlobalIndicatorsChartDefinition } from "@/model/interfaces/globalIndicators/GlobalIndicatorsChartDefinition"
import VueI18n from "@/plugins/i18n"

export class GlobalIndicatorsChartDefinitions {
  static getChartDefinitions(): Partial<Record<GlobalIndicatorsDatasetKey, GlobalIndicatorsChartDefinition>> {
    return {
      [GlobalIndicatorsDatasetKey.BENEFICIARIES_LEGAL_STATUS_COUNT]: {
        additionalConfig: [
          {
            backgroundColor: [
              LVColors.PRIMARY_BLUE,
              LVColors.LIGHT_BLUE_1,
              LVColors.LIGHT_BLUE_2,
              LVColors.LIGHT_BLUE_3
            ]
          }
        ],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.legalStatus.formerRefugees") as string
          },
          {
            color: LVColors.LIGHT_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.legalStatus.hostCommunity") as string
          },
          {
            color: LVColors.LIGHT_BLUE_2,
            value: VueI18n.t("dashboard.globalindicators.legends.legalStatus.idps") as string
          },
          {
            color: LVColors.LIGHT_BLUE_3,
            value: VueI18n.t("dashboard.globalindicators.legends.legalStatus.refugees") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.FAMILIES_SIZE_COUNT]: {
        additionalConfig: [
          {
            backgroundColor: LVColors.PRIMARY_BLUE,
            borderColor: LVColors.PRIMARY_BLUE,
            barThickness: 5,
            categoryPercentage: 0.1,
            barPercentage: 1,
            borderWidth: 1
          }
        ],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.ORIGIN_COUNTRIES_COUNT]: {
        additionalConfig: [],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.LAND_LEGAL_ACCESS]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.PRIMARY_BLUE, LVColors.PRIMARY_RED, LVColors.PRIMARY_GREEN]
          }
        ],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.some") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.no") as string
          },
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.yes") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.EMPLOYMENT_LEGAL_ACCESS]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.PRIMARY_BLUE, LVColors.PRIMARY_RED, LVColors.PRIMARY_GREEN]
          }
        ],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.some") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.no") as string
          },
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.yes") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.FINANCIAL_LEGAL_ACCESS]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.PRIMARY_BLUE, LVColors.PRIMARY_RED, LVColors.PRIMARY_GREEN]
          }
        ],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.some") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.no") as string
          },
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.legalAcces.yes") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.AOW_REPARTITION]: {
        additionalConfig: [
          {
            backgroundColor: [
              LVColors.DARK_GREEN,
              LVColors.DARK_BLUE_1,
              LVColors.LIGHT_BLUE_1,
              LVColors.LIGHT_GREY_1
            ]
          }
        ],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.aow1") as string
          },
          {
            color: LVColors.DARK_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.aow2") as string
          },
          {
            color: LVColors.LIGHT_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.aow3") as string
          },
          {
            color: LVColors.LIGHT_GREY_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.aow4") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.AGRICULTURAL_ACTIVITIES_INVOLVEMENT]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.DARK_GREEN, LVColors.PRIMARY_GREEN, LVColors.LIGHT_GREEN]
          }
        ],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.farming") as string
          },
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.livestock") as string
          },
          {
            color: LVColors.LIGHT_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.fishery") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.BENEFICIARIES_EMPLOYMENT_TYPE]: {
        additionalConfig: [
          {
            backgroundColor: [
              LVColors.DARK_BLUE_1,
              LVColors.PRIMARY_BLUE,
              LVColors.LIGHT_BLUE_1,
              LVColors.LIGHT_BLUE_2,
              LVColors.LIGHT_BLUE_3,
              LVColors.LIGHT_GREY_1
            ]
          }
        ],
        legend: [
          {
            color: LVColors.DARK_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.wholesale") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.manufacturing") as string
          },
          {
            color: LVColors.LIGHT_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.otherServices") as string
          },
          {
            color: LVColors.LIGHT_BLUE_2,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.artisanal") as string
          },
          {
            color: LVColors.LIGHT_BLUE_3,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.beauty") as string
          },
          {
            color: LVColors.LIGHT_GREY_1,
            value: VueI18n.t("dashboard.globalindicators.legends.beneficiaries.aow4") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE]: {
        additionalConfig: [{}],
        legend: [
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.gender.female") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.gender.male") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF]: {
        additionalConfig: [{}],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE]: {
        additionalConfig: [{}],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.DISPLACED_BENEFICIARIES_EMPLOYMENT_BY_LINE]: {
        additionalConfig: [{ backgroundColor: LVColors.PRIMARY_BLUE }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.employed") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.unemployed") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.HOST_COMMUNITY_BENEFICIARIES_EMPLOYMENT_BY_LINE]: {
        additionalConfig: [{ backgroundColor: LVColors.PRIMARY_BLUE }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.FEMALE_BENEFICIARIES_EMPLOYMENT_BY_LINE]: {
        additionalConfig: [{ backgroundColor: LVColors.PRIMARY_BLUE }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.employed") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.unemployed") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.MALE_BENEFICIARIES_EMPLOYMENT_BY_LINE]: {
        additionalConfig: [{ backgroundColor: LVColors.PRIMARY_BLUE }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.SELF_EMPLOYMENT_IN_AND_OUT_AGRICULTURE]: {
        additionalConfig: [
          { backgroundColor: LVColors.PRIMARY_GREEN },
          { backgroundColor: LVColors.PRIMARY_BLUE },
          { backgroundColor: LVColors.WHITE }
        ],
        legend: [
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.agriculture") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.nonAgriculture") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.WAGE_EMPLOYMENT_IN_AND_OUT_AGRICULTURE]: {
        additionalConfig: [
          { backgroundColor: LVColors.PRIMARY_GREEN },
          { backgroundColor: LVColors.PRIMARY_BLUE },
          { backgroundColor: LVColors.WHITE }
        ],
        legend: []
      },
      [GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_IN_AGRICULTURE]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.DARK_GREEN, LVColors.PRIMARY_GREEN, LVColors.LIGHT_GREEN]
          }
        ],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.daily") as string
          },
          {
            color: LVColors.PRIMARY_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.monthly") as string
          },
          {
            color: LVColors.LIGHT_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.permanent") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_OTHERS]: {
        additionalConfig: [
          {
            backgroundColor: [LVColors.PRIMARY_BLUE, LVColors.DARK_BLUE_1, LVColors.LIGHT_BLUE_3]
          }
        ],
        legend: [
          {
            color: LVColors.DARK_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.more12") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.less12") as string
          },
          {
            color: LVColors.LIGHT_BLUE_3,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.less6") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_MULTIPLIER]: {
        additionalConfig: [],
        legend: [
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.allSectors") as string
          },
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.agriculture") as string
          },
          {
            color: LVColors.DARK_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.nonAgriculture") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_TYPES]: {
        additionalConfig: [
          {
            backgroundColor: [
              LVColors.LIGHT_BLUE_1,
              LVColors.DARK_BLUE_1,
              LVColors.PRIMARY_BLUE,
              LVColors.LIGHT_BLUE_3
            ]
          }
        ],
        legend: [
          {
            color: LVColors.LIGHT_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.family") as string
          },
          {
            color: LVColors.DARK_BLUE_1,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.friends") as string
          },
          {
            color: LVColors.LIGHT_BLUE_3,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.otherRefugees") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.employment.hostCommunity") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.INCOME_CHANGE_BY_LINE]: {
        additionalConfig: [
          { backgroundColor: LVColors.DARK_GREEN },
          { backgroundColor: LVColors.PRIMARY_BLUE },
          { backgroundColor: LVColors.PRIMARY_RED }
        ],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.decrease") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.increase") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.same") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.SAVING_CHANGE_BY_LINE]: {
        additionalConfig: [
          { backgroundColor: LVColors.DARK_GREEN },
          { backgroundColor: LVColors.PRIMARY_BLUE },
          { backgroundColor: LVColors.PRIMARY_RED }
        ],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.decrease") as string
          },
          {
            color: LVColors.PRIMARY_BLUE,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.increase") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.same") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.SAVING_SERVICE_ACCESS]: {
        additionalConfig: [{ backgroundColor: LVColors.DARK_GREEN }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.formal") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.informal") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.LOAN_SERVICE_ACCESS]: {
        additionalConfig: [{ backgroundColor: LVColors.DARK_GREEN }, { backgroundColor: LVColors.PRIMARY_RED }],
        legend: [
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.formal") as string
          },
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.informal") as string
          }
        ]
      },
      [GlobalIndicatorsDatasetKey.MARKET_ACCESS]: {
        additionalConfig: [{ backgroundColor: LVColors.PRIMARY_RED }, { backgroundColor: LVColors.DARK_GREEN }, { backgroundColor: LVColors.LIGHT_GREEN }],
        legend: [
          {
            color: LVColors.PRIMARY_RED,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.no") as string
          },
          {
            color: LVColors.DARK_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.yesFormal") as string
          },
          {
            color: LVColors.LIGHT_GREEN,
            value: VueI18n.t("dashboard.globalindicators.legends.financial.yesInformal") as string
          }
        ]
      }
    }
  }
}
