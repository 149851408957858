














import { Component, Vue } from "vue-property-decorator"

import NotificationModule from "@/store/modules/NotificationModule"
import NotificationType, { NotificationTypeToSelectItems } from "@/model/enum/notification/notificationType"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import PaginationLabel from "@/components/elements/PaginationLabel.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import { Pagination } from "@/model/interfaces/pagination"
import AvailableYearModule from "@/store/modules/AvailableYearModule"
import Year from "@/model/interfaces/year"

@Component({
  components: {
    ComboboxMulti,
    ComboboxPagination,
    LayoutToolbar,
    PaginationLabel,
    SearchField
  }
})
export default class NotificationToolbar extends Vue {
  typeItems = NotificationTypeToSelectItems()

  set selectedType(type: NotificationType[]) {
    NotificationModule.setType(type)
    NotificationModule.get()
  }

  get selectedType(): NotificationType[] {
    return NotificationModule.filters.type ?? []
  }

  // Pagination
  get pagination(): Pagination {
    return NotificationModule.pagination
  }

  get selectedItemPerPage(): number | null {
    return NotificationModule.pagination.itemsPerPage
  }
  set selectedItemPerPage(itemsPerPage: number | null) {
    NotificationModule.setItemsPerPage(itemsPerPage)
    NotificationModule.get()
  }

  // Years
  set selectedYears(years: number[]) {
    NotificationModule.setYears(years)
    NotificationModule.get()
  }

  get selectedYears(): number[] {
    return NotificationModule.filters.year ?? []
  }

  get years(): Year[] {
    return AvailableYearModule.years
  }

  // Search
  get search(): string | null {
    return NotificationModule.filters.search ?? ""
  }

  set search(search: string | null) {
    NotificationModule.setSearch(search)
    NotificationModule.get()
  }
}
