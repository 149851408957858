








import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button
  }
})
export default class ButtonGroup extends Vue {
  @Prop() readonly fullWidth: boolean | undefined
  @Prop({ default: "" })
  value!: string

  @Prop()
  description!: Array<{ text: string; key: string }>

  currentKey = this.value
  @Watch("currentKey")
  onCurrentKeyChanged(): void {
    this.$emit("input", this.currentKey)
  }

  getWidth() {
    if (this.fullWidth) {
      return `width: ${100 / this.description.length}%`
    }
  }
}
