import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import { SurveySubmission } from "@/model/interfaces/submission/surveySubmission/surveySubmission"
import SurveySubmissionService from "@/services/submission/surveySubmission/SurveySubmissionService"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import SurveySubmissionFilter from "@/model/interfaces/submission/surveySubmission/surveySubmissionFilter"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { Template } from "@/model/interfaces/form/template/template"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import DataAnalysisModule from "@/store/modules/dataAnalysis/dataAnalysisModule"

@Module({ dynamic: true, store, name: "SurveySubmissionModule", namespaced: true })
class SurveySubmissionModule extends VuexModuleSearch {
  beneficiarySubmissions: SurveySubmission[] = []
  beneficiarySubmissionsTotalItems = 0
  beneficiarySubmissionUpdateProgress = 0
  partnerSubmissions: SurveySubmission[] = []
  partnerSubmissionsTotalItems = 0
  filters: SurveySubmissionFilter = {}

  submissionsToUpdate: SurveySubmission[] = []

  @Mutation
  setStatus(status: string[]) {
    this.filters.status = status
  }

  @Mutation
  isDataCleaned(dataCleaned: boolean) {
    this.filters.dataCleaned = dataCleaned
  }

  @Mutation
  isPublishable(publishable: boolean | null) {
    this.filters["publishable"] = publishable
  }

  @Mutation
  setFormType(formType: SurveyFormType) {
    this.filters["survey.formType"] = formType
  }

  @Mutation
  clear() {
    this.beneficiarySubmissions = []
    this.partnerSubmissions = []
    this.filters = {}
    this.submissionsToUpdate = []
  }

  @Mutation
  setBeneficiarySubmissions(surveySubmissions: SurveySubmission[]) {
    this.beneficiarySubmissions = surveySubmissions
  }

  @Mutation
  setBeneficiarySubmissionUpdateProgress(beneficiarySubmissionUpdateProgress: number) {
    this.beneficiarySubmissionUpdateProgress = beneficiarySubmissionUpdateProgress
  }

  @Mutation
  setBeneficiarySubmissionsTotalItems(totalItems: number) {
    this.beneficiarySubmissionsTotalItems = totalItems
  }
  @Mutation
  setPartnerSubmissionsTotalItems(totalItems: number) {
    this.partnerSubmissionsTotalItems = totalItems
  }

  @Mutation
  setPartnerSubmissions(surveySubmissions: SurveySubmission[]) {
    this.partnerSubmissions = surveySubmissions
  }

  @Mutation
  addSubmissionsToUpdate(surveySubmission: SurveySubmission) {
    const index = this.submissionsToUpdate.findIndex(submissionToUpdate => submissionToUpdate.id === surveySubmission.id)
    if (index !== -1) {
      this.submissionsToUpdate.splice(index, 1, surveySubmission)
    } else {
      this.submissionsToUpdate.push(surveySubmission)
    }
  }

  @Mutation
  resetSubmissionsToUpdate() {
    this.submissionsToUpdate = []
  }

  @Action({
    rawError: true
  })
  async getByTemplateSubmission(templateSubmission: TemplateSubmission) {
    getModule(SurveySubmissionModule).setLoading(true)
    const results = await SurveySubmissionService.getByTemplateSubmission(templateSubmission, this.filters).finally(() => {
      getModule(SurveySubmissionModule).setLoading(false)
    })

    await this.setSubmissions(results)
  }

  @Action({
    rawError: true
  })
  async getByTemplate(template: Template) {
    getModule(SurveySubmissionModule).setLoading(true)
    try {
      const results = await SurveySubmissionService.getByTemplate(template, this.filters, this.pagination)
      this.mergePagination(results.pagination)
      await this.setSubmissions({ submissions: results.submissions, totalItems: results.pagination.itemCount })
    } finally {
      getModule(SurveySubmissionModule).setLoading(false)
    }
  }

  @Action
  async setSubmissions(results: { submissions: SurveySubmission[]; totalItems: number }) {
    if (this.filters["survey.formType"] === SurveyFormType.Beneficiary) {
      this.setBeneficiarySubmissions(results.submissions)
      this.setBeneficiarySubmissionsTotalItems(results.totalItems)
    }
    if (this.filters["survey.formType"] === SurveyFormType.Partner) {
      this.setPartnerSubmissions(results.submissions)
      this.setPartnerSubmissionsTotalItems(results.totalItems)
    }
  }

  @Action({
    rawError: true
  })
  async updateSubmissions() {
    const progress = (updated: number, total: number) => {
      this.setBeneficiarySubmissionUpdateProgress(Math.round((updated / total) * 100))
    }

    if (this.submissionsToUpdate.length > 0) {
      const partialSubmissions: Partial<SurveySubmission>[] = this.submissionsToUpdate.map((surveySubmission: SurveySubmission) => {
        const { id, fields } = surveySubmission
        return { id, fields }
      })
      await SurveySubmissionService.patchSubmissions(partialSubmissions, progress)
      this.resetSubmissionsToUpdate()
    }
  }

  @Action({ rawError: true })
  async reloadByCurrentSubmission() {
    if (TemplateSubmissionModule.currentSubmission) {
      await getModule(SurveySubmissionModule).getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
    }
  }

  @Action
  setForDataBridge() {
    this.isDataCleaned(true)
    this.isPublishable(DataAnalysisModule.dataBridgeMenuKey === DataAnalysisModule.KEY_BENEFICIARY ? true : null)
  }
}

export default getModule(SurveySubmissionModule)
