












import { Component, Vue } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import LayoutPublic from "@/components/layouts/LayoutPublic.vue"
import AzureService from "@/services/security/AzureService"
import GitInfoModule from "@/store/modules/GitInfoModule"

@Component({
  components: {
    Button,
    LayoutPublic
  }
})
export default class LoginSelect extends Vue {
  authorizationLink = ""
  async created(): Promise<void> {
    this.authorizationLink = await AzureService.getAuthorizationUrl()
    await GitInfoModule.get()
  }

  get isPlatformAuthenticationEnabled(): boolean {
    return process.env.VUE_APP_PLATFORM_AUTH === "1"
  }

  get gitInfoModule(): typeof GitInfoModule {
    return GitInfoModule
  }
}
