
















































import { Component, Prop, Ref, Watch } from "vue-property-decorator"
import CanDictionaryModule from "@/store/modules/submission/CanDictionaryModule"
import { DictionaryItemType } from "@/model/interfaces/submission/templateSubmission/DictionaryItemType"

import VueTrans from "@/mixins/VueTrans.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class DictionaryItemForm extends VueTrans {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>

  @Prop({ default: false })
  show!: boolean

  @Prop({ default: null })
  referenceItem!: DictionaryItemType | null
  editedItem: DictionaryItemType = {
    key: "",
    value: ""
  }

  get isKeyFieldEditable(): boolean {
    return this.referenceItem === null
  }

  @Watch("show", { immediate: true })
  handleItemChange() {
    if (this.referenceItem) {
      this.editedItem = { ...this.referenceItem }
    } else {
      this.editedItem.key = ""
      this.editedItem.value = ""
    }
  }

  get loading(): boolean {
    return CanDictionaryModule.loading
  }

  async submit(): Promise<void> {
    try {
      if (this.referenceItem) {
        await CanDictionaryModule.patch(this.editedItem)
      } else {
        await CanDictionaryModule.post(this.editedItem)
      }
    } finally {
      this.close()
    }
  }

  get keys(): Array<string> {
    return CanDictionaryModule.keys
  }

  close(): void {
    this.form.reset()
    this.$emit("close")
  }
}
