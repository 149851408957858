


























import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"

@Component({})
export default class LayoutPublic extends Vue {
  @Prop()
  title!: string

  @Prop()
  subtitle!: string

  @Prop({ default: null })
  info?: string
}
