


































import { Component } from "vue-property-decorator"
import SurveySubmissionHistoryModule from "@/store/modules/submission/SurveySubmissionHistoryModule"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { SurveySubmissionHistory } from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistory"
import IriUtils from "@/services/utils/IriUtils"
import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"
import { Pagination } from "@/model/interfaces/pagination"
import { PAGINATION_DEFAULT_VALUES } from "@/model/constants"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"

@Component({
  components: { LayoutPaginable }
})
export default class ActivityLog extends VueTrans {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.activityLog."
  expanded: [] = []

  async mounted(): Promise<void> {
    SurveySubmissionHistoryModule.setFormType(SurveyFormType.Beneficiary)
    await this.getSurveySubmissionHistories()
  }

  get loading(): boolean {
    return SurveySubmissionHistoryModule.loading
  }

  get pagination(): Pagination {
    return SurveySubmissionHistoryModule.pagination
  }

  set pagination(pagination: Pagination) {
    pagination.current = pagination.page || 1
    if (pagination.current !== SurveySubmissionHistoryModule.pagination.current || pagination.itemsPerPage !== SurveySubmissionHistoryModule.pagination.itemsPerPage) {
      SurveySubmissionHistoryModule.mergePagination(pagination)
      this.getSurveySubmissionHistories()
    }
  }

  get itemCount(): number {
    return SurveySubmissionHistoryModule.pagination.itemCount
  }

  get itemsPerPage(): number {
    return SurveySubmissionHistoryModule.pagination.itemsPerPage
  }

  get itemsPerPageOptions(): number[] {
    return PAGINATION_DEFAULT_VALUES
  }

  get headers(): DataTableHeader[] {
    return [
      { text: "", value: "data-table-expand" },
      { text: this.t("table.headers.date"), value: "loggedAt", sortable: false },
      { text: this.t("table.headers.objectId"), value: "objectId", sortable: false },
      { text: this.t("table.headers.action"), value: "action", sortable: false },
      { text: this.t("table.headers.version"), value: "version", sortable: false },
      { text: this.t("table.headers.username"), value: "username", sortable: false }
    ]
  }

  get surveySubmissionHistories(): SurveySubmissionHistory[] {
    return SurveySubmissionHistoryModule.surveySubmissionHistories
  }

  async getSurveySubmissionHistories(): Promise<void> {
    await SurveySubmissionHistoryModule.getByCurrentTemplateSubmission()
  }

  getId(item: SurveySubmissionHistory): number | undefined {
    if (item.objectId) {
      return IriUtils.getId(item.objectId)
    }
    return undefined
  }

  getUsername(item: SurveySubmissionHistory): string | undefined {
    if (item.username && typeof item.username === "object") {
      return item.username.username
    }

    return undefined
  }

  getHeadersOfItemFields(fields: Record<string, unknown>): DataTableHeader[] {
    return Object.keys(fields).map(key => {
      return { text: key, value: key, sortable: false }
    })
  }
}
