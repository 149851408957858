import { HandsontableStyle } from "@/model/interfaces/handsontable/handsontableStyle"
import { THEMES } from "@/plugins/vuetify"
import { HandsontableStylingCondition } from "@/model/enum/handsontable/handsontableStylingCondition"

export default class HandsontableUtils {
  // ///////////////////////////////////////////////////////////////////////////
  // Styles
  // ///////////////////////////////////////////////////////////////////////////
  static readonly HARMONIZED_STYLE: HandsontableStyle = {
    id: "harmonized",
    mainColor: THEMES.light.primary.lighten2,
    isCellColored: true
  }

  static readonly CROP_STYLE: HandsontableStyle = {
    id: "crop",
    mainColor: THEMES.light.green.lighten2,
    isCellColored: false
  }

  static readonly OUTLIERS_CROP_STYLE: HandsontableStyle = {
    id: "outliers-crops",
    mainColor: THEMES.light.green.lighten2,
    isCellColored: false,
    condition: HandsontableStylingCondition.OFFSET_ROW_SHOULD_BE_THE_SAME
  }

  static readonly CLEANED_DATA_STYLE: HandsontableStyle = {
    id: "cleaned-data",
    mainColor: THEMES.light.primary.base,
    isCellColored: true
  }

  static widthByHeaders(headers: string[]): number[] {
    const width = []
    for (const header of headers) {
      width.push(header.length * 10 > 80 ? header.length * 10 : 80)
    }

    return width
  }
}
