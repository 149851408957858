















import { Component } from "vue-property-decorator"
import Vue from "vue"
import Button from "@/components/inputs/Button.vue"
import ButtonClose from "@/components//inputs/ButtonClose.vue"
@Component({
  components: {
    Button,
    ButtonClose
  }
})
export default class LayoutSubmissionView extends Vue {}
