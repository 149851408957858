







import { Component, Vue } from "vue-property-decorator"
import HeaderNavigation from "./HeaderNavigation.vue"
import HeaderTop from "./HeaderTop.vue"

@Component({
  components: {
    HeaderNavigation,
    HeaderTop
  }
})
export default class Header extends Vue {}
