









import { Component, Vue } from "vue-property-decorator"
import CurrencyTable from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/harmonizationSettings/Currency/CurrencyTable.vue"
import CurrencyForm from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/harmonizationSettings/Currency/CurrencyForm.vue"

@Component({
  components: { CurrencyForm, CurrencyTable }
})
export default class HarmonizationSettings extends Vue {}
