


























import { Component } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { Pagination } from "@/model/interfaces/pagination"
import ConfirmDeletionDialog from "@/components/elements/dialog/ConfirmDeletionDialog.vue"
import CopyUtils from "@/services/utils/CopyUtils"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import { mixins } from "vue-class-component"
import VuePagination from "@/mixins/VuePagination.vue"
import CurrencyForm from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/harmonizationSettings/Currency/CurrencyForm.vue"
import { HarmonizationSettingSubmissionCurrency } from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrency"
import HarmonizationSettingsCurrencyModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsCurrencyModule"
import HarmonizationSettingsCurrencyService from "@/services/harmonizationSettings/harmonizationSettingsCurrency/HarmonizationSettingsCurrencyService"

@Component({
  components: { CurrencyForm, ConfirmDeletionDialog }
})
export default class CurrencyTable extends mixins(VueTrans, VuePagination) {
  transKey = "templatesubmission.surveydatamanagement.partnerDatasheet.harmonizationSettings.table.currency."

  destroyed(): void {
    HarmonizationSettingsCurrencyModule.resetPagination()
  }

  get loading(): boolean {
    return HarmonizationSettingsUnitModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("convertFrom"), value: "name", sortable: false },
      { text: this.t("convertTo"), value: "exchangeCountry.currency", sortable: false },
      { text: this.t("rate"), value: "rate", sortable: false },
      { text: this.t("year"), value: "year", sortable: false },
      { text: this.t("actions"), value: "actions", sortable: false }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionCurrency[] {
    return HarmonizationSettingsCurrencyModule.currencies
  }

  get pagination(): Pagination {
    return HarmonizationSettingsUnitModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPaginationChanged(pagination, HarmonizationSettingsCurrencyModule)) {
      HarmonizationSettingsCurrencyModule.mergePagination(pagination)
      HarmonizationSettingsCurrencyModule.getGlobal()
    }
  }

  get itemsPerPage(): number {
    return HarmonizationSettingsCurrencyModule.pagination.itemsPerPage
  }

  get itemCount(): number {
    return HarmonizationSettingsCurrencyModule.pagination.itemCount
  }

  getRateByYear(item: HarmonizationSettingSubmissionCurrency): string | undefined {
    return HarmonizationSettingsCurrencyService.getRateByYear(item)
  }

  edit(currency: HarmonizationSettingSubmissionCurrency): void {
    HarmonizationSettingsCurrencyModule.setCurrent((CopyUtils.create((currency as unknown) as Record<string, unknown>) as unknown) as HarmonizationSettingSubmissionCurrency)
    HarmonizationSettingsCurrencyModule.setDialogForm(true)
  }

  async remove(unit: HarmonizationSettingSubmissionCurrency): Promise<void> {
    HarmonizationSettingsCurrencyModule.setLoading(true)
    HarmonizationSettingsCurrencyModule.delete(unit).finally(() => {
      HarmonizationSettingsCurrencyModule.setLoading(false)
    })
  }
}
