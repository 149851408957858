import { Vue } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { HarmonizationSettingSubmissionOutlier } from "@/model/interfaces/harmonizationSettings/outlier/harmonizationSettingSubmissionOutlier"

export default class HarmonizationSettingsOutlierService {
  private static URI = "harmonization_setting_submission_outliers"

  static async get(templateSubmission: TemplateSubmission): Promise<HarmonizationSettingSubmissionOutlier[]> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI)

    return response.data["hydra:member"]
  }
}
