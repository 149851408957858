import { SurveySubmission } from "@/model/interfaces/submission/surveySubmission/surveySubmission"
import { Template } from "@/model/interfaces/form/template/template"
import { DataTableHeader } from "vuetify"
import { HandsontableChangeCell } from "@/model/interfaces/handsontable/handsontableChangeCell"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"

export default class SurveySubmissionParserService {
  // ///////////////////////////////////////////////////////////////////////////
  // DATA_BRIDGE
  // ///////////////////////////////////////////////////////////////////////////
  public static DATA_BRIDGE_HEADERS_PPG = "PPG"

  // ///////////////////////////////////////////////////////////////////////////
  // HARMONIZATION
  // ///////////////////////////////////////////////////////////////////////////
  public static readonly ID = "_id"
  public static readonly TODAY = "today"
  public static readonly HARMONIZED_HEADERS_DATA_CLEANED = "DATA_CLEANED"
  public static readonly PARTNER_INFO = "PARTNER_INFO/BE"

  public static readonly FIRST_HEADERS_BENEFICIARY = [
    SurveySubmissionParserService.ID,
    SurveySubmissionParserService.TODAY,
    SurveySubmissionParserService.HARMONIZED_HEADERS_DATA_CLEANED,
    SurveySubmissionParserService.PARTNER_INFO
  ]

  // ///////////////////////////////////////////////////////////////////////////
  // BENEFICIARY
  // ///////////////////////////////////////////////////////////////////////////
  public static readonly HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV = "Harmonized_G_OUTPUT1/O1_Animal_Production/CV"
  public static readonly HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV_2 = "Harmonized_G_OUTPUT1/O1_Animal_Production/CV2"
  public static readonly HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_1_O_11_ST_CROP = "Harmonized_G_OUTPUT1/O1_Crop_Production1/O1_1stCrop"
  public static readonly HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_2_O_12_ND_CROP = "Harmonized_G_OUTPUT1/O1_Crop_Production2/O1_2ndCrop"

  public static readonly HARMONIZED_GOUTPUT_1_HEADERS_BENEFICIARY = [
    SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV,
    SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV_2,
    SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_1_O_11_ST_CROP,
    SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_2_O_12_ND_CROP
  ]

  public static readonly OUTLIER_CROP_1_HA = "Outlier_Crop1HA"
  public static readonly OUTLIER_CROP_1_KG = "Outlier_Crop1KG"
  public static readonly OUTLIER_CROP_2_HA = "Outlier_Crop2HA"
  public static readonly OUTLIER_CROP_2_KG = "Outlier_Crop2KG"
  public static readonly OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED = "Outlier_G_OUTPUT1/O1_Animal_Production/Animal_Owned"
  public static readonly OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED_2 = "Outlier_G_OUTPUT1/O1_Animal_Production/Animal_Owned2"

  public static readonly HARMONIZED_OUTLIER_HEADERS_BENEFICIARY = [
    SurveySubmissionParserService.OUTLIER_CROP_1_HA,
    SurveySubmissionParserService.OUTLIER_CROP_1_KG,
    SurveySubmissionParserService.OUTLIER_CROP_2_HA,
    SurveySubmissionParserService.OUTLIER_CROP_2_KG,
    SurveySubmissionParserService.OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED,
    SurveySubmissionParserService.OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED_2
  ]

  public static readonly DEPENDENCY_RATIO_HEADERS_BENEFICIARY = "Dependency ratio"

  public static readonly CROP_1_KG = "Crop1KG"
  public static readonly CROP_1_HA = "Crop1HA"
  public static readonly CROP_2_KG = "Crop2KG"
  public static readonly CROP_2_HA = "Crop2HA"
  public static readonly HARMONIZED_CROP = [
    SurveySubmissionParserService.CROP_1_KG,
    SurveySubmissionParserService.CROP_1_HA,
    SurveySubmissionParserService.CROP_2_KG,
    SurveySubmissionParserService.CROP_2_HA
  ]

  public static readonly HARMONIZED_HEADERS_BENEFICIARY: string[] = [
    ...SurveySubmissionParserService.FIRST_HEADERS_BENEFICIARY,
    ...SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_HEADERS_BENEFICIARY,
    ...SurveySubmissionParserService.HARMONIZED_OUTLIER_HEADERS_BENEFICIARY,
    ...SurveySubmissionParserService.HARMONIZED_CROP,
    SurveySubmissionParserService.DEPENDENCY_RATIO_HEADERS_BENEFICIARY
  ]

  // ///////////////////////////////////////////////////////////////////////////
  // PARTNERS
  // ///////////////////////////////////////////////////////////////////////////

  public static readonly LAND_ACCESS = "Land_access"
  public static readonly EMPLOYMENT_ACCESS = "Employment_access"
  public static readonly FINANCE_ACCESS = "Finance_access"
  public static readonly O_1_FPACOST_USD = "O1FPACostUSD"
  public static readonly O_1_FPACASH_COST_USD = "O1FPACashCostUSD"
  public static readonly O_1_MPPA_1_COST_USD = "O1MPPA1CostUSD"
  public static readonly O_2_FPACOST_USD = "O2FPACostUSD"
  public static readonly O_2_FPACASH_COST_USD = "O2FPACashCostUSD"
  public static readonly O_3_FPACOST_USD = "O3FPACostUSD"
  public static readonly O_3_FPACASH_COST_USD = "O3FPACashCostUSD"
  public static HARMONIZED_HEADERS_PARTNER: string[] = [
    SurveySubmissionParserService.LAND_ACCESS,
    SurveySubmissionParserService.EMPLOYMENT_ACCESS,
    SurveySubmissionParserService.FINANCE_ACCESS,
    SurveySubmissionParserService.O_1_FPACOST_USD,
    SurveySubmissionParserService.O_1_FPACASH_COST_USD,
    SurveySubmissionParserService.O_1_MPPA_1_COST_USD,
    SurveySubmissionParserService.O_2_FPACOST_USD,
    SurveySubmissionParserService.O_2_FPACASH_COST_USD,
    SurveySubmissionParserService.O_3_FPACOST_USD,
    SurveySubmissionParserService.O_3_FPACASH_COST_USD,
    SurveySubmissionParserService.HARMONIZED_HEADERS_DATA_CLEANED
  ]

  public static getBeneficiaryHeaders(template: Template, isDataBridge = false): string[] {
    const headers: string[] = []
    headers.push(...this.HARMONIZED_HEADERS_BENEFICIARY)
    if (template.beneficiaryHeaders) {
      headers.push(...template.beneficiaryHeaders.filter(x => !this.HARMONIZED_HEADERS_BENEFICIARY.includes(x)).sort())
    }

    if (isDataBridge) {
      headers.push(this.DATA_BRIDGE_HEADERS_PPG)
    }

    return headers
  }

  public static getPartnerHeaders(template: Template, isDataBridge = false): string[] {
    let headers: string[] = []
    if (template.partnerHeaders) {
      headers = [...template.partnerHeaders]
    }

    if (isDataBridge) {
      headers.push(this.DATA_BRIDGE_HEADERS_PPG)
    }

    headers.push(...this.HARMONIZED_HEADERS_PARTNER)

    return headers
  }

  public static headersForVDataTables(headers: string[]): DataTableHeader[] {
    const vHeaders = []
    for (const header of headers) {
      vHeaders.push({
        text: header,
        value: header
      })
    }

    return vHeaders
  }

  public static getFieldsValues(surveySubmissions: SurveySubmission[]): Record<string, unknown>[] {
    const fieldsValue = []

    for (const surveySubmission of surveySubmissions) {
      if (surveySubmission.fields) {
        fieldsValue.push({ ...surveySubmission.fields, ...this.getHarmonizedValues(surveySubmission) })
      }
    }

    return fieldsValue
  }

  public static getHarmonizedValues(surveySubmission: SurveySubmission): Record<string, unknown> {
    if (surveySubmission.harmonizedFields) {
      return surveySubmission.harmonizedFields
    }

    return {}
  }

  static async updateSurveySubmissions(updatedData: HandsontableChangeCell[], surveySubmissions: SurveySubmission[]): Promise<void> {
    for (const updatedDatum of updatedData) {
      const surveySubmission = surveySubmissions.find(surveySubmission => {
        return surveySubmission.fields["_id"] === updatedDatum.id
      })
      if (surveySubmission) {
        surveySubmission.fields = { ...surveySubmission.fields, ...{ [updatedDatum.column]: updatedDatum.newValue } }
        SurveySubmissionModule.addSubmissionsToUpdate(surveySubmission)
      }
    }
  }
}
