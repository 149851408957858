






















import { Component, Vue, Watch } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import { TranslateResult } from "vue-i18n"

@Component({
  components: {
    Button,
    LayoutLoadable,
    TextHeader
  }
})
export default class ReportDataPublicationViewPdf extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  get pdfContent(): string | null {
    return TemplateSubmissionModule.currentSubmissionCanContent
  }

  get loading(): boolean {
    return TemplateSubmissionModule.currentSubmissionCanLoading
  }

  @Watch("submission", { immediate: true })
  async retrieveCanContent(): Promise<void> {
    if (this.submission) {
      await TemplateSubmissionModule.retrieveCanContent()
    }
  }

  async generatePDFExport(): Promise<void> {
    if (this.pdfContent) {
      window.open(this.pdfContent, "_blank")
    }
  }

  async openEnketoEditLink(): Promise<void> {
    if (this.submission != null) {
      const link = await TemplateSubmissionService.getEditLink(this.submission)
      if (typeof link === "string") {
        try {
          const url = new URL(link)
          window.open(url.toString(), "_blank")
        } catch {
          console.error(`Couldn't parse the url: ${link}`)
        }
      }
    }
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.templateview." + tKey)
  }
}
