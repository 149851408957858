













import { Component, Vue } from "vue-property-decorator"

import OverviewTableResume from "./OverviewTableResume.vue"
import OverviewTableTemplateSubmission from "./templateSubmission/OverviewTableTemplateSubmission.vue"
import OverviewTableSurveyPlan from "./OverviewTableSurveyPlan.vue"
import OverviewTableUserAccessManagement from "./userAccessManagement/OverviewTableUserAccessManagement.vue"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import OverviewTableSurveyDataManagement from "./OverviewTableSurveyDataManagement.vue"
import OverviewTableReportDataPublication from "./reportDataPublication/OverviewTableReportDataPublication.vue"

import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    OverviewTableResume,
    OverviewTableTemplateSubmission,
    OverviewTableSurveyPlan,
    OverviewTableUserAccessManagement,
    OverviewTableSurveyDataManagement,
    OverviewTableReportDataPublication,
    LayoutLoadable
  }
})
export default class OverviewContent extends Vue {
  get loading(): boolean {
    return TemplateSubmissionModule.loading
  }

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }
}
