









import { Component, Prop, Vue } from "vue-property-decorator"
import Avatar from "@/components/elements/Avatar.vue"

@Component({
  components: { Avatar }
})
export default class LayoutActiveUsers extends Vue {
  @Prop() usernames!: string[]

  get usernamesCount(): number {
    return this.usernames.length
  }
}
