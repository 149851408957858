




import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class Button extends Vue {
  inheritAttrs = false
  loadingDefaults = {
    color: "primary",
    size: "100"
  }

  @Prop({ default: false })
  loading!: boolean
}
