






import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class Avatar extends Vue {
  @Prop() username!: string

  get initials(): string {
    return this.username
      .split(" ")
      .map(i => i.charAt(0))
      .join("")
      .toUpperCase()
      .substring(0, 2)
  }

  inheritAttrs = false
  defaultsAttrs = {
    color: "accent darken-" + this.numberBasedOnUsername(4)
  }

  numberBasedOnUsername(max = 2): number {
    const min = 0
    let hash = 0
    for (let i = 0; i < this.username.length; i++) {
      hash = this.username.charCodeAt(i) + ((hash << 5) - hash)
    }
    return (Math.abs(hash) % (max - min + 1)) + min
  }
}
