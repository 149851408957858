var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('ButtonGroup',{attrs:{"description":[
            {
              key: _vm.keyBeneficiary,
              text: _vm.t('subMenu.beneficiary')
            },
            {
              key: _vm.keyPartner,
              text: _vm.t('subMenu.partner')
            }
          ]},model:{value:(_vm.currentKey),callback:function ($$v) {_vm.currentKey=$$v},expression:"currentKey"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":0,"outlined":""}},[_c('v-data-table',{staticClass:"table",attrs:{"disable-pagination":"","items":_vm.surveySubmissions,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"fixed-header":"","options":_vm.pagination,"server-items-length":_vm.itemCount,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions }},on:{"update:options":function($event){_vm.pagination=$event}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }