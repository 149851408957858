import { getModule, Module, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import { HarmonizationSettingSubmissionOutlier } from "@/model/interfaces/harmonizationSettings/outlier/harmonizationSettingSubmissionOutlier"
import HarmonizationSettingsOutlierService from "@/services/harmonizationSettings/harmonizationSettingsOutlier/HarmonizationSettingsOutlierService"
import VuexModuleLoading from "@/store/VuexModuleLoading"

@Module({ dynamic: true, store, name: "HarmonizationSettingsOutlierModule", namespaced: true })
class HarmonizationSettingsOutlierModule extends VuexModuleLoading {
  outliers = [] as HarmonizationSettingSubmissionOutlier[]

  @MutationAction({
    mutate: ["outliers"],
    rawError: true
  })
  async get() {
    getModule(HarmonizationSettingsOutlierModule).setLoading(true)
    const outliers = await HarmonizationSettingsOutlierService.get(TemplateSubmissionModule.currentSubmission as TemplateSubmission).finally(() => {
      getModule(HarmonizationSettingsOutlierModule).setLoading(false)
    })

    return {
      outliers: outliers
    }
  }
}

export default getModule(HarmonizationSettingsOutlierModule)
