





















import { Component, Vue } from "vue-property-decorator"

import LayoutNavbarHorizontal from "@/components/layouts/LayoutNavbarHorizontal.vue"
import LayoutNavbarHorizontalItem from "@/components/layouts/LayoutNavbarHorizontalItem.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    LayoutNavbarHorizontal,
    LayoutNavbarHorizontalItem
  }
})
export default class HeaderNavigation extends Vue {
  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }
}
