









































































































import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

import { Component } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import LayoutOverviewColumn from "@/components/layouts/LayoutOverviewColumn.vue"
import VueTrans from "@/mixins/VueTrans.vue"

@Component({
  components: {
    Button,
    LayoutOverviewColumn
  }
})
export default class OverviewTableSurveyDataManagement extends VueTrans {
  transKey = "dashboard.overview.surveyDataManagement."
  get submissions(): TemplateSubmission[] {
    return TemplateSubmissionModule.submissions
  }
}
