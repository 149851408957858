import { getModule, Module, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import { FaoValue } from "@/model/interfaces/fao/faoValue"
import FaoValueService from "@/services/fao/FaoValueService"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import FaoValueFilter from "@/model/interfaces/fao/faoValueFilter"

@Module({ dynamic: true, store, name: "FaoValueModule", namespaced: true })
class FaoValueModule extends VuexModuleSearch {
  faoValues: FaoValue[] = []

  filters: FaoValueFilter = {
    search: undefined
  }

  @MutationAction({
    mutate: ["faoValues"],
    rawError: true
  })
  async get() {
    getModule(FaoValueModule).setLoading(true)
    const results = await FaoValueService.get(this.filters, this.pagination).finally(() => {
      getModule(FaoValueModule).setLoading(false)
    })

    getModule(FaoValueModule).mergePagination(results.pagination)

    return {
      faoValues: results.faoValues
    }
  }
}

export default getModule(FaoValueModule)
