import MercureService from "@/services/mercure/MercureService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import MercureMessageType from "@/model/enum/mercureMessageType"

export default class TemplateSubmissionMercureService extends MercureService {
  static URI = "template_submissions/"
  static URI_ON_CREATE: string = TemplateSubmissionMercureService.URI + "create"

  static createEventSource(templateSubmission: TemplateSubmission): EventSource {
    const topic = process.env.VUE_APP_API_URL + this.URI + templateSubmission.id
    return this.newEventSource(topic)
  }

  static onMessage(eventSource: EventSource, type?: MercureMessageType): EventSource {
    eventSource.onmessage = async ev => {
      const submission = JSON.parse(ev.data)
      type = await this.getTypeFromMessageData(submission, type)
      switch (type) {
        case MercureMessageType.Create:
        case MercureMessageType.Delete:
          await TemplateSubmissionModule.updateTemplateSubmissions()
          break
        case MercureMessageType.Update:
          await TemplateSubmissionModule.updateSubmissionByIndex(submission)
          break
      }
    }

    return eventSource
  }

  static subscription(templateSubmission: TemplateSubmission): EventSource {
    return this.onMessage(this.createEventSource(templateSubmission))
  }

  static async subscriptions(templateSubmissions: TemplateSubmission[]): Promise<EventSource[]> {
    const eventSources: EventSource[] = []
    for (const submissions of templateSubmissions) {
      eventSources.push(this.subscription(submissions))
    }

    return eventSources
  }
}
