import { Mutation } from "vuex-module-decorators"
import VuexModulePagination from "@/store/VuexModulePagination"
import SearchFilter from "@/model/interfaces/searchFilter"

export default abstract class VuexModuleSearch extends VuexModulePagination {
  filters: SearchFilter = {
    search: undefined
  }

  @Mutation
  setSearch(search: string | null): void {
    this.filters.search = search ? search.trim() : undefined
  }
}
