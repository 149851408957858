import { NotificationFilter } from "@/model/interfaces/notification/notificationFilter"

export default class NotificationFilterService {
  static normalizeSearchFilter(search: string, filter: NotificationFilter): void {
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter.entityId = intValue
    }
    filter.country = search
    filter.dutyStation = search
  }

  static normalizeFilters(filter: NotificationFilter): NotificationFilter {
    const normalizedFilter: NotificationFilter = { year: filter.year }

    if (filter.type) {
      normalizedFilter.type = filter.type
    }
    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }
    return normalizedFilter
  }
}
