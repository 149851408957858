import SecurityService from "@/services/security/SecurityService"
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import UserService from "@/services/UserService"
import { AxiosError } from "axios"
import JwtCookie from "@/services/security/JwtCookie"
import { User } from "@/model/interfaces/user/user"
import { Notification } from "@/model/interfaces/notification/notification"
import NotificationModule from "@/store/modules/NotificationModule"
import VueI18n from "@/plugins/i18n"
import AvailableYearModule from "@/store/modules/AvailableYearModule"
import AzureService from "@/services/security/AzureService"
import GitInfoModule from "@/store/modules/GitInfoModule"

@Module({ dynamic: true, store, name: "SecurityModule", namespaced: true })
class SecurityModule extends VuexModule {
  public user: User | null = null
  public loginApiStatus = "Disconnected"
  public isAuthenticated = false
  public hasAcceptedPrivacyNotice = false
  public isAzure = false
  public isAdmin = false
  public isSupportTeam = false
  public isFocalPoint = false
  public errorMessage = ""
  public sessionIdleTimeout = 60000 * 30 // 30 minutes

  get hasUser() {
    return this.user !== null
  }

  @Action
  async isMeByIri(iri: string) {
    if (this.user) {
      // Quick fix because iri change on API V3 to /api/users/me
      return "/api/users/" + this.user.id === iri
    }

    return false
  }

  @Action
  public async logIn(user: { email: string; password: string }) {
    try {
      await SecurityService.login(user).then(async () => {
        await this.authUser()
      })
    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        this.setErrorMessage(err.response.data.message ?? (VueI18n.t("error.server") as string))
      }
    }
  }

  @Action
  public async authUser() {
    try {
      const response = await UserService.getMe()
      this.setLoginApiStatus(true)
      this.setUser(response.data)
      this.setIsAdmin(response.data)
      this.setIsSupportTeam(response.data)
      this.setIsFocalPoint(response.data)
      this.setHasAcceptedPrivacyNotice(response.data.acceptedPN)
      await NotificationModule.onCreate()
      await AvailableYearModule.get()
      GitInfoModule.resetGitInfo()
    } catch (error) {
      const err = error as AxiosError
      if (err.response) {
        this.setErrorMessage(err.response.data.message ?? (VueI18n.t("error.server") as string))
      }
    }
  }

  @Action
  public async logOut() {
    JwtCookie.clearCookies()
    this.setLoginApiStatus(false)
    this.setUser(null)
    this.setIsAdmin(false)
    this.setIsSupportTeam(false)
    this.setIsFocalPoint(false)
    this.setHasAcceptedPrivacyNotice(false)
    AzureService.removeAuthorizationHeader()
    await SecurityService.logout()
    // redirecting to home from HERE --> trigger checkAuthenticated -> action inside an action, it's bad !
  }

  @Action
  public async checkAuthenticated() {
    const jwtCookieIsValid = JwtCookie.isValid()
    if (AzureService.hasAzureTokenCookie()) {
      await AzureService.setToken()
    }
    const hasAuthorizationHeader = AzureService.hasAuthorizationHeader()
    if (!this.hasUser && (jwtCookieIsValid || hasAuthorizationHeader)) {
      await UserService.getMe().then(async response => {
        this.setUser(response.data)
        this.setIsAdmin(response.data)
        this.setIsSupportTeam(response.data)
        this.setIsFocalPoint(response.data)
        this.setHasAcceptedPrivacyNotice(response.data.acceptedPN)
        await NotificationModule.onCreate()
        await AvailableYearModule.get()
      })
    }

    this.setLoginApiStatus((jwtCookieIsValid || hasAuthorizationHeader) && this.hasUser)
  }

  @Mutation
  private setUser(user: any) {
    this.user = user
  }

  @Action
  async setLastNotification(notification: Notification) {
    if (this.user && this.user.nbUnseenNotifications !== undefined && this.user.nbUnseenNotifications > 0) {
      const response = await UserService.updateLastNotification(this.user, notification)
      this.setUser(response.data)
    }
  }

  @Mutation
  setNbUnseenNotifications(nb: number) {
    if (this.user) {
      this.user.nbUnseenNotifications = nb
    }
  }

  @Mutation
  private setLoginApiStatus(status: boolean) {
    this.isAuthenticated = status
    this.loginApiStatus = status ? "Authenticated" : "Disconnected"
  }

  @Mutation
  public setHasAcceptedPrivacyNotice(status: boolean) {
    this.hasAcceptedPrivacyNotice = status
  }

  @Mutation
  private setIsAdmin(user: any) {
    this.isAdmin = !!(user && user.roles.includes("ROLE_ADMIN"))
  }

  @Mutation
  private setIsSupportTeam(user: any) {
    this.isSupportTeam = !!(user && user.roles.includes("ROLE_SUPPORT_TEAM"))
  }
  @Mutation
  private setIsFocalPoint(user: any) {
    this.isFocalPoint = !!(user && user.roles.includes("ROLE_FOCAL_POINT"))
  }

  @Mutation
  private setErrorMessage(message: string) {
    this.errorMessage = message
  }
}

export default getModule(SecurityModule)
