




import { Component, Prop, Vue } from "vue-property-decorator"

import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"

@Component({
  components: {
    LayoutLoadable
  }
})
export default class LayoutSurveyManagement extends Vue {
  @Prop({ default: false })
  loading!: boolean
}
