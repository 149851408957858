









import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { Component, Vue } from "vue-property-decorator"
import Navigation from "./Navigation/Navigation.vue"
import TemplateModule from "@/store/modules/form/TemplateModule"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"

@Component({
  components: {
    Navigation
  }
})
export default class TemplateSubmission extends Vue {
  async created(): Promise<void> {
    const index = +this.$route.params.id
    await TemplateSubmissionModule.setCurrentSubmissionByIndex(index)
    await TemplateModule.getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
  }

  beforeDestroy(): void {
    TemplateSubmissionModule.clearCurrentSubmission()
    SurveySubmissionModule.clear()
    TemplateModule.clearTemplate()
  }
}
