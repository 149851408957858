






















































import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import TextFieldLogin from "@/components/inputs/TextFieldLogin.vue"

@Component({
  components: { ValidationObserver, ValidationProvider, TextFieldLogin }
})
export default class PasswordProtectionDialog extends Vue {
  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: String, default: "" }) label!: string
  dialog = false
  password = this.generateRandomPassword()
  showPassword = false
  showError = false
  copying = false

  copyPasswordToClipboard(): void {
    navigator.clipboard.writeText(this.password).then(() => {
      if (!this.copying) {
        this.copying = true
        setTimeout(() => (this.copying = false), 2000)
      }
    })
  }

  @Emit()
  confirm() {
    this.dialog = false
    return this.password
  }

  cancel(): void {
    this.dialog = false
  }

  generateRandomPassword(length = 10): string {
    const _list_alpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const _list_int = "0123456789"
    const _list_spec = "!,.@#-_{}[]"

    var temp = "",
      len = length / 2 - 1

    for (var i = 0; i < len; i++) temp += _list_alpha.charAt(Math.floor(Math.random() * _list_alpha.length))

    for (i = 0; i < len; i++) temp += _list_spec.charAt(Math.floor(Math.random() * _list_spec.length))

    for (i = 0; i < len; i++) temp += _list_int.charAt(Math.floor(Math.random() * _list_int.length))

    temp = temp
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("")

    return temp
  }
}
