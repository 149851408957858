


































import { Component, Vue } from "vue-property-decorator"
import { TranslateResult } from "vue-i18n"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

@Component
export default class TemplateSubmissionInfo extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.templateview.email." + tKey)
  }
}
