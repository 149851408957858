



































import { Component, PropSync, Watch } from "vue-property-decorator"
import VueTrans from "@/mixins/VueTrans.vue"
import HamTable from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/ham/HamTable.vue"
import HamForm from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/ham/HamForm.vue"
import UnitTable from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/unit/UnitTable.vue"
import UnitForm from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/unit/UnitForm.vue"
import OutlierTable from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/outlier/OutlierTable.vue"
import FaoCountryForm from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/ham/FaoCountryForm.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import HarmonizationSettingsFaoCountryModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsFaoCountryModule"
import FaoItemModule from "@/store/modules/fao/FaoItemModule"
import { FaoCountry } from "@/model/interfaces/fao/faoCountry"

@Component({
  components: { FaoCountryForm, OutlierTable, UnitForm, UnitTable, HamForm, HamTable }
})
export default class HarmonizationSettings extends VueTrans {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings."
  static readonly KEY_HAM_ANIMAL = "KEY_HAM_ANIMAL"
  static readonly KEY_HAM_CROP = "KEY_HAM_CROP"
  static readonly KEY_UNIT = "KEY_UNIT"
  static readonly KEY_OUTLIER = "KEY_OUTLIER"

  @PropSync("currentMenuKey", { default: HarmonizationSettings.KEY_HAM_ANIMAL }) currentMenuKeySync!: string

  getColor(key: string): string {
    return this.currentMenuKeySync === key ? "primary" : "primary lighten-2 primary--text"
  }

  setCurrentMenuKeySync(key: string): void {
    this.currentMenuKeySync = key
  }

  get keyHamAnimal(): string {
    return HarmonizationSettings.KEY_HAM_ANIMAL
  }

  get keyHamCrop(): string {
    return HarmonizationSettings.KEY_HAM_CROP
  }

  get keyUnit(): string {
    return HarmonizationSettings.KEY_UNIT
  }

  get keyOutlier(): string {
    return HarmonizationSettings.KEY_OUTLIER
  }

  get isKeyUnitSelected(): boolean {
    return this.currentMenuKeySync == this.keyUnit
  }

  get isKeyHamSelected(): boolean {
    return this.currentMenuKeySync == this.keyHamAnimal || this.currentMenuKeySync == this.keyHamCrop
  }

  get isKeyOutlierSelected(): boolean {
    return this.currentMenuKeySync == this.keyOutlier
  }

  get templateSubmission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  @Watch("templateSubmission", { immediate: true })
  async onTemplateSubmissionChanged(): Promise<void> {
    if (TemplateSubmissionModule.currentSubmission) {
      await HarmonizationSettingsFaoCountryModule.getByTemplateSubmission()
      if (HarmonizationSettingsFaoCountryModule.current.faoCountry as FaoCountry) {
        const faoCountry = HarmonizationSettingsFaoCountryModule.current.faoCountry as FaoCountry
        if (faoCountry.name) {
          FaoItemModule.setCountry(faoCountry.name)
          await FaoItemModule.getAll()
        }
      }
    }
  }
}
