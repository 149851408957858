import { Template } from "@/model/interfaces/form/template/template"
import SurveySubmissionParserService from "@/services/submission/surveySubmission/SurveySubmissionParserService"

export default class TemplateParserService {
  public static getBeneficiaryHeaders(template: Template): string[] {
    let headers: string[] = []
    if (template.beneficiaryHeaders) {
      headers = [...template.beneficiaryHeaders]
    }

    headers.push(...SurveySubmissionParserService.HARMONIZED_HEADERS_BENEFICIARY)

    return headers
  }

  public static getPartnerHeaders(template: Template): string[] {
    let headers: string[] = []
    if (template.partnerHeaders) {
      headers = [...template.partnerHeaders]
    }

    headers.push(...SurveySubmissionParserService.HARMONIZED_HEADERS_PARTNER)

    return headers
  }
}
