












import { Component, Vue } from "vue-property-decorator"

import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import PaginationLabel from "@/components/elements/PaginationLabel.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import { Pagination } from "@/model/interfaces/pagination"
import UserModule from "@/store/modules/UserModule"

@Component({
  components: {
    ComboboxMulti,
    ComboboxPagination,
    LayoutToolbar,
    PaginationLabel,
    SearchField
  }
})
export default class UserManagementToolbar extends Vue {
  // Pagination
  get pagination(): Pagination {
    return UserModule.pagination
  }

  get selectedItemPerPage(): number | null {
    return UserModule.pagination.itemsPerPage
  }
  set selectedItemPerPage(itemsPerPage: number | null) {
    UserModule.setItemsPerPage(itemsPerPage)
  }

  // Search
  get search(): string | null {
    return UserModule.filters.search ?? ""
  }
}
