

















import { Component } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import FaoValueModule from "@/store/modules/fao/FaoValueModule"
import { Pagination } from "@/model/interfaces/pagination"
import { mixins } from "vue-class-component"
import VueTrans from "@/mixins/VueTrans.vue"
import VuePagination from "@/mixins/VuePagination.vue"

@Component
export default class FaoTable extends mixins(VueTrans, VuePagination) {
  get headers(): DataTableHeader[] {
    return [
      { text: this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.table.headers.country"), value: "country.name", sortable: true },
      { text: this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.table.fao.headers.item"), value: "item.name", sortable: true },
      { text: this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.table.fao.headers.avg"), value: "value", sortable: true },
      { text: this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.table.headers.year"), value: "year", sortable: true }
    ] as DataTableHeader[]
  }

  get faoItems(): unknown[] {
    return FaoValueModule.faoValues
  }

  get loading(): boolean {
    return FaoValueModule.loading
  }

  get pagination(): Pagination {
    return FaoValueModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPaginationChanged(pagination, FaoValueModule)) {
      FaoValueModule.mergePagination(pagination)
      FaoValueModule.get()
    }
  }

  get itemsPerPage(): number {
    return FaoValueModule.pagination.itemsPerPage
  }

  get itemCount(): number {
    return FaoValueModule.pagination.itemCount
  }
}
