



















import { Component, Vue } from "vue-property-decorator"
import Status from "@/model/enum/submission/templateSubmission/templateSubmissionStatus"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import Button from "@/components/inputs/Button.vue"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import PaginationLabel from "@/components/elements/PaginationLabel.vue"
import { Pagination } from "@/model/interfaces/pagination"
import AvailableYearModule from "@/store/modules/AvailableYearModule"
import Year from "@/model/interfaces/year"

@Component({
  components: {
    Button,
    ComboboxMulti,
    ComboboxPagination,
    LayoutToolbar,
    PaginationLabel,
    SearchField
  }
})
export default class OverviewToolbar extends Vue {
  set selectedStatus(status: string[]) {
    TemplateSubmissionModule.setStatus(status)
    TemplateSubmissionModule.updateTemplateSubmissions()
  }

  get selectedStatus(): string[] {
    return TemplateSubmissionModule.filters.status ?? []
  }

  get status(): string[] {
    const statutes = []
    for (const status in Status) {
      statutes.push(status)
    }
    return statutes
  }

  // Pagination
  get pagination(): Pagination {
    return TemplateSubmissionModule.pagination
  }

  get selectedItemPerPage(): number | null {
    return TemplateSubmissionModule.pagination.itemsPerPage
  }
  set selectedItemPerPage(itemsPerPage: number | null) {
    TemplateSubmissionModule.setItemsPerPage(itemsPerPage)
    TemplateSubmissionModule.updateTemplateSubmissions()
  }

  // Years
  set selectedYears(years: number[]) {
    TemplateSubmissionModule.setYears(years)
    TemplateSubmissionModule.updateTemplateSubmissions()
  }

  get selectedYears(): number[] {
    return TemplateSubmissionModule.filters.year ?? []
  }

  get years(): Year[] {
    return AvailableYearModule.years
  }

  // Search
  get search(): string {
    return TemplateSubmissionModule.filters.search ?? ""
  }

  // Implements timeout to prevent request all the time
  set search(search: string) {
    TemplateSubmissionModule.setSearch(search)
    TemplateSubmissionModule.setCurrentPage(1)
    TemplateSubmissionModule.updateTemplateSubmissions()
  }

  async exportContent(): Promise<void> {
    const xlsxContent = await TemplateSubmissionService.getExport(TemplateSubmissionModule.filters)
    if (xlsxContent) {
      const link = document.createElement("a")
      link.href = xlsxContent
      link.download = "export.xlsx"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
