import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import {
  AgePyramidAgeGroup,
  AgePyramidAggregatedData,
  AgePyramidAggregatedDataKey,
  AgePyramidCategoryData,
  GlobalIndicatorsDataset
} from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"

interface rawGlobalIndicatorsAgePyramidData {
  category: string
  age: number
  gender: string
  count: number
}
export class GlobalIndicatorsAgePyramidFormatter {
  static format(data: rawGlobalIndicatorsAgePyramidData[]): AgePyramidAggregatedData {
    const result: AgePyramidAggregatedData = {
      O1: {} as AgePyramidCategoryData,
      O2: {} as AgePyramidCategoryData,
      O3: {} as AgePyramidCategoryData
    }
    const ageGroups: AgePyramidAgeGroup[] = ["15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64", "65+"]
    for (const category of ["O1", "O2", "O3"]) {
      result[category as AgePyramidAggregatedDataKey] = ageGroups.reduce((acc, group) => {
        acc[group] = { Male: 0, Female: 0 }
        return acc
      }, {} as { [key in AgePyramidAgeGroup]: { Male: number; Female: number } })
    }
    for (const entry of data) {
      const { age, category, count, gender } = entry
      if (age < 15 || !["O1", "O2", "O3"].includes(category) || !["Male", "Female"].includes(gender)) {
        continue
      }
      const ageGroup = this.getAgeGroup(age)
      if (gender === "Female") {
        // Negative values are needed to create a pyramid bar chart
        result[category as AgePyramidAggregatedDataKey][ageGroup].Female -= count
      } else {
        result[category as AgePyramidAggregatedDataKey][ageGroup].Male += count
      }
    }
    return result
  }

  static getAgeGroup(age: number): AgePyramidAgeGroup {
    if (age >= 15 && age <= 19) return "15-19"
    if (age >= 20 && age <= 24) return "20-24"
    if (age >= 25 && age <= 29) return "25-29"
    if (age >= 30 && age <= 34) return "30-34"
    if (age >= 35 && age <= 39) return "35-39"
    if (age >= 40 && age <= 44) return "40-44"
    if (age >= 45 && age <= 49) return "45-49"
    if (age >= 50 && age <= 54) return "50-54"
    if (age >= 55 && age <= 59) return "55-59"
    if (age >= 60 && age <= 64) return "60-64"
    return "65+"
  }
}
