import { Vue } from "vue-property-decorator"
import { Survey } from "@/model/interfaces/form/survey/survey"

export default class SurveysService {
  private static URI = "surveys"

  static async get(): Promise<Survey[]> {
    const response = await Vue.$axios.get(this.URI)
    return response.data["hydra:member"]
  }
}
