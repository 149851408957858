







import { Component } from "vue-property-decorator"
import Vue from "vue"
import ButtonLogout from "@/components/inputs/ButtonLogout.vue"
import LayoutPublic from "@/components/layouts/LayoutPublic.vue"
import WorkInProgress from "@/components/elements/WorkInProgress.vue"

@Component({
  components: {
    ButtonLogout,
    LayoutPublic,
    WorkInProgress
  }
})
export default class FocalPointView extends Vue {}
