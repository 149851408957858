import { Vue } from "vue-property-decorator"

export default class RequestUtils {
  static async getPDFasString(url: string): Promise<string> {
    try {
      const response = await Vue.$axios.get(url, {
        responseType: "arraybuffer"
      })
      return window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }))
    } catch (e) {
      return ""
    }
  }
}
