









import { Component } from "vue-property-decorator"
import GlobalHarmonizationSettings from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/GlobalHarmonizationSettings.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import CanDictionary from "@/components/dashboard/dataAnalysis/canDictionary/CanDictionary.vue"
import DataBridge from "@/components/dashboard/dataAnalysis/dataBridge/DataBridge.vue"
import DataAnalysisModule from "@/store/modules/dataAnalysis/dataAnalysisModule"
import DataAnalysisToolbar from "@/components/dashboard/dataAnalysis/DataAnalysisToolbar.vue"

@Component({
  components: {
    CanDictionary,
    DataAnalysisToolbar,
    DataBridge,
    GlobalHarmonizationSettings
  }
})
export default class DataAnalysisContent extends VueTrans {
  isKeyDataBridge(): boolean {
    return DataAnalysisModule.menuKey === DataAnalysisModule.KEY_DATA_BRIDGE
  }
  isKeyHarmonization(): boolean {
    return DataAnalysisModule.menuKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING
  }

  isKeyCanDictionary(): boolean {
    return DataAnalysisModule.menuKey === DataAnalysisModule.KEY_CAN_DICTIONARY
  }
}
