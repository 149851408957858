








































import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TranslateResult } from "vue-i18n"
import { Component, Ref, Vue } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import EmailCandidate from "@/model/interfaces/submission/templateSubmission/EmailCandidate"

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class SurveyPlanEmailCC extends Vue {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  emailCandidate = ""

  get emailsCandidates(): EmailCandidate[] {
    return TemplateSubmissionModule.currentEmailsCandidates
  }

  removeEntry(index: number): void {
    if (index > -1) {
      this.emailsCandidates.splice(index, 1)
    }
  }

  addEmailCandidate(): void {
    if (this.form) {
      this.form.validate().then((success: boolean) => {
        if (!success) {
          return
        }
        if (this.emailCandidate) {
          this.emailsCandidates.push({ email: this.emailCandidate, errorMessage: null })
          this.emailCandidate = ""
        }
      })
    }
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.surveyplan." + tKey)
  }
}
