import { Mutation, VuexModule } from "vuex-module-decorators"

export default abstract class VuexModuleLoading extends VuexModule {
  loading = false

  @Mutation
  setLoading(bool: boolean): void {
    this.loading = bool
  }
}
