import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import GitInfoService from "@/services/GitInfoService"
import GitInfo from "@/model/interfaces/gitInfo"

@Module({ dynamic: true, store, name: "GitInfoModule", namespaced: true })
class GitInfoModule extends VuexModule {
  gitInfo: GitInfo = {
    commitAuthor: "",
    commitDate: "",
    commitMessage: "",
    tag: "",
    commit: "",
    branch: ""
  }

  @Action
  async get() {
    if (this.gitInfo.commit === "") {
      const gitInfo = await GitInfoService.get()
      this.setGitInfo(gitInfo)
    }
  }

  @Mutation
  setGitInfo(gitInfo: GitInfo) {
    this.gitInfo = gitInfo
  }

  @Mutation
  resetGitInfo() {
    this.gitInfo = {
      commitAuthor: "",
      commitDate: "",
      commitMessage: "",
      tag: "",
      commit: "",
      branch: ""
    }
  }
}

export default getModule(GitInfoModule)
