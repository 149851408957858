





















import { Component, Vue } from "vue-property-decorator"
import LayoutSubmissionView from "@/components/layouts/LayoutSubmissionView.vue"
import LayoutNavbarHorizontal from "@/components/layouts/LayoutNavbarHorizontal.vue"
import LayoutNavbarHorizontalItem from "@/components/layouts/LayoutNavbarHorizontalItem.vue"

@Component({
  components: {
    LayoutNavbarHorizontal,
    LayoutNavbarHorizontalItem,
    LayoutSubmissionView
  }
})
export default class SurveyDataManagement extends Vue {}
