














































import { Component, Prop, Ref } from "vue-property-decorator"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import VueTrans from "@/mixins/VueTrans.vue"
import { HarmonizationSettingSubmissionHam } from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHam"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import ApiViolationService from "@/services/api/ApiViolationService"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import FaoItemModule from "@/store/modules/fao/FaoItemModule"
import HarmonizationSettingSubmissionHamSubType from "@/model/enum/harmonizationSetting/ham/harmonizationSettingSubmissionHamSubType"

@Component({
  components: { SwitchCustom, ValidationObserver, ValidationProvider }
})
export default class HamForm extends VueTrans {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  @Ref("convertTo") readonly convertTo!: HTMLInputElement
  @Prop({ type: Boolean, default: false }) isGlobal!: boolean
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.form.ham."

  get ham(): HarmonizationSettingSubmissionHam {
    return HarmonizationSettingsHamModule.current
  }

  get items(): { text: string; value: string }[] {
    const items = []
    if (this.filterSubType === HarmonizationSettingSubmissionHamSubType.CROP) {
      for (const faoItem of FaoItemModule.faoItems) {
        items.push({ text: faoItem.name as string, value: faoItem.name as string })
      }
    }

    return items
  }

  get dialogForm(): boolean {
    return HarmonizationSettingsHamModule.dialogForm
  }

  get loading(): boolean {
    return HarmonizationSettingsHamModule.loading
  }

  get filterSubType(): HarmonizationSettingSubmissionHamSubType | undefined {
    return HarmonizationSettingsHamModule.filters.subType
  }

  get convertToError(): string | undefined {
    return ApiViolationService.getByPropertyPath("convertTo")
  }

  async submit(): Promise<void> {
    try {
      this.convertTo.blur()
      this.$nextTick(async () => {
        await HarmonizationSettingsHamModule.saveCurrent()
      })
    } finally {
      this.close()
    }
  }

  close(): void {
    this.form.reset()
    HarmonizationSettingsHamModule.setDialogForm(false)
  }
}
