import HarmonizationSettingSubmissionCurrencyFilter from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrencyFilter"

export default class HarmonizationSettingsCurrencyFilterService {
  static normalizeSearchFilter(search: string, filter: HarmonizationSettingSubmissionCurrencyFilter): void {
    filter.name = search
    filter["exchangeCountry.name"] = search
    filter["exchangeCountry.currency"] = search
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter.year = intValue
    }
  }

  static normalizeFilters(filter: HarmonizationSettingSubmissionCurrencyFilter): HarmonizationSettingSubmissionCurrencyFilter {
    const normalizedFilter: HarmonizationSettingSubmissionCurrencyFilter = {}

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    if (filter.global !== undefined) {
      normalizedFilter.global = filter.global
    }

    return normalizedFilter
  }
}
