import { Vue } from "vue-property-decorator"
import { Template } from "@/model/interfaces/form/template/template"
import { AxiosResponse } from "axios"
import { TemplateDescriptor } from "@/model/interfaces/form/template/templateDescriptor"
import TemplateDescriptorType from "@/model/enum/form/template/templateDescriptorType"

export default class TemplateService {
  private static URI = "templates"

  static async get(): Promise<Template[]> {
    const response = await Vue.$axios.get(this.URI)
    return response.data["hydra:member"]
  }

  static async getOne(id: number): Promise<Template | null> {
    try {
      const response = await Vue.$axios.get(this.URI + "/" + id)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async getSummary(): Promise<Template[]> {
    const response = await Vue.$axios.get(this.URI + "/summary")
    return response.data["hydra:member"]
  }

  static async update(template: Partial<Template>): Promise<Template | null> {
    try {
      const response = await Vue.$axios.patch(this.URI + "/" + template.id, template)
      return response.data
    } catch (e) {
      return null
    }
  }

  static sortTemplatesByYear(templates: Template[]): Template[] {
    return templates.sort((a, b) => {
      if (!a.year) {
        return 1
      }
      if (!b.year) {
        return -1
      }

      if (a.year < b.year) {
        return 1
      } else if (a.year > b.year) {
        return -1
      }

      return 0
    })
  }

  static getTemplateDescriptorByType(template: Template, templateDescriptorType: TemplateDescriptorType): TemplateDescriptor | null {
    if (template.templateDescriptors) {
      for (const templateDescriptor of template.templateDescriptors) {
        if (templateDescriptor.type === templateDescriptorType) {
          return templateDescriptor
        }
      }
    }

    return null
  }
}
