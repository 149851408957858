var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPaginable',{staticClass:"LayoutSurveyManagement",scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('LayoutToolbar',{attrs:{"title":_vm.$t('dashboard.surveymanagement.title')}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"LayoutSurveyManagement__contentContainer"},[_c('ButtonGroup',{attrs:{"description":[
          {
            key: _vm.KEY_TEMPLATE,
            text: _vm.$t('dashboard.surveymanagement.template.title')
          },
          {
            key: _vm.KEY_BENEFICIARIES,
            text: _vm.$t('dashboard.surveymanagement.beneficiary.title')
          }
        ]},model:{value:(_vm.currentKey),callback:function ($$v) {_vm.currentKey=$$v},expression:"currentKey"}}),(_vm.currentKey === _vm.KEY_TEMPLATE)?_c('v-row',{staticClass:"text-right",attrs:{"justify":"end"}},[_c('v-col',[_c('Button',{staticClass:"mr-1",attrs:{"outlined":"","small":"","download":_vm.templateDescriptorFileNameByType(_vm.TemplateDescriptorType.PDF_REPORT),"href":_vm.templateDescriptorContentUrlByType(_vm.TemplateDescriptorType.PDF_REPORT)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$mdiDownload")]),_vm._v(_vm._s(_vm.$t("dashboard.surveymanagement.template.button.download.pdf_report")))],1),_c('Button',{attrs:{"outlined":"","small":"","download":_vm.templateDescriptorFileNameByType(_vm.TemplateDescriptorType.SUMMARY),"href":_vm.templateDescriptorContentUrlByType(_vm.TemplateDescriptorType.SUMMARY)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$mdiDownload")]),_vm._v(_vm._s(_vm.$t("dashboard.surveymanagement.template.button.download.summary")))],1)],1)],1):_vm._e(),(_vm.currentKey === _vm.KEY_TEMPLATE)?_c('SurveyManagementTemplate',{staticClass:"LayoutSurveyManagement__content"}):_c('SurveyManagementBeneficiary',{staticClass:"LayoutSurveyManagement__content"})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }