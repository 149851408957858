



































import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { Component, Prop, Ref, Vue } from "vue-property-decorator"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import { UserAccessManagementStatus } from "@/model/enum/user/userAccessManagementStatus"
import { ValidationObserver, ValidationProvider } from "vee-validate"

@Component({
  components: {
    SwitchCustom,
    ValidationObserver,
    ValidationProvider
  }
})
export default class OverviewTableUserAccessManagementLine extends Vue {
  @Prop({ default: {} }) submission!: TemplateSubmission
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>

  updating = false
  lastValidUsername = this.submission.uamUsername

  get status(): UserAccessManagementStatus {
    return this.submission.uamStatus ?? UserAccessManagementStatus.UNDEFINED
  }

  get isSwitchDisabledAccessTemplate(): boolean {
    return this.submission.uamStatus === UserAccessManagementStatus.NOT_FOUND || this.submission.uamStatus === UserAccessManagementStatus.PENDING
  }

  async setUAMToTemplate(): Promise<void> {
    if (this.form) {
      this.updating = true
      this.form
        .validate()
        .then(async (success: boolean) => {
          if (!success) {
            return
          }
          const { uamUsername, uamIsAccessToTemplateEnabled, id } = this.submission
          const result = await TemplateSubmissionService.patchTemplateSubmissionUAM({ uamUsername, uamIsAccessToTemplateEnabled, id })
          if (result) {
            this.submission.uamIsAccessToTemplateEnabled = result.uamIsAccessToTemplateEnabled
            this.submission.uamUsername = result.uamUsername
            this.submission.uamStatus = result.uamStatus
            this.lastValidUsername = result.uamUsername
          } else {
            this.submission.uamUsername = this.lastValidUsername
          }
        })
        .finally(() => {
          this.updating = false
        })
    }
  }
}
