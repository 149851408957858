















import { Component, Watch } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import PartnerDataSheetToolBar from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/PartnerDataSheetToolBar.vue"
import "handsontable/dist/handsontable.full.min.css"
import Handsontable from "@/components/elements/Handsontable/Handsontable.vue"
import OriginalData from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/OriginalData.vue"
import HarmonizationSettings from "@/components/templateSubmission/surveyDataManagement/partnerDataSheet/harmonizationSettings/HarmonizationSettings.vue"

@Component({
  components: { HarmonizationSettings, OriginalData, Handsontable, PartnerDataSheetToolBar, SearchField, TextHeader }
})
export default class PartnerDataSheetView extends VueTrans {
  static readonly KEY_ORIGINAL_DATA = "original-data"
  static readonly KEY_HARMONIZATION_SETTINGS = "harmonization-settings"
  transKey = "templatesubmission.surveydatamanagement."
  search = ""
  selected = PartnerDataSheetView.KEY_ORIGINAL_DATA

  async mounted(): Promise<void> {
    await this.updateSurveySubmissions()
  }

  get keyOriginalData(): string {
    return PartnerDataSheetView.KEY_ORIGINAL_DATA
  }

  get templateSubmission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  @Watch("templateSubmission")
  async onTemplateSubmissionChange(): Promise<void> {
    await this.updateSurveySubmissions()
  }

  private async updateSurveySubmissions() {
    if (TemplateSubmissionModule.currentSubmission) {
      SurveySubmissionModule.setFormType(SurveyFormType.Partner)
      await SurveySubmissionModule.getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
    }
  }
}
