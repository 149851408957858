






import { Component, Vue } from "vue-property-decorator"

@Component
export default class TextField extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    clearable: true,
    color: "navy",
    height: "44",
    outlined: true,
    dense: true,
    flat: true,
    "single-line": true,
    "hide-details": true,
    solo: true
  }
}
