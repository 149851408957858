var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":0,"outlined":""}},[_c('v-data-table',{staticClass:"table",attrs:{"loading":_vm.loading,"fixed-header":"","headers":_vm.headers,"items":_vm.surveySubmissionHistories,"expanded":_vm.expanded,"show-expand":"","items-per-page":_vm.itemsPerPage,"server-items-length":_vm.itemCount,"options":_vm.pagination,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions },"height":"60vh"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.loggedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$d(new Date(item.loggedAt), "long")))])]}},{key:"item.objectId",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getId(item))}})]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getUsername(item))}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"scrollable",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.getHeadersOfItemFields(item.data.fields),"items":[item.data.fields],"hide-default-footer":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }