









import { Component, Prop, PropSync } from "vue-property-decorator"
import { ValidationProvider } from "vee-validate"
import DateInput from "@/components/inputs/DateInput.vue"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import VueTrans from "@/mixins/VueTrans.vue"

@Component({
  components: {
    DateInput,
    ValidationProvider
  }
})
export default class SurveyPlanFormDate extends VueTrans {
  @PropSync("date", { default: null }) dateSync!: Date | null
  @Prop({ type: String, default: "" }) translateKey!: string
  @Prop({ default: null }) min!: string | null
  @Prop({ default: null }) max!: string | null

  transKey = this.translateKey

  get canEdit(): boolean {
    return TemplateSubmissionModule.canEdit
  }
}
