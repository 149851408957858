




































































import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsCategoryBanner from "../reusableComponents/GlobalIndicatorsCategoryBanner.vue"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import GlobalIndicatorsFigure from "../reusableComponents/GlobalIndicatorsFigure.vue"
import GlobalIndicatorsChart from "../reusableComponents/GlobalIndicatorsChart.vue"
import { GlobalIndicatorsChartDefinitions } from "@/services/globalIndicators/GlobalIndicatorsChartDefinitions"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import GlobalIndicatorsChartLegend from "../reusableComponents/GlobalIndicatorsChartLegend.vue"
import GlobalIndicatorsUniqueIndicatorBar from "../reusableComponents/UniqueIndicatorBar.vue"
@Component({
  components: {
    GlobalIndicatorsCategoryBanner,
    GlobalIndicatorsFigure,
    GlobalIndicatorsChart,
    GlobalIndicatorsChartLegend,
    GlobalIndicatorsUniqueIndicatorBar
  }
})
export default class GlobalIndicatorsFinancialProfile extends Vue {
  get employmentFinancialChartsConfig() {
    return GlobalIndicatorsModule.surveyedChartsConfig
  }

  getChartLegends(chartId: GlobalIndicatorsDatasetKey) {
    return GlobalIndicatorsChartDefinitions.getChartDefinitions()[chartId]?.legend
  }

  get incomeChangeTrend(): number {
    if (GlobalIndicatorsModule.dataset) {
      try {
        return GlobalIndicatorsModule.dataset.incomeChangeByLine.Increased[1] - GlobalIndicatorsModule.dataset.incomeChangeByLine.Increased[0]
      } catch (error) {
        return 0
      }
    }
    return 0
  }

  get savingServiceAccessTrend(): number {
    if (GlobalIndicatorsModule.dataset) {
      try {
        return GlobalIndicatorsModule.dataset.savingServiceAccess.Formal[1] - GlobalIndicatorsModule.dataset.savingServiceAccess.Formal[0]
      } catch (error) {
        return 0
      }
    }
    return 0
  }

  get savingChangeTrend(): number {
    if (GlobalIndicatorsModule.dataset) {
      try {
        return GlobalIndicatorsModule.dataset.savingChangeByLine.Increased[1] - GlobalIndicatorsModule.dataset.savingChangeByLine.Increased[0]
      } catch (error) {
        return 0
      }
    }
    return 0
  }

  get loanServiceAccessTrend(): number {
    if (GlobalIndicatorsModule.dataset) {
      try {
        return GlobalIndicatorsModule.dataset.loanServiceAccess.Formal[1] - GlobalIndicatorsModule.dataset.loanServiceAccess.Formal[0]
      } catch (error) {
        return 0
      }
    }
    return 0
  }

  get marketAccessTrend(): number {
    if (GlobalIndicatorsModule.dataset) {
      try {
        return (GlobalIndicatorsModule.dataset.marketAccess.Yes_Formal[1] + GlobalIndicatorsModule.dataset.marketAccess.Yes_Informal[1]) - (GlobalIndicatorsModule.dataset.marketAccess.Yes_Formal[0] + GlobalIndicatorsModule.dataset.marketAccess.Yes_Informal[0])
      } catch (error) {
        return 0
      }
    }
    return 0
  }
}
