























import { Component, Vue, Watch } from "vue-property-decorator"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
@Component({
  components: {
    ButtonGroup
  }
})
export default class GlobalIndicatorsCategorySelector extends Vue {
  beneficiaries = "beneficiaries"
  employment = "employment"
  financialInclusion = "financialInclusion"
  currentKey = this.beneficiaries

  @Watch("currentKey")
  onCurrentKeyChanged() {
    const element = document.getElementById(this.currentKey)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}
