

























import { Component, Prop, Vue } from "vue-property-decorator"
import NotificationModule from "@/store/modules/NotificationModule"
import { Notification } from "@/model/interfaces/notification/notification"
import notificationType from "@/model/enum/notification/notificationType"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { Pagination } from "@/model/interfaces/pagination"

@Component({})
export default class NotificationTable extends Vue {
  @Prop({ type: Boolean, default: false }) hasPagination!: boolean

  get pagination(): Pagination {
    return NotificationModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPagination) {
      NotificationModule.mergePagination(pagination)
      NotificationModule.get()
    }
  }

  get loading(): boolean {
    return NotificationModule.loading
  }

  get headers(): Record<string, unknown>[] {
    return [
      {
        text: this.$t("notification.table.header.date"),
        value: "createdAt"
      },
      { text: this.$t("notification.table.header.message"), value: "message", sortable: false },
      { text: this.$t("notification.table.header.template_id"), value: "entityId" },
      { text: this.$t("notification.table.header.country"), value: "country" },
      { text: this.$t("notification.table.header.duty_station"), value: "dutyStation" },
      { text: this.$t("notification.table.header.year"), value: "year" },
      { text: this.$t("notification.table.header.username"), value: "createdBy.username" }
    ]
  }

  get notifications(): Notification[] {
    return NotificationModule.notifications
  }

  get itemsPerPage(): number {
    return NotificationModule.pagination.itemsPerPage
  }

  getMessage(notification: Notification): string {
    if (notification.type === notificationType.GRANTED || notification.type === notificationType.UNGRANTED) {
      return this.$t("notification.type.long_" + notification.type, { username: notification.content?.uamUsername, status: notification.content?.uamStatus }).toString()
    }
    if (notification.type === notificationType.BENEFICIARY_SURVEY_SUBMISSION_CREATED || notification.type === notificationType.PARTNER_SURVEY_SUBMISSION_CREATED) {
      return this.$tc("notification.type.long_" + notification.type, notification.content?.count as number).toString()
    }
    return this.$t("notification.type." + notification.type).toString()
  }

  routeTo(item: Notification): void {
    if (item.entityId !== undefined) {
      TemplateSubmissionModule.setSearch(item.entityId.toString())
      this.$router.push({ name: "overview" })
    }
  }
}
