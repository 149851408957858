import { Vue } from "vue-property-decorator"
import { AxiosResponse } from "axios"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { HarmonizationSettingSubmissionCurrency } from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrency"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import HarmonizationSettingSubmissionCurrencyFilter from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrencyFilter"
import HarmonizationSettingsCurrencyFilterService from "@/services/harmonizationSettings/harmonizationSettingsCurrency/HarmonizationSettingsCurrencyFilterService"

export default class HarmonizationSettingsCurrencyService {
  private static URI = "harmonization_setting_submission_currencies"

  static async getByTemplateSubmission(templateSubmission: TemplateSubmission): Promise<{ currencies: HarmonizationSettingSubmissionCurrency[] }> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI)

    return {
      currencies: response.data["hydra:member"]
    }
  }

  static async get(
    filter: HarmonizationSettingSubmissionCurrencyFilter,
    pagination: Pagination
  ): Promise<{ currencies: HarmonizationSettingSubmissionCurrency[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...HarmonizationSettingsCurrencyFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  static async delete(currency: HarmonizationSettingSubmissionCurrency): Promise<AxiosResponse> {
    return await Vue.$axios.delete(this.URI + "/" + currency.id)
  }

  static async post(currency: HarmonizationSettingSubmissionCurrency): Promise<HarmonizationSettingSubmissionCurrency> {
    const response = await Vue.$axios.post(this.URI, currency)
    return response.data
  }

  static async patch(currency: HarmonizationSettingSubmissionCurrency): Promise<HarmonizationSettingSubmissionCurrency> {
    const response = await Vue.$axios.patch(this.URI + "/" + currency.id, currency)
    return response.data
  }

  private static getResultWihPagination(response: AxiosResponse, pagination: Pagination) {
    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      currencies: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static getRateByYear(item: HarmonizationSettingSubmissionCurrency): string | undefined {
    if (typeof item.exchangeCountry === "object" && item.exchangeCountry.exchangeRates) {
      for (const exchangeRate of item.exchangeCountry.exchangeRates) {
        if (exchangeRate.calendarYear === item.year) {
          return exchangeRate.rate
        }
      }
    }

    return ""
  }
}
