import { Action, getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import HarmonizationSettingsHamService from "@/services/harmonizationSettings/harmonizationSettingsHam/HarmonizationSettingsHamService"
import HarmonizationSettingSubmissionHamFilter from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHamFilter"
import { HarmonizationSettingSubmissionHam } from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHam"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import HarmonizationSettingSubmissionHamSubType from "@/model/enum/harmonizationSetting/ham/harmonizationSettingSubmissionHamSubType"

@Module({ dynamic: true, store, name: "HarmonizationSettingsHamModule", namespaced: true })
class HarmonizationSettingsHamModule extends VuexModuleSearch {
  hams = [] as HarmonizationSettingSubmissionHam[]
  current: HarmonizationSettingSubmissionHam = { global: false }
  dialogForm = false

  filters: HarmonizationSettingSubmissionHamFilter = {
    search: undefined
  }

  @Mutation
  setCurrent(ham: HarmonizationSettingSubmissionHam) {
    this.current = ham
  }

  @Mutation
  setHams(hams: HarmonizationSettingSubmissionHam[]) {
    this.hams = hams
  }

  @Mutation
  setDialogForm(dialogForm: boolean) {
    this.dialogForm = dialogForm
  }

  @Mutation
  setGlobalFilter(global: boolean) {
    this.filters.global = global
  }

  @Mutation
  setSubTypeFilter(subType: HarmonizationSettingSubmissionHamSubType) {
    this.filters.subType = subType
  }

  @MutationAction({
    mutate: ["hams"],
    rawError: true
  })
  async getByTemplateSubmission() {
    getModule(HarmonizationSettingsHamModule).setLoading(true)
    const results = await HarmonizationSettingsHamService.getByTemplateSubmission(
      this.filters,
      this.pagination,
      TemplateSubmissionModule.currentSubmission as TemplateSubmission
    ).finally(() => {
      getModule(HarmonizationSettingsHamModule).setLoading(false)
    })

    getModule(HarmonizationSettingsHamModule).mergePagination(results.pagination)

    return {
      hams: results.hams
    }
  }

  @Action
  async getGlobal() {
    this.setLoading(true)
    this.setGlobalFilter(true)
    const results = await HarmonizationSettingsHamService.get(this.filters, this.pagination).finally(() => {
      this.setLoading(false)
    })

    this.mergePagination(results.pagination)

    this.setHams(results.hams)
  }

  @Action
  async delete(ham: HarmonizationSettingSubmissionHam) {
    try {
      getModule(HarmonizationSettingsHamModule).setLoading(true)
      await HarmonizationSettingsHamService.delete(ham)
      const hamIndex = getModule(HarmonizationSettingsHamModule).hams.findIndex(item => item.id === ham.id)
      if (hamIndex !== -1) {
        getModule(HarmonizationSettingsHamModule).hams.splice(hamIndex, 1)
      }
    } finally {
      await SurveySubmissionModule.reloadByCurrentSubmission()
      getModule(HarmonizationSettingsHamModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async saveCurrent() {
    getModule(HarmonizationSettingsHamModule).setLoading(true)
    try {
      if (this.current["@id"] === undefined) {
        this.current.templateSubmission = TemplateSubmissionModule.currentSubmission?.["@id"]
        getModule(HarmonizationSettingsHamModule).setCurrent(await HarmonizationSettingsHamService.post(this.current))
      } else {
        getModule(HarmonizationSettingsHamModule).setCurrent(await HarmonizationSettingsHamService.patch(this.current))
      }
    } finally {
      if (TemplateSubmissionModule.currentSubmission) {
        await getModule(HarmonizationSettingsHamModule).reloadByTemplateSubmission()
      } else {
        await getModule(HarmonizationSettingsHamModule).getGlobal()
      }
      getModule(HarmonizationSettingsHamModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async reloadByTemplateSubmission() {
    await this.getByTemplateSubmission()
    await SurveySubmissionModule.reloadByCurrentSubmission()
  }
}

export default getModule(HarmonizationSettingsHamModule)
