






import { Component, Vue } from "vue-property-decorator"

import Combobox from "@/components/inputs/Combobox.vue"

@Component({
  components: {
    Combobox
  }
})
export default class ComboboxMulti extends Vue {
  inheritAttrs = false
}
