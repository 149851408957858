var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex mt-6"},[_c('ButtonGroup',{staticClass:"GlobalIndicators__categorySelector",attrs:{"description":[
      {
        key: _vm.beneficiaries,
        text: _vm.$t('dashboard.globalindicators.selectors.beneficiaries')
      },
      {
        key: _vm.employment,
        text: _vm.$t('dashboard.globalindicators.selectors.employment')
      },
      {
        key: _vm.financialInclusion,
        text: _vm.$t('dashboard.globalindicators.selectors.financialInclusion')
      }
    ],"fullWidth":true},model:{value:(_vm.currentKey),callback:function ($$v) {_vm.currentKey=$$v},expression:"currentKey"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }