import { XLSX_FORMAT } from "@/model/constants"

export default class DocumentUtils {
  static createXlsx(data: BlobPart, name = "export"): void {
    name += XLSX_FORMAT
    const xlsxContent = window.URL.createObjectURL(new Blob([data]))
    if (xlsxContent) {
      const link = document.createElement("a")
      link.href = xlsxContent
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
