import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import { FaoItem } from "@/model/interfaces/fao/faoItem"
import FaoItemService from "@/services/fao/FaoItemService"
import FaoItemFilter from "@/model/interfaces/fao/faoItemFilter"
import { HarmonizationSettingSubmissionFaoCountry } from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountry"
import { FaoCountry } from "@/model/interfaces/fao/faoCountry"

@Module({ dynamic: true, store, name: "FaoItemModule", namespaced: true })
class FaoItemModule extends VuexModule {
  faoItems: FaoItem[] = []
  filters: FaoItemFilter = {}

  @Mutation
  setFaoItems(faoItems: FaoItem[]): void {
    this.faoItems = faoItems
  }

  @Mutation
  setCountry(country: string): void {
    this.filters.country = country
  }

  @Action
  async getAll(): Promise<void> {
    const faoItems = await FaoItemService.get(this.filters)

    this.setFaoItems(faoItems)
  }

  @Action
  async getAllByHarmonizationSettingsFaoCountry(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry): Promise<void> {
    if (harmonizationSettingSubmissionFaoCountry.faoCountry) {
      const faoCountry = harmonizationSettingSubmissionFaoCountry.faoCountry as FaoCountry
      if (faoCountry.name) {
        this.setCountry(faoCountry.name)
        await this.getAll()
      }
    }
  }
}

export default getModule(FaoItemModule)
