import { Module, MutationAction, getModule, Mutation } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleLoading from "@/store/VuexModuleLoading"
import { ExchangeRateFilter } from "@/model/interfaces/exchange/exchangeRateFilter"
import ExchangeRateService from "@/services/exchange/ExchangeRateService"
import { ExchangeRate } from "@/model/interfaces/exchange/exchangeRate"
import { NEWEST_YEAR } from "@/model/years"

@Module({ dynamic: true, store, name: "ExchangeRateModule", namespaced: true })
class ExchangeRateModule extends VuexModuleLoading {
  exchangeRates: ExchangeRate[] = []

  filters: ExchangeRateFilter = {
    year: NEWEST_YEAR
  }

  @Mutation
  setYear(years: number | undefined) {
    this.filters.year = years
  }

  @MutationAction({
    mutate: ["exchangeRates"]
  })
  async get() {
    getModule(ExchangeRateModule).setLoading(true)
    const exchangeRates = await ExchangeRateService.get(this.filters).finally(() => {
      getModule(ExchangeRateModule).setLoading(false)
    })

    return {
      exchangeRates
    }
  }
}

export default getModule(ExchangeRateModule)
