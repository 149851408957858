import TemplateSubmission from "@/views/TemplateSubmission/TemplateSubmission.vue"
import TemplateView from "@/views/TemplateSubmission/TemplateView/TemplateView.vue"
import TemplateViewPdf from "@/views/TemplateSubmission/TemplateView/TemplateViewPdf.vue"
import TemplateViewEmail from "@/views/TemplateSubmission/TemplateView/TemplateViewEmail.vue"
import TemplateViewPdfIframe from "@/views/TemplateSubmission/TemplateView/TemplateViewPdfIframe.vue"
import SurveyPlan from "@/views/TemplateSubmission/SurveyPlan/SurveyPlan.vue"
import UserAccessManagement from "@/views/TemplateSubmission/UserAccessManagement/UserAccessManagement.vue"
import SurveyDataManagement from "@/views/TemplateSubmission/SurveyDataManagement/SurveyDataManagement.vue"
import ReportDataPublicationView from "@/views/TemplateSubmission/ReportDataPublication/ReportDataPublicationView.vue"
import ReportDataPublicationViewPdf from "@/views/TemplateSubmission/ReportDataPublication/ReportDataPublicationViewPdf.vue"
import BeneficiaryDataSheetView from "@/views/TemplateSubmission/SurveyDataManagement/BeneficiaryDataSheet/BeneficiaryDataSheetView.vue"
import PartnerDataSheetView from "@/views/TemplateSubmission/SurveyDataManagement/PartnerDataSheet/PartnerDataSheetView.vue"
import SampleProfileView from "@/views/TemplateSubmission/SurveyDataManagement/SampleProfile/SampleProfileView.vue"

import ReportDataPublicationViewCustomize from "@/views/TemplateSubmission/ReportDataPublication/ReportDataPublicationViewCustomize.vue"

export const routesTemplateSubmission = {
  path: "/template-submission/:id",
  component: TemplateSubmission,
  meta: {
    requiresAuth: true,
    requiresPrivacyNoticyAcceptance: true,
    requiresRoleSupportTeam: true
  },
  children: [
    {
      path: "",
      name: "template-submission",
      redirect: { name: "template-view" }
    },
    {
      path: "template-view",
      component: TemplateView,
      children: [
        {
          path: "",
          name: "template-view",
          redirect: { name: "template-view-pdf" }
        },
        {
          path: "pdf",
          name: "template-view-pdf",
          component: TemplateViewPdf,
          meta: {
            requiresRoleFocalPoint: true
          }
        },
        {
          path: "email",
          name: "template-view-email",
          component: TemplateViewEmail
        },
        {
          path: "pdf-iframe",
          name: "template-view-pdf-iframe",
          component: TemplateViewPdfIframe
        }
      ]
    },
    {
      path: "survey-plan-follow-up",
      name: "survey-plan-follow-up",
      component: SurveyPlan,
      meta: {
        requiresRoleFocalPoint: true
      }
    },
    {
      path: "user-access-management",
      name: "user-access-management",
      component: UserAccessManagement
    },
    {
      path: "survey-data-management",
      name: "survey-data-management",
      redirect: { name: "survey-data-management-beneficiary-datasheet" },
      component: SurveyDataManagement,
      children: [
        {
          path: "beneficiary-datasheet",
          name: "survey-data-management-beneficiary-datasheet",
          component: BeneficiaryDataSheetView,
          meta: {
            requiresRoleFocalPoint: true
          }
        },
        {
          path: "partner-datasheet",
          name: "survey-data-management-partner-datasheet",
          component: PartnerDataSheetView,
          meta: {
            requiresRoleFocalPoint: true
          }
        },
        {
          path: "sample-profile",
          name: "survey-data-management-sample-profile",
          component: SampleProfileView,
          meta: {
            requiresRoleFocalPoint: true
          }
        }
      ]
    },
    {
      path: "report-data-publication",
      component: ReportDataPublicationView,
      children: [
        {
          path: "",
          name: "report-data-publication",
          redirect: { name: "report-data-publication-pdf" }
        },
        {
          path: "pdf",
          name: "report-data-publication-pdf",
          component: ReportDataPublicationViewPdf,
          meta: {
            requiresRoleFocalPoint: true
          }
        },
        {
          path: "customize",
          name: "report-data-publication-customize",
          component: ReportDataPublicationViewCustomize
        }
      ]
    }
  ]
}
