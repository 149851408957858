







import { Component, Vue } from "vue-property-decorator"
import DataAnalysisTitle from "@/components/dashboard/dataAnalysis/DataAnalysisTitle.vue"
import DataAnalysisContent from "@/components/dashboard/dataAnalysis/DataAnalysisContent.vue"

@Component({
  components: { DataAnalysisContent, DataAnalysisTitle }
})
export default class DataAnalysisView extends Vue {}
