import { Vue } from "vue-property-decorator"
import { ExchangeRateFilter } from "@/model/interfaces/exchange/exchangeRateFilter"
import ExchangeRateFilterService from "@/services/exchange/ExchangeRateFilterService"
import { ExchangeRate } from "@/model/interfaces/exchange/exchangeRate"

export default class ExchangeRateService {
  private static URI = "exchange_rates"

  static async get(filter: ExchangeRateFilter): Promise<ExchangeRate[]> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...ExchangeRateFilterService.normalizeFilters(filter)
      }
    })

    return response.data["hydra:member"]
  }
}
