import MercureService from "@/services/mercure/MercureService"
import NotificationModule from "@/store/modules/NotificationModule"
import MercureMessageType from "@/model/enum/mercureMessageType"

export default class NotificationMercureService extends MercureService {
  static URI = "notifications/"
  static URI_ON_CREATE: string = NotificationMercureService.URI + "create"

  static onMessage(eventSource: EventSource, type?: MercureMessageType): EventSource {
    eventSource.onmessage = async ev => {
      const notification = JSON.parse(ev.data)
      type = await this.getTypeFromMessageData(notification, type)
      switch (type) {
        case MercureMessageType.Create:
          await NotificationModule.add(notification)
          break
      }
    }

    return eventSource
  }
}
