













import { Component, Prop, Vue } from "vue-property-decorator"
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { ChartOptions } from "chart.js"
import { TreemapController, TreemapElement } from "chartjs-chart-treemap"
Chart.register(ChartDataLabels)
Chart.register(TreemapController, TreemapElement)

@Component
export default class GlobalIndicatorsChart extends Vue {
  @Prop() canvasId!: string
  @Prop() configuration!: ChartOptions
  @Prop({ default: false })
  isTreeMap!: boolean
  get hasDataset(): boolean {
    let hasDataset = false
    if ((this.configuration as any).type !== "treemap" ) {
      if (
        (this.configuration as any).data.datasets.length > 0
        && (this.configuration as any).data.datasets.every((x: any) => x.data.length > 0)
        && (this.configuration as any).data.datasets.every((x: any) => {
          const sum = x.data.reduce((total: number, value: number) => total + value, 0)
          return sum !== 0
        })
      ) {
        hasDataset = true
      }
    } else {
      if ((this.configuration as any).data.datasets.every((x: any) => x.tree.length > 0)) hasDataset =  true
    }
    return hasDataset
  }
  mounted(): void {
    if (this.hasDataset) {
      const ctx = document.getElementById(this.canvasId) as HTMLCanvasElement
      if (ctx) {
        new Chart(ctx, this.configuration)
      }
    }
  }
}
