










import { Component, Vue } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import CanEditor from "@/views/TemplateSubmission/ReportDataPublication/CanEditor.vue"

@Component({
  components: {
    CanEditor,
    TextHeader
  }
})
export default class ReportDataPublicationViewCustomize extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }
}
