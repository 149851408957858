




















import { Component, Prop, Vue } from "vue-property-decorator"
import { TranslateResult } from "vue-i18n"

@Component
export default class EmailTextAreaCopy extends Vue {
  @Prop({ default: "" })
  mail!: string

  copying = false
  public copyEmailToClipboard(): void {
    navigator.clipboard.writeText(this.mail).then(() => {
      this.copying = true
      setTimeout(() => (this.copying = false), 1000)
    })
  }

  public copyToClip(str: string) {
    function listener(e: any) {
      e.clipboardData.setData("text/html", str)
      e.clipboardData.setData("text/plain", str)
      e.preventDefault()
    }
    document.addEventListener("copy", listener)
    document.execCommand("copy")
    document.removeEventListener("copy", listener)
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.templateview.email." + tKey)
  }
}
