







import { Component, Prop, Vue } from "vue-property-decorator"

import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button
  }
})
export default class ButtonClose extends Vue {
  @Prop({ default: "" })
  to!: string

  inheritAttrs = false
}
