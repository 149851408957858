
import { Component, Vue } from "vue-property-decorator"
import { PAGINATION_DEFAULT_VALUES } from "@/model/constants"
import { Pagination } from "@/model/interfaces/pagination"
import VuexModulePagination from "@/store/VuexModulePagination"

@Component
export default class VuePagination extends Vue {
  get itemsPerPageOptions(): number[] {
    return PAGINATION_DEFAULT_VALUES
  }

  hasPaginationChanged(pagination: Pagination, module: VuexModulePagination): boolean {
    pagination.current = pagination.page || 1

    return this.hasPageChanged(pagination, module) || this.hasItemChanged(pagination, module) || this.hasSortChanged(pagination, module)
  }

  hasItemChanged(pagination: Pagination, module: VuexModulePagination): boolean {
    return pagination.itemsPerPage !== module.pagination.itemsPerPage
  }

  hasPageChanged(pagination: Pagination, module: VuexModulePagination): boolean {
    return pagination.current !== module.pagination.page
  }

  hasSortChanged(pagination: Pagination, module: VuexModulePagination): boolean {
    return pagination.sortBy !== module.pagination.sortBy || pagination.sortDesc !== module.pagination.sortDesc
  }
}
