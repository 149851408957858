



















import { Component, Emit, Prop, Vue } from "vue-property-decorator"

@Component
export default class ConfirmDialog extends Vue {
  dialog = false

  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: String, default: "" }) question!: string
  @Prop({ type: String, default: "" }) title!: string
  @Prop({ type: String, default: "" }) icon!: string

  @Emit()
  reject(): void {
    this.dialog = false
  }

  @Emit()
  accept(): void {
    this.dialog = false
  }
}
