











































import { Component } from "vue-property-decorator"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import TemplateModule from "@/store/modules/form/TemplateModule"
import VueTrans from "@/mixins/VueTrans.vue"
import DataAnalysisModule from "@/store/modules/dataAnalysis/dataAnalysisModule"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import SurveySubmissionParserService from "@/services/submission/surveySubmission/SurveySubmissionParserService"
import { DataTableHeader } from "vuetify"
import { Pagination } from "@/model/interfaces/pagination"
import { PAGINATION_DEFAULT_VALUES } from "@/model/constants"

@Component({
  components: { ButtonGroup }
})
export default class DataBridge extends VueTrans {
  transKey = "dashboard.dataAnalysis.dataBridge."

  async mounted(): Promise<void> {
    await TemplateModule.getTemplates()
    SurveySubmissionModule.setFormType(DataAnalysisModule.dataBridgeMenuKey)
    SurveySubmissionModule.setForDataBridge()
    if (TemplateModule.template) {
      await SurveySubmissionModule.getByTemplate(TemplateModule.template)
    }
  }

  beforeDestroy(): void {
    SurveySubmissionModule.clear()
    TemplateModule.clearTemplate()
  }

  get loading(): boolean {
    return SurveySubmissionModule.loading
  }

  get pagination(): Pagination {
    return SurveySubmissionModule.pagination
  }

  set pagination(pagination: Pagination) {
    pagination.current = pagination.page || 1
    if (TemplateModule.template && (pagination.current !== SurveySubmissionModule.pagination.page || pagination.itemsPerPage !== SurveySubmissionModule.pagination.itemsPerPage)) {
      SurveySubmissionModule.mergePagination(pagination)
      SurveySubmissionModule.getByTemplate(TemplateModule.template)
    }
  }

  get itemsPerPageOptions(): number[] {
    return PAGINATION_DEFAULT_VALUES
  }

  get itemsPerPage(): number {
    return SurveySubmissionModule.pagination.itemsPerPage
  }

  get itemCount(): number {
    return SurveySubmissionModule.pagination.itemCount
  }

  get currentKey(): SurveyFormType {
    return DataAnalysisModule.dataBridgeMenuKey
  }

  set currentKey(key: SurveyFormType) {
    SurveySubmissionModule.setFormType(key)
    DataAnalysisModule.setDataBridgeMenuKey(key)
    SurveySubmissionModule.setForDataBridge()
    if (TemplateModule.template) {
      SurveySubmissionModule.setCurrentPage(1)
      SurveySubmissionModule.getByTemplate(TemplateModule.template)
    }
  }

  get keyBeneficiary(): string {
    return DataAnalysisModule.KEY_BENEFICIARY
  }

  get keyPartner(): string {
    return DataAnalysisModule.KEY_PARTNER
  }

  get headers(): DataTableHeader[] | undefined {
    if (TemplateModule.template && this.surveySubmissions.length > 0) {
      if (DataAnalysisModule.dataBridgeMenuKey === DataAnalysisModule.KEY_BENEFICIARY) {
        return SurveySubmissionParserService.headersForVDataTables(SurveySubmissionParserService.getBeneficiaryHeaders(TemplateModule.template, true))
      }

      if (DataAnalysisModule.dataBridgeMenuKey === DataAnalysisModule.KEY_PARTNER) {
        return SurveySubmissionParserService.headersForVDataTables(SurveySubmissionParserService.getPartnerHeaders(TemplateModule.template, true))
      }
    }

    return []
  }

  get surveySubmissions(): Record<string, unknown>[] {
    if (DataAnalysisModule.dataBridgeMenuKey === DataAnalysisModule.KEY_BENEFICIARY) {
      return SurveySubmissionParserService.getFieldsValues(SurveySubmissionModule.beneficiarySubmissions)
    }

    if (DataAnalysisModule.dataBridgeMenuKey === DataAnalysisModule.KEY_PARTNER) {
      return SurveySubmissionParserService.getFieldsValues(SurveySubmissionModule.partnerSubmissions)
    }

    return []
  }
}
