











import { Component, Vue } from "vue-property-decorator"
import SnackBars from "@/components/elements/snackBar/SnackBars.vue"

@Component({
  components: { SnackBars }
})
export default class App extends Vue {}
