
















import { Component, Vue } from "vue-property-decorator"
import LayoutBackToOverview from "@/components/layouts/LayoutBackToOverview.vue"
import LayoutNavbarHorizontal from "@/components/layouts/LayoutNavbarHorizontal.vue"
import LayoutNavbarHorizontalItem from "@/components/layouts/LayoutNavbarHorizontalItem.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    LayoutBackToOverview,
    LayoutNavbarHorizontal,
    LayoutNavbarHorizontalItem
  }
})
export default class AdminPanelView extends Vue {
  get isAdmin(): boolean {
    return SecurityModule.isAdmin
  }
}
