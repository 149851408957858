






































import { Component, Prop, PropSync, Watch } from "vue-property-decorator"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import BeneficiaryDataSheetView from "@/views/TemplateSubmission/SurveyDataManagement/BeneficiaryDataSheet/BeneficiaryDataSheetView.vue"
import HarmonizationSettings from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/HarmonizationSettings.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import ExportList from "@/components/templateSubmission/surveyDataManagement/ExportList.vue"
import DataCleaningModule from "@/store/modules/DataCleaningModule"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import SurveySubmissionHistoryModule from "@/store/modules/submission/SurveySubmissionHistoryModule"
import DateInput from "@/components/inputs/DateInput.vue"
import Combobox from "@/components/inputs/Combobox.vue"
import Button from "@/components/inputs/Button.vue"
import HarmonizationSettingsFaoCountryModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsFaoCountryModule"
import SecurityModule from "@/store/modules/SecurityModule"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import NotificationType, { NotificationTypeToSelectItems } from "@/model/enum/notification/notificationType"
import NotificationModule from "@/store/modules/NotificationModule"

@Component({
  components: { ComboboxMulti, Button, Combobox, DateInput, ExportList, ComboboxPagination, SearchField, TextHeader }
})
export default class BeneficiaryDataSheetToolBar extends VueTrans {
  @PropSync("selected", { type: String, default: "original-data" }) selectedSync!: string
  @Prop() subSelected!: string
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet."
  dateBefore: null | string = null
  dateAfter: null | string = null
  typeItems = NotificationTypeToSelectItems()

  get loading(): boolean {
    return TemplateSubmissionModule.loading
  }

  get search(): string | undefined {
    if (this.isOriginalData()) {
      return DataCleaningModule.search
    }
    if (this.isHarmonizationSettingHam()) {
      return HarmonizationSettingsHamModule.filters.search ?? ""
    }

    if (this.isHarmonizationSettingUnit()) {
      return HarmonizationSettingsUnitModule.filters.search ?? ""
    }

    if (this.isActivityLog()) {
      return SurveySubmissionHistoryModule.filters.search ?? ""
    }

    return undefined
  }

  set search(value: string | undefined) {
    if (this.isOriginalData()) {
      DataCleaningModule.setSearch(value ?? "")
      return
    }
    if (this.isHarmonizationSettingHam()) {
      HarmonizationSettingsHamModule.setSearch(value ?? null)
      HarmonizationSettingsHamModule.setCurrentPage(1)
      HarmonizationSettingsHamModule.getByTemplateSubmission()
      return
    }
    if (this.isHarmonizationSettingUnit()) {
      HarmonizationSettingsUnitModule.setSearch(value ?? null)
      HarmonizationSettingsUnitModule.setCurrentPage(1)
      HarmonizationSettingsUnitModule.getByTemplateSubmission()
      return
    }

    if (this.isActivityLog()) {
      SurveySubmissionHistoryModule.setSearch(value ?? null)
      SurveySubmissionHistoryModule.setCurrentPage(1)
      SurveySubmissionHistoryModule.getByCurrentTemplateSubmission()
    }
  }

  get hasExport(): boolean {
    return this.isOriginalData()
  }

  get hasSearch(): boolean {
    return !this.isHarmonizationSettingOutlier() && !this.isNotification()
  }

  get hasCountryButton(): boolean {
    return this.isHarmonizationSettingHam()
  }

  get hasHarmonizationButton(): boolean {
    return this.isHarmonizationSettings() && !SecurityModule.isFocalPoint
  }

  get hasFaoCountry(): boolean {
    return HarmonizationSettingsFaoCountryModule.current.faoCountry !== undefined
  }

  get hasDate(): boolean {
    return this.isActivityLog()
  }

  get keyOriginalData(): string {
    return BeneficiaryDataSheetView.KEY_ORIGINAL_DATA
  }

  get keyHarmonizationSettings(): string {
    return BeneficiaryDataSheetView.KEY_HARMONIZATION_SETTINGS
  }

  get keyActivityLog(): string {
    return BeneficiaryDataSheetView.KEY_ACTIVITY_LOG
  }

  get keyNotification(): string {
    return BeneficiaryDataSheetView.KEY_NOTIFICATION
  }

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }

  private isHarmonizationSettingOutlier(): boolean {
    return this.isHarmonizationSettings() && this.subSelected === HarmonizationSettings.KEY_OUTLIER
  }

  private isHarmonizationSettingUnit(): boolean {
    return this.isHarmonizationSettings() && this.subSelected === HarmonizationSettings.KEY_UNIT
  }

  private isHarmonizationSettingHam(): boolean {
    return this.isHarmonizationSettings() && (this.subSelected === HarmonizationSettings.KEY_HAM_ANIMAL || this.subSelected === HarmonizationSettings.KEY_HAM_CROP)
  }

  private isOriginalData(): boolean {
    return this.selectedSync === this.keyOriginalData
  }

  private isActivityLog(): boolean {
    return this.selectedSync === this.keyActivityLog
  }

  protected isNotification(): boolean {
    return this.selectedSync === this.keyNotification
  }

  public isHarmonizationSettings(): boolean {
    return this.selectedSync === this.keyHarmonizationSettings
  }

  public async displayFaoCountryForm(): Promise<void> {
    HarmonizationSettingsFaoCountryModule.setDialogForm(true)
  }

  public async requestHarmonization(): Promise<void> {
    await TemplateSubmissionModule.harmonizeCurrentSubmission(SurveyFormType.Beneficiary)
  }

  set selectedType(type: NotificationType[]) {
    NotificationModule.setType(type)
    NotificationModule.get()
  }

  get selectedType(): NotificationType[] {
    return NotificationModule.filters.type ?? []
  }

  @Watch("dateBefore")
  async onDateBeforeChange(): Promise<void> {
    SurveySubmissionHistoryModule.setDateBefore(this.dateBefore)
    await SurveySubmissionHistoryModule.getByCurrentTemplateSubmission()
  }

  @Watch("dateAfter")
  async onDateAfterChange(): Promise<void> {
    SurveySubmissionHistoryModule.setDateAfter(this.dateAfter)
    await SurveySubmissionHistoryModule.getByCurrentTemplateSubmission()
  }
}
