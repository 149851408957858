import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import HarmonizationSettingsFaoCountryService from "@/services/harmonizationSettings/harmonizationSettingsFaoCountry/HarmonizationSettingsFaoCountryService"
import { HarmonizationSettingSubmissionFaoCountry } from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountry"
import HarmonizationSettingSubmissionFaoCountryFilter from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountryFilter"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import IriUtils from "@/services/utils/IriUtils"

@Module({ dynamic: true, store, name: "HarmonizationSettingsFaoCountryModule", namespaced: true })
class HarmonizationSettingsFaoCountryModule extends VuexModuleSearch {
  faoCountries: HarmonizationSettingSubmissionFaoCountry[] = []
  current: HarmonizationSettingSubmissionFaoCountry = { global: false }
  filters: HarmonizationSettingSubmissionFaoCountryFilter = {
    search: undefined
  }
  dialogForm = false

  @Mutation
  setFaoCountries(faoCountries: HarmonizationSettingSubmissionFaoCountry[]) {
    this.faoCountries = faoCountries
  }

  @Mutation
  setCurrent(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry) {
    this.current = harmonizationSettingSubmissionFaoCountry
  }

  @Mutation
  setDialogForm(dialogForm: boolean) {
    this.dialogForm = dialogForm
  }

  @Mutation
  setGlobal(global: boolean) {
    this.filters.global = global
  }

  @Action
  async getByTemplateSubmission() {
    getModule(HarmonizationSettingsFaoCountryModule).setLoading(true)
    const harmonizationSettingSubmissionFaoCountry = await HarmonizationSettingsFaoCountryService.getByTemplateSubmission(
      TemplateSubmissionModule.currentSubmission as TemplateSubmission
    ).finally(() => {
      getModule(HarmonizationSettingsFaoCountryModule).setLoading(false)
    })

    this.setCurrent(harmonizationSettingSubmissionFaoCountry)
  }

  @Action
  async getGlobal() {
    this.setGlobal(true)
    getModule(HarmonizationSettingsFaoCountryModule).setLoading(true)
    const response = await HarmonizationSettingsFaoCountryService.getGlobal(this.filters, this.pagination).finally(() => {
      getModule(HarmonizationSettingsFaoCountryModule).setLoading(false)
    })

    this.mergePagination(response.pagination)
    this.setFaoCountries(response.faoCountries)
  }

  @Action
  async delete(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry) {
    try {
      getModule(HarmonizationSettingsFaoCountryModule).setLoading(true)
      await HarmonizationSettingsFaoCountryService.delete(harmonizationSettingSubmissionFaoCountry)
      const faoCountryIndex = getModule(HarmonizationSettingsFaoCountryModule).faoCountries.findIndex(item => item.id === harmonizationSettingSubmissionFaoCountry.id)
      if (faoCountryIndex !== -1) {
        getModule(HarmonizationSettingsFaoCountryModule).faoCountries.splice(faoCountryIndex, 1)
      }
    } finally {
      getModule(HarmonizationSettingsFaoCountryModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async save(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry) {
    getModule(HarmonizationSettingsFaoCountryModule).setLoading(true)
    try {
      harmonizationSettingSubmissionFaoCountry.faoCountry = IriUtils.getIri(harmonizationSettingSubmissionFaoCountry.faoCountry)

      const { id, global, faoCountry } = harmonizationSettingSubmissionFaoCountry
      if (harmonizationSettingSubmissionFaoCountry["@id"] === undefined) {
        getModule(HarmonizationSettingsFaoCountryModule).setCurrent(await HarmonizationSettingsFaoCountryService.post({ global, faoCountry }))
      } else {
        getModule(HarmonizationSettingsFaoCountryModule).setCurrent(await HarmonizationSettingsFaoCountryService.patch({ id, global, faoCountry }))
      }
    } finally {
      if (TemplateSubmissionModule.currentSubmission) {
        await getModule(HarmonizationSettingsFaoCountryModule).reloadByTemplateSubmission()
      } else {
        await this.getGlobal()
      }

      getModule(HarmonizationSettingsFaoCountryModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async reloadByTemplateSubmission() {
    await SurveySubmissionModule.reloadByCurrentSubmission()
    await HarmonizationSettingsHamModule.getByTemplateSubmission()
  }
}

export default getModule(HarmonizationSettingsFaoCountryModule)
