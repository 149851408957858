













import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TranslateResult } from "vue-i18n"
import { Component, Vue } from "vue-property-decorator"

@Component({})
export default class SurveyPlanEmailTo extends Vue {
  get email(): string {
    return TemplateSubmissionModule.currentSubmission?.focalPointEmail ?? ""
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.surveyplan." + tKey)
  }
}
