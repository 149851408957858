
















import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"

@Component({})
export default class LayoutToolbar extends Vue {
  @Prop()
  title!: string

  @Prop()
  subtitle!: string
}
