














import { Component, Watch } from "vue-property-decorator"
import SnackBarModule from "@/store/modules/SnackBarModule"
import { SnackBar as SnackBarInterface } from "@/model/interfaces/snackBar"
import SecurityModule from "@/store/modules/SecurityModule"
import VueTrans from "@/mixins/VueTrans.vue"

@Component
export default class SnackBar extends VueTrans {
  transKey = "snackBar."
  timeout = 3000
  processing = false
  timeoutId: number | undefined = undefined

  get snackBars(): SnackBarInterface[] {
    return SnackBarModule.snackBars
  }

  get isAuthenticated(): boolean {
    return SecurityModule.isAuthenticated
  }

  getSnackBarColor(snackBar: SnackBarInterface): string {
    return snackBar.color ?? "alert"
  }

  @Watch("snackBars")
  onSnackBarsChange(): void {
    this.processSnackBars()
  }

  async processSnackBars(): Promise<void> {
    this.processing = true
    if (this.hasSnackBars()) {
      const [snackBar] = this.snackBars
      this.timeoutId = setTimeout(async () => {
        if (snackBar.id) {
          await this.removeSnackBar(snackBar.id)
        }
      }, snackBar.timeout ?? this.timeout)
    }
    this.processing = false
  }

  async removeSnackBar(id: string): Promise<void> {
    clearTimeout(this.timeoutId)
    await SnackBarModule.removeSnackBarById(id)
    if (this.hasSnackBars()) {
      await this.processSnackBars()
    }
  }

  private hasSnackBars() {
    return this.snackBars.length > 0
  }
  private hasManySnackBars() {
    return this.snackBars.length > 1
  }
}
