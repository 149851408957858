import SurveySubmissionHistoryFilter from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistoryFilter"

export default class SurveySubmissionHistoryFilterService {
  static normalizeSearchFilter(search: string, filter: SurveySubmissionHistoryFilter): void {
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter["objectId.id"] = search
    }

    filter["username.username"] = search
  }

  static normalizeFilters(filter: SurveySubmissionHistoryFilter): SurveySubmissionHistoryFilter {
    const normalizedFilter: SurveySubmissionHistoryFilter = {}

    if (filter["objectId.survey.formType"]) {
      normalizedFilter["objectId.survey.formType"] = filter["objectId.survey.formType"]
    }

    if (filter.loggedAt && Object.keys(filter.loggedAt).length > 0) {
      normalizedFilter.loggedAt = filter.loggedAt
    }

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    return normalizedFilter
  }
}
