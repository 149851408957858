import FaoItemFilter from "@/model/interfaces/fao/faoItemFilter"

export default class FaoValueFilterService {
  static normalizeFilters(filter: FaoItemFilter): FaoItemFilter {
    const normalizedFilter: FaoItemFilter = {}

    if (filter.country) {
      normalizedFilter["faoValues.country.name"] = filter.country
    }

    return normalizedFilter
  }
}
