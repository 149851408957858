











import { Component, PropSync, Vue } from "vue-property-decorator"
import { TranslateResult } from "vue-i18n"
import { ValidationProvider } from "vee-validate"
@Component({
  components: {
    ValidationProvider
  }
})
export default class SurveyPlanFormEmail extends Vue {
  @PropSync("templateSubmissionId", { default: null }) templateId!: number

  public t(tKey: string): TranslateResult {
    return this.$t("survey_plan_form.card.template." + tKey)
  }
}
