import { DictionaryItemType } from "@/model/interfaces/submission/templateSubmission/DictionaryItemType"
import { Dictionary } from "@/model/interfaces/submission/templateSubmission/Dictionary"
import { Vue } from "vue-property-decorator"

export default class CanDictionaryService {
  public static URI = "can_dictionary_items"

  static async getAll(): Promise<Dictionary> {
    const response = await Vue.$axios.get(this.URI)
    return response.data["hydra:member"]
  }

  static async delete(entry: DictionaryItemType): Promise<DictionaryItemType> {
    return await Vue.$axios.delete(this.URI + "/" + entry.id)
  }

  static async patch(entry: DictionaryItemType): Promise<DictionaryItemType> {
    return await Vue.$axios.patch(this.URI + "/" + entry.id, entry)
  }

  static async post(entry: DictionaryItemType): Promise<DictionaryItemType> {
    return await Vue.$axios.post(this.URI, {
      key: entry.key,
      value: entry.value
    })
  }
}
