import TemplateSummaryFilter from "@/model/interfaces/submission/templateSubmission/templateSummaryFilter"
import TemplateSubmissionFilterService from "./TemplateSubmissionFilterService"

export default class TemplateSummaryFilterService extends TemplateSubmissionFilterService {
  static normalizeFilters(filter: TemplateSummaryFilter): TemplateSummaryFilter {
    const normalizedFilter: TemplateSummaryFilter = {
      ...TemplateSubmissionFilterService.normalizeFilters(filter),
      "template.uid": filter["template.uid"]
    }
    return normalizedFilter
  }
}
