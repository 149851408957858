




import { Component } from "vue-property-decorator"
import Vue from "vue"
import LayoutPublic from "@/components/layouts/LayoutPublic.vue"

@Component({
  components: {
    LayoutPublic
  }
})
export default class Page404View extends Vue {}
