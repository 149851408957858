import { Mutation } from "vuex-module-decorators"
import { Pagination } from "@/model/interfaces/pagination"
import VuexModuleLoading from "@/store/VuexModuleLoading"

export default abstract class VuexModulePagination extends VuexModuleLoading {
  pagination: Pagination = {
    current: 1,
    last: 1,
    itemsPerPage: 15,
    itemCount: 0
  }

  @Mutation
  setCurrentPage(page: number | null): void {
    this.pagination.current = page ?? 1
  }

  @Mutation
  setItemsPerPage(page: number | null): void {
    this.pagination.itemsPerPage = page ?? 1
    this.pagination.current = 1
  }

  @Mutation
  mergePagination(pagination: Pagination): void {
    Object.assign(this.pagination, pagination)
  }

  @Mutation
  resetPagination(): void {
    this.pagination = {
      current: 1,
      last: 1,
      itemsPerPage: 15,
      itemCount: 0
    }
  }
}
