






































import { Component, PropSync } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SurveyPlanFormDate from "@/components/surveyPlanForm/Form/SurveyPlanFormDate.vue"
import SurveyPlanFormTemplate from "@/components/surveyPlanForm/Form/SurveyPlanFormTemplate.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import DateUtils from "@/services/utils/DateUtils"

@Component({
  components: {
    SurveyPlanFormTemplate,
    SurveyPlanFormDate,
    ValidationObserver
  }
})
export default class SurveyPlanFormForm extends VueTrans {
  @PropSync("success") isSuccess!: boolean
  email = null
  transKey = "survey_plan_form.card."

  get minDate(): string {
    const date = this.getInitDateByTemplateSubmission()
    date.setMonth(0, 1)

    return date.toISOString().split("T")[0]
  }

  private getInitDateByTemplateSubmission(): Date {
    const date = new Date()
    if (this.templateSubmission?.year) {
      date.setFullYear(this.templateSubmission.year)
    }

    return date
  }

  get maxDateForBaseLine(): string {
    if (
      this.templateSubmission &&
      this.templateSubmission.endLine &&
      this.templateSubmission.year &&
      DateUtils.getDate(this.templateSubmission.endLine).getFullYear() <= this.templateSubmission.year
    ) {
      return this.templateSubmission.endLine
    }

    return this.maxDate
  }

  get maxDate(): string {
    const date = this.getInitDateByTemplateSubmission()
    date.setMonth(11, 31)
    return date.toISOString().split("T")[0]
  }

  get maxDateEndLine(): string {
    const date = this.getInitDateByTemplateSubmission()
    date.setMonth(10, 30)
    date.setFullYear(date.getFullYear() + 1)
    return date.toISOString().split("T")[0]
  }

  get minDateForEndLine(): string {
    if (this.templateSubmission && this.templateSubmission.baseLine) {
      return this.templateSubmission.baseLine
    }

    return this.minDate
  }

  get loading(): boolean {
    return TemplateSubmissionModule.loading
  }

  get canEdit(): boolean {
    return TemplateSubmissionModule.canEdit
  }

  get templateSubmission(): TemplateSubmission | null {
    return TemplateSubmissionModule.followUpSubmission
  }

  public async submit(): Promise<void> {
    await TemplateSubmissionModule.updateFollowUpSubmission()
    this.isSuccess = true
  }
}
