import MercureMessageType from "@/model/enum/mercureMessageType"

export default abstract class MercureService {
  static url: string = process.env.VUE_APP_MERCURE_IRI_URL
  static URI_ON_CREATE = "create"
  static URI_SUBSCRIPTION = MercureService.url + "/subscriptions/"

  constructor() {
    if (this.constructor == MercureService) {
      throw new Error("Abstract classes can't be instantiated.")
    }
  }

  static createEventSourceOnCreate(): EventSource {
    const topic = process.env.VUE_APP_API_URL + this.URI_ON_CREATE
    return this.newEventSource(topic)
  }

  protected static getUrl(topic: string, lastEventId: string | null = null): URL {
    const url = new URL(this.url)
    if (lastEventId !== null) {
      url.searchParams.append("lastEventID", lastEventId)
    }
    url.searchParams.append("topic", topic)
    return url
  }

  protected static newEventSource(topic: string, lastEventId: string | null = null): EventSource {
    return new EventSource(`${this.getUrl(topic, lastEventId)}`, { withCredentials: true })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static onMessage(eventSource: EventSource, type?: MercureMessageType): EventSource {
    throw new Error("Method 'onMessage()' must be implemented.")
  }

  static onCreate(): EventSource {
    return this.onMessage(this.createEventSourceOnCreate(), MercureMessageType.Create)
  }

  static async closeEventSources(eventSources: EventSource[]): Promise<void> {
    for (const eventSource of eventSources) {
      await this.closeEventSource(eventSource)
    }
  }

  static async closeEventSource(eventSource: EventSource): Promise<void> {
    eventSource.close()
  }

  protected static async getTypeFromMessageData(object: Record<string, unknown>, type?: MercureMessageType): Promise<MercureMessageType> {
    if (type) {
      return type
    }

    if (Object.prototype.hasOwnProperty.call(object, "id")) {
      return MercureMessageType.Update
    }

    return MercureMessageType.Delete
  }
}
