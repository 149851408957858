








































































import { Component } from "vue-property-decorator"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
import Combobox from "@/components/inputs/Combobox.vue"
import ExchangeRateModule from "@/store/modules/exchange/ExchangeRateModule"
import { YEARS_FROM } from "@/model/years"
import SearchField from "@/components/inputs/SearchField.vue"
import FaoValueModule from "@/store/modules/fao/FaoValueModule"
import Button from "@/components/inputs/Button.vue"
import FaoValueService from "@/services/fao/FaoValueService"
import VueI18n from "vue-i18n"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import VueTrans from "@/mixins/VueTrans.vue"
import { Template } from "@/model/interfaces/form/template/template"
import TemplateModule from "@/store/modules/form/TemplateModule"
import DataAnalysisModule from "@/store/modules/dataAnalysis/dataAnalysisModule"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import SurveySubmissionService from "@/services/submission/surveySubmission/SurveySubmissionService"
import HarmonizationSettingsCurrencyModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsCurrencyModule"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import HarmonizationSettingSubmissionHamSubType from "@/model/enum/harmonizationSetting/ham/harmonizationSettingSubmissionHamSubType"

@Component({
  components: { Button, SearchField, Combobox, ButtonGroup, LayoutToolbar }
})
export default class DataAnalysisToolbar extends VueTrans {
  transKey = "dashboard.dataAnalysis."
  fileRule = [
    (value: File): boolean | string | VueI18n.LocaleMessages => !value || value.size < 100000000 || this.$t("dashboard.dataAnalysis.globalHarmonisationSettings.file.error.max")
  ]
  file: File | null = null
  fileLoading = false
  fileError: string | null = null
  fileSuccess: string | null = null

  get keyDataBridge(): string {
    return DataAnalysisModule.KEY_DATA_BRIDGE
  }

  get keyGlobalHarmonizationSetting(): string {
    return DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING
  }

  get keyCanDictionary(): string {
    return DataAnalysisModule.KEY_CAN_DICTIONARY
  }

  get currentKey(): string {
    return DataAnalysisModule.menuKey
  }

  set currentKey(key: string) {
    DataAnalysisModule.setMenuKey(key)
  }

  get globalHarmonizationSettingsMenuKey(): string {
    return DataAnalysisModule.globalHarmonizationSettingsMenuKey
  }

  get years(): number[] {
    return YEARS_FROM(2021)
  }

  // Years
  set selectedYears(years: number | undefined) {
    ExchangeRateModule.setYear(years)
    ExchangeRateModule.get()
  }

  get selectedYears(): number | undefined {
    return ExchangeRateModule.filters?.year
  }

  get templates(): Template[] {
    return TemplateModule.templates
  }

  get template(): Template | null {
    return TemplateModule.template
  }

  set template(template: Template | null) {
    TemplateModule.setTemplate(template)
    if (template) {
      SurveySubmissionModule.setForDataBridge()
      SurveySubmissionModule.getByTemplate(template)
    }
  }

  // Search
  get search(): string | null {
    return FaoValueModule.filters.search ?? ""
  }

  set search(search: string | null) {
    if (this.isFao) {
      FaoValueModule.setSearch(search)
      FaoValueModule.setCurrentPage(1)
      FaoValueModule.get()
    }
    if (this.isDataBridge && TemplateModule.template) {
      SurveySubmissionModule.setSearch(search)
      SurveySubmissionModule.setCurrentPage(1)
      SurveySubmissionModule.getByTemplate(TemplateModule.template)
    }
    if (this.isCurrency) {
      HarmonizationSettingsCurrencyModule.setSearch(search)
      HarmonizationSettingsCurrencyModule.setCurrentPage(1)
      HarmonizationSettingsCurrencyModule.getGlobal()
    }
  }

  hasYearFilter(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === DataAnalysisModule.KEY_EXCHANGE
  }

  hasSearchFilter(): boolean {
    return this.isDataBridge || this.isFao || this.isCurrency
  }

  hasUpdatedField(): boolean {
    return this.isFao
  }

  hasAddButton(): boolean {
    return this.isUnit || this.isHam
  }

  hasTemplateFilter(): boolean {
    return this.isDataBridge
  }

  hasExport(): boolean {
    return this.isDataBridge
  }

  get isDataBridge(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_DATA_BRIDGE
  }

  get isFao(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === DataAnalysisModule.KEY_FAO
  }

  get isUnit(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === DataAnalysisModule.KEY_UNIT
  }

  get isHam(): boolean {
    return DataAnalysisModule.isHam
  }

  get isHamAnimal(): boolean {
    return DataAnalysisModule.isHamAnimal
  }

  get isHamCrop(): boolean {
    return DataAnalysisModule.isHamCrop
  }

  get isExchange(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === DataAnalysisModule.KEY_EXCHANGE
  }

  get isCurrency(): boolean {
    return this.currentKey === DataAnalysisModule.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === DataAnalysisModule.KEY_CURRENCY
  }

  create(): void {
    if (this.hasAddButton()) {
      if (this.isUnit) {
        HarmonizationSettingsUnitModule.setCurrent({ global: true })
        HarmonizationSettingsUnitModule.setDialogForm(true)
        return
      }
      if (this.isHam) {
        HarmonizationSettingsHamModule.setCurrent({
          global: true,
          subType: this.isHamAnimal ? HarmonizationSettingSubmissionHamSubType.ANIMAL : HarmonizationSettingSubmissionHamSubType.CROP
        })
        HarmonizationSettingsHamModule.setDialogForm(true)
        return
      }
    }
  }

  async uploadFile(): Promise<void> {
    this.fileSuccess = null
    this.fileError = null
    this.fileLoading = true
    let form = new FormData()
    form.append("file", this.file as Blob)
    const response = await FaoValueService.postFile(form)
    if ("message" in response) {
      this.fileSuccess = response.message
    }
    if ("error" in response) {
      this.fileError = response.error
    }
    this.fileLoading = false
  }

  exportXlsx(): void {
    const template = TemplateModule.template
    if (template) {
      SurveySubmissionService.exportByTemplate(template, SurveySubmissionModule.filters)
    }
  }
}
