

















































import { Component, Vue } from "vue-property-decorator"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import LayoutOverviewColumn from "@/components/layouts/LayoutOverviewColumn.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

@Component({
  components: {
    LayoutOverviewColumn
  }
})
export default class OverviewTableResume extends Vue {
  readonly HEADER = [
    this.$root.$i18n.t("dashboard.overview.resume.status"),
    this.$root.$i18n.t("dashboard.overview.resume.year"),
    this.$root.$i18n.t("dashboard.overview.resume.templateId"),
    this.$root.$i18n.t("dashboard.overview.resume.country"),
    this.$root.$i18n.t("dashboard.overview.resume.dutyStation"),
    this.$root.$i18n.t("dashboard.overview.resume.focalPoint"),
    this.$root.$i18n.t("dashboard.overview.resume.latestUpdate")
  ]

  get submissions(): Array<TemplateSubmission> {
    return TemplateSubmissionModule.submissions
  }
}
