






import { Component } from "vue-property-decorator"
import ExchangeRateModule from "@/store/modules/exchange/ExchangeRateModule"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { PAGINATION_DEFAULT_VALUES } from "@/model/constants"

@Component
export default class ExchangeRateTable extends VueTrans {
  transKey = "dashboard.dataAnalysis.globalHarmonisationSettings.table."

  get itemsPerPageOptions(): number[] {
    return [...PAGINATION_DEFAULT_VALUES, -1]
  }

  async mounted(): Promise<void> {
    await ExchangeRateModule.get()
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("headers.country"), value: "country", sortable: true },
      { text: this.t("exchangeRate.headers.currency"), value: "currency", sortable: true },
      { text: this.t("exchangeRate.headers.rate"), value: "rate", sortable: true },
      { text: this.t("headers.year"), value: "year", sortable: true }
    ] as DataTableHeader[]
  }

  get exchangeCountriesItems(): unknown[] {
    const items = []
    const exchangeRates = ExchangeRateModule.exchangeRates

    for (const exchangeRate of exchangeRates) {
      items.push({
        country: exchangeRate?.country?.name,
        currency: exchangeRate?.country?.currency,
        rate: exchangeRate?.rate,
        year: exchangeRate?.calendarYear
      })
    }

    return items
  }

  get loading(): boolean {
    return ExchangeRateModule.loading
  }
}
