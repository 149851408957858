
























import { Component, PropSync } from "vue-property-decorator"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import ExportList from "@/components/templateSubmission/surveyDataManagement/ExportList.vue"
import PartnerDataSheetView from "@/views/TemplateSubmission/SurveyDataManagement/PartnerDataSheet/PartnerDataSheetView.vue"
import Button from "@/components/inputs/Button.vue"
import SecurityModule from "@/store/modules/SecurityModule"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"

@Component({
  components: { Button, ExportList, ComboboxPagination, SearchField, TextHeader }
})
export default class PartnerDataSheetToolBar extends VueTrans {
  @PropSync("selected", { type: String, default: "original-data" }) selectedSync!: string
  @PropSync("search", { type: String, default: "" }) searchSync!: string
  transKey = "templatesubmission.surveydatamanagement."

  get hasSearch(): boolean {
    return this.selectedSync === this.keyOriginalData
  }
  get hasExport(): boolean {
    return this.selectedSync === this.keyOriginalData
  }

  get keyOriginalData(): string {
    return PartnerDataSheetView.KEY_ORIGINAL_DATA
  }

  private isHarmonizationSettings(): boolean {
    return this.selectedSync === this.keyHarmonizationSettings
  }

  get keyHarmonizationSettings(): string {
    return PartnerDataSheetView.KEY_HARMONIZATION_SETTINGS
  }

  get hasHarmonizationButton(): boolean {
    return this.isHarmonizationSettings() && !SecurityModule.isFocalPoint
  }

  public async requestHarmonization(): Promise<void> {
    await TemplateSubmissionModule.harmonizeCurrentSubmission(SurveyFormType.Partner)
  }
}
