import { Pagination } from "@/model/interfaces/pagination"
import { AxiosResponse } from "axios"

export default class PaginationUtils {
  static parseCurrentLast(response: AxiosResponse): { current: number; last: number } {
    let current
    let last = 1
    try {
      last = Math.max(+response.data["hydra:view"]["hydra:last"].split("page=")[1], 1)
      current = Math.min(Math.max(+response.data["hydra:view"]["@id"].split("page=")[1], 1), last)
    } catch {
      last = 1
      current = 1
    }

    return { current: current, last: last }
  }

  static getPaginationResponse(response: AxiosResponse, pagination: Pagination): Pagination {
    const { current, last } = PaginationUtils.parseCurrentLast(response)
    return {
      current: current,
      itemsPerPage: pagination.itemsPerPage,
      last: last,
      itemCount: response.data["hydra:totalItems"]
    }
  }

  static normalizePagination(pagination: Pagination): Record<string, unknown> {
    const queries: Record<string, unknown> = {
      page: pagination.current,
      itemsPerPage: pagination.itemsPerPage
    }
    if (pagination.sortBy !== undefined && pagination.sortDesc !== undefined) {
      for (const sortByKey in pagination.sortBy) {
        queries["order[" + pagination.sortBy[sortByKey] + "]"] = pagination.sortDesc[sortByKey] ? "desc" : "asc"
      }
    }
    // api platform field values
    return queries
  }
}
