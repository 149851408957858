















import { Component, Vue } from "vue-property-decorator"
import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"
import TemplateSummaryModule from "@/store/modules/summary/TemplateSummaryModule"
import TemplateSummaryTable from "./TemplateSummaryTable.vue"
import { Template } from "@/model/interfaces/form/template/template"
import TemplateSummary from "@/model/interfaces/submission/templateSubmission/templateSummary"

@Component({
  components: {
    LayoutLoadable,
    TemplateSummaryTable
  }
})
export default class TemplateSummaryContent extends Vue {
  get activeTemplate(): Template {
    return TemplateSummaryModule.activeTemplate
  }

  get summary(): TemplateSummary[] {
    return TemplateSummaryModule.summary
  }

  get loading(): boolean {
    return TemplateSummaryModule.loading
  }
}
