






















import { Component, Watch } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TextHeader from "@/components/elements/text/TextHeader.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import BeneficiaryDataSheetToolBar from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/BeneficiaryDataSheetToolBar.vue"
import OriginalData from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/originalData/OriginalData.vue"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import HarmonizationSettings from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/HarmonizationSettings.vue"
import Button from "@/components/inputs/Button.vue"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import BeneficiaryDatasheetMercureService from "@/services/mercure/BeneficiaryDataSheetMercureService"
import LayoutActiveUsers from "@/components/layouts/LayoutActiveUsers.vue"
import OriginalDataHeader from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/originalData/OriginalDataHeader.vue"
import ActivityLog from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/activityLog/ActivityLog.vue"
import BeneficiarySubmissionModule from "@/store/modules/submission/BeneficiaySubmissionModule"
import FaoCountryModule from "@/store/modules/fao/FaoCountryModule"
import SecurityModule from "@/store/modules/SecurityModule"
import FaoItemModule from "@/store/modules/fao/FaoItemModule"
import HarmonizationSettingSubmissionHamSubType from "@/model/enum/harmonizationSetting/ham/harmonizationSettingSubmissionHamSubType"
import Notification from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/notification/Notification.vue"

@Component({
  components: {
    Notification,
    ActivityLog,
    OriginalDataHeader,
    Button,
    HarmonizationSettings,
    OriginalData,
    BeneficiaryDataSheetToolBar,
    SearchField,
    TextHeader,
    LayoutActiveUsers
  }
})
export default class BeneficiaryDataSheetView extends VueTrans {
  static readonly KEY_ORIGINAL_DATA = "original-data"
  static readonly KEY_HARMONIZATION_SETTINGS = "harmonization-settings"
  static readonly KEY_ACTIVITY_LOG = "activity-log"
  static readonly KEY_NOTIFICATION = "notification"
  transKey = "templatesubmission.surveydatamanagement."
  selected = BeneficiaryDataSheetView.KEY_ORIGINAL_DATA
  search = ""
  subMenu = HarmonizationSettings.KEY_HAM_ANIMAL

  async mounted(): Promise<void> {
    await this.updateSurveySubmissions()
    if (TemplateSubmissionModule.currentSubmission) {
      await this.createEventSource()
    }
    await FaoCountryModule.getAll()
  }

  async destroyed(): Promise<void> {
    await BeneficiarySubmissionModule.closeBeneficiaryDataSheetEventSources()
    FaoItemModule.setFaoItems([])
  }

  get hasAddButton(): boolean {
    // return this.selected === this.keyHarmonizationSettings && (this.subMenu === HarmonizationSettings.KEY_HAM || this.subMenu === HarmonizationSettings.KEY_UNIT)
    return false
  }

  get keyOriginalData(): string {
    return BeneficiaryDataSheetView.KEY_ORIGINAL_DATA
  }

  get keyHarmonizationSettings(): string {
    return BeneficiaryDataSheetView.KEY_HARMONIZATION_SETTINGS
  }

  get keyActivityLog(): string {
    return BeneficiaryDataSheetView.KEY_ACTIVITY_LOG
  }

  get keyNotification(): string {
    return BeneficiaryDataSheetView.KEY_NOTIFICATION
  }

  get templateSubmission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  get usernames(): string[] {
    return BeneficiarySubmissionModule.beneficiaryDataSheetActiveUsernames
  }

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }

  create(): void {
    if (this.subMenu === HarmonizationSettings.KEY_HAM_ANIMAL) {
      HarmonizationSettingsHamModule.setCurrent({ global: false })
      HarmonizationSettingsHamModule.setDialogForm(true)
    }
    if (this.subMenu === HarmonizationSettings.KEY_UNIT) {
      HarmonizationSettingsUnitModule.setCurrent({ global: false })
      HarmonizationSettingsUnitModule.setDialogForm(true)
    }
  }

  @Watch("templateSubmission")
  async onChange(): Promise<void> {
    await this.updateSurveySubmissions()
    await this.createEventSource()
  }

  @Watch("subMenu", { immediate: true })
  async onSubMenuChange(): Promise<void> {
    if (this.subMenu === HarmonizationSettings.KEY_HAM_ANIMAL || this.subMenu === HarmonizationSettings.KEY_HAM_CROP) {
      HarmonizationSettingsHamModule.setSubTypeFilter(
        this.subMenu === HarmonizationSettings.KEY_HAM_ANIMAL ? HarmonizationSettingSubmissionHamSubType.ANIMAL : HarmonizationSettingSubmissionHamSubType.CROP
      )
    }
  }

  private async createEventSource() {
    if (this.templateSubmission !== null) {
      await BeneficiaryDatasheetMercureService.listenSubscriptions(this.templateSubmission)
      await BeneficiaryDatasheetMercureService.createEventSource(this.templateSubmission)
    }
  }

  private async updateSurveySubmissions() {
    if (TemplateSubmissionModule.currentSubmission) {
      SurveySubmissionModule.setFormType(SurveyFormType.Beneficiary)
      await SurveySubmissionModule.getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
    }
  }
}
