




import { Component, Vue } from "vue-property-decorator"
import TextField from "./TextField.vue"

@Component({
  components: {
    TextField
  }
})
export default class TextFieldLogin extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    clearable: true,
    color: "navy",
    height: "60",
    outlined: false
  }
}
