
































import { Component, Vue } from "vue-property-decorator"

import SurveyPlanHeader from "./SurveyPlanHeader.vue"
import SurveyPlanEmailCC from "./SurveyPlanEmailCC.vue"
import SurveyPlanEmailTo from "./SurveyPlanEmailTo.vue"
import { TranslateResult } from "vue-i18n"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
@Component({
  components: {
    SurveyPlanHeader,
    SurveyPlanEmailCC,
    SurveyPlanEmailTo
  }
})
export default class SurveyPlan extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  public t(tKey: string): TranslateResult {
    return this.$t("templatesubmission.surveyplan." + tKey)
  }

  get isUpdateEmailsDisabled(): boolean {
    return JSON.stringify(TemplateSubmissionModule.currentEmailsCandidates) === JSON.stringify(TemplateSubmissionModule.currentSubmission?.surveyplanEmails)
  }

  public updateEmails(): void {
    if (this.submission) {
      TemplateSubmissionModule.updateCurrentSubmissionsEmails()
    }
  }
}
