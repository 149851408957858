






import { Component, Vue } from "vue-property-decorator"
import LayoutPublic from "@/components/layouts/LayoutPublic.vue"
import WorkInProgress from "@/components/elements/WorkInProgress.vue"
import AzureService from "@/services/security/AzureService"
import router from "@/router"
import SecurityModule from "@/store/modules/SecurityModule"
import Loading from "@/components/elements/Loading.vue"

@Component({
  components: {
    LayoutPublic,
    WorkInProgress,
    Loading
  }
})
export default class LoginUnhcr extends Vue {
  async created(): Promise<void> {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get("code")
    if (code !== null) {
      await AzureService.setToken(code)
      await SecurityModule.authUser()
      if (SecurityModule.isAuthenticated) {
        await router.push({ name: "home" })
      }
    }
  }
}
