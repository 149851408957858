


















import { Component, Vue } from "vue-property-decorator"
import LayoutSubmissionView from "@/components/layouts/LayoutSubmissionView.vue"
import LayoutNavbarHorizontal from "@/components/layouts/LayoutNavbarHorizontal.vue"
import LayoutNavbarHorizontalItem from "@/components/layouts/LayoutNavbarHorizontalItem.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    LayoutNavbarHorizontal,
    LayoutNavbarHorizontalItem,
    LayoutSubmissionView
  }
})
export default class ReportDataPublicationView extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }
}
