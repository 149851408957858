import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import Violation from "@/model/interfaces/api/violation"
import { AxiosError } from "axios"

@Module({ dynamic: true, store, name: "ApiViolationModule", namespaced: true })
class ApiViolationModule extends VuexModule {
  errorMessages: Violation[] = []

  @Mutation
  reset() {
    this.errorMessages = []
  }

  @Mutation
  set(errorMessages: Violation[]) {
    this.errorMessages = errorMessages
  }

  @Action
  add(error: AxiosError) {
    const data = error.response?.data
    if (data?.violations.length) {
      for (const violation of data.violations) {
        const errorMessage = this.errorMessages.find(errorMessage => errorMessage.propertyPath === violation.propertyPath)
        if (errorMessage) {
          errorMessage.message = violation.message
        } else {
          this.errorMessages.push(violation)
        }
      }
    }
  }
}

export default getModule(ApiViolationModule)
