import i18n from "@/plugins/i18n"

enum NotificationType {
  TEMPLATE_SUBMISSION_CREATE = "templateSubmissionCreate",
  TEMPLATE_SUBMISSION_UPDATE = "templateSubmissionUpdate",
  TEMPLATE_SUBMISSION_DELETE = "templateSubmissionDelete",
  SURVEY_PLAN_UPDATED = "surveyPlanUpdated",
  GRANTED = "granted",
  UNGRANTED = "ungranted",
  EMAIL_NOTIFICATION_ENABLED = "emailNotificationEnabled",
  EMAIL_NOTIFICATION_DISABLED = "emailNotificationDisabled",
  SAMPLE_PROFILE_GENERATED = "sampleProfileGenerated",
  FAO_IMPORT_SUCCESS = "faoImportSuccess",
  FAO_IMPORT_FAIL = "faoImportFail",
  CUSTOMISATION_YEAR_FINALIZED = "customisationYearFinalized",
  CUSTOMISATION_PROGRAM_FINALIZED = "customisationProgramFinalized",
  UNIT_HARMONIZATION_SUCCESS = "unitHarmonizationSuccess",
  UNIT_HARMONIZATION_FAIL = "unitHarmonizationFail",
  HAM_HARMONIZATION_SUCCESS = "hamHarmonizationSuccess",
  HAM_HARMONIZATION_FAIL = "hamHarmonizationFail",
  PARTNER_HARMONIZATION_SUCCESS = "partnerHarmonizationSuccess",
  PARTNER_HARMONIZATION_FAIL = "partnerHarmonizationFail",
  BENEFICIARY_HARMONIZATION_SUCCESS = "beneficiaryHarmonizationSuccess",
  BENEFICIARY_HARMONIZATION_FAIL = "beneficiaryHarmonizationFail",
  PARTNER_SURVEY_SUBMISSION_CREATED = "partnerSurveySubmissionCreated",
  BENEFICIARY_SURVEY_SUBMISSION_CREATED = "beneficiarySurveySubmissionCreated",
  DATA_CLEANING_UPDATED = "dataCleaningUpdated"
}

// eslint-disable-next-line @typescript-eslint/no-namespace

// eslint-disable-next-line no-inner-declarations
export function NotificationTypeToSelectItems(): { text: string; value: string }[] {
  const items = []
  let suite: keyof typeof NotificationType

  for (suite in NotificationType) {
    const value = NotificationType[suite]
    items.push({
      text: i18n.t("notification.type.select." + value) as string,
      value: value as string
    })
  }
  return items
}

export default NotificationType
