



















import { Component } from "vue-property-decorator"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { Pagination } from "@/model/interfaces/pagination"
import { HarmonizationSettingSubmissionHam } from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHam"
import CopyUtils from "@/services/utils/CopyUtils"
import { mixins } from "vue-class-component"
import VuePagination from "@/mixins/VuePagination.vue"

@Component
export default class HamTable extends mixins(VueTrans, VuePagination) {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.table.ham."

  async beforeDestroy(): Promise<void> {
    HarmonizationSettingsHamModule.setSearch(null)
    HarmonizationSettingsHamModule.resetPagination()
  }

  beforeMount(): void {
    HarmonizationSettingsHamModule.setGlobalFilter(false)
    HarmonizationSettingsHamModule.getByTemplateSubmission()
  }

  get loading(): boolean {
    return HarmonizationSettingsHamModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("headers.convertFrom"), value: "name", sortable: true },
      { text: this.t("headers.convertTo"), value: "convertValue", sortable: true },
      { text: this.t("headers.actions"), value: "actions", sortable: false }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionHam[] {
    return HarmonizationSettingsHamModule.hams
  }

  get pagination(): Pagination {
    return HarmonizationSettingsHamModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPaginationChanged(pagination, HarmonizationSettingsHamModule)) {
      HarmonizationSettingsHamModule.mergePagination(pagination)
      HarmonizationSettingsHamModule.getByTemplateSubmission()
    }
  }

  get itemCount(): number {
    return HarmonizationSettingsHamModule.pagination.itemCount
  }

  edit(ham: HarmonizationSettingSubmissionHam): void {
    HarmonizationSettingsHamModule.setCurrent((CopyUtils.create((ham as unknown) as Record<string, unknown>) as unknown) as HarmonizationSettingSubmissionHam)
    HarmonizationSettingsHamModule.setDialogForm(true)
  }

  async remove(ham: HarmonizationSettingSubmissionHam): Promise<void> {
    HarmonizationSettingsHamModule.setLoading(true)
    HarmonizationSettingsHamModule.delete(ham).finally(() => {
      HarmonizationSettingsHamModule.setLoading(false)
    })
  }
}
