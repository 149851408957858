import FaoValueFilter from "@/model/interfaces/fao/faoValueFilter"

export default class FaoValueFilterService {
  static normalizeSearchFilter(search: string, filter: FaoValueFilter): void {
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter.year = intValue
      filter.value = intValue
    }
    filter["country.name"] = search
    filter["item.name"] = search
  }

  static normalizeFilters(filter: FaoValueFilter): FaoValueFilter {
    const normalizedFilter: FaoValueFilter = {}

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    return normalizedFilter
  }
}
