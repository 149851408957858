














import { Component } from "vue-property-decorator"
import Vue from "vue"
import Button from "@/components/inputs/Button.vue"
import ButtonBack from "@/components/inputs/ButtonBack.vue"
import ButtonClose from "../inputs/ButtonClose.vue"

@Component({
  components: {
    Button,
    ButtonBack,
    ButtonClose
  }
})
export default class LayoutBackToOverview extends Vue {}
