var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"scroll-x-transition","persistent":"","hide-overlay":"","width":"auto"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-bold justify-center"},[_vm._v(" Dictionary entry ")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"ma-2 justify-center text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Key","rules":_vm.isKeyFieldEditable ? 'value_not_in_array:' + _vm.keys + '|required' : '',"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"disabled":_vm.referenceItem !== null,"label":"Key","error-messages":errors,"success":valid,"autofocus":_vm.isKeyFieldEditable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.editedItem.key),callback:function ($$v) {_vm.$set(_vm.editedItem, "key", $$v)},expression:"editedItem.key"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Value","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Value","error-messages":errors,"success":valid,"autofocus":!_vm.isKeyFieldEditable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"alert","dark":"","small":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }