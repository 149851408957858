









import { Component, Vue } from "vue-property-decorator"
import HeaderTopUser from "./HeaderTopUser.vue"
import HeaderTopLogo from "@/components/dashboard/Header/HeaderTopLogo.vue"
import NotificationMenu from "@/components/notification/NotificationMenu.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    HeaderTopLogo,
    HeaderTopUser,
    NotificationMenu
  }
})
export default class HeaderTop extends Vue {
  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }
}
