
import { Component, Vue } from "vue-property-decorator"
import VueI18n from "vue-i18n"

@Component
export default class VueTrans extends Vue {
  transKey = ""

  t(key: string, values?: VueI18n.Values): string {
    return this.$t(this.transKey + key, values).toString()
  }

  tc(key: string, choice?: VueI18n.Choice, values?: VueI18n.Values): string {
    return this.$tc(this.transKey + key, choice, values).toString()
  }
}
