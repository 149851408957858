


























































import { Component, Prop, Ref } from "vue-property-decorator"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import VueTrans from "@/mixins/VueTrans.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { HarmonizationSettingSubmissionUnit } from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnit"
import ApiViolationService from "@/services/api/ApiViolationService"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import TextField from "@/components/inputs/TextField.vue"

@Component({
  components: { TextField, SwitchCustom, ValidationObserver, ValidationProvider }
})
export default class UnitForm extends VueTrans {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  @Ref("convertTo") readonly convertTo!: HTMLInputElement
  @Prop({ type: Boolean, default: false }) isGlobal!: boolean
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.form.unit."

  get unit(): HarmonizationSettingSubmissionUnit {
    return HarmonizationSettingsUnitModule.current
  }

  get isNew(): boolean {
    return !this.unit["@id"]
  }

  get dialogForm(): boolean {
    return HarmonizationSettingsUnitModule.dialogForm
  }

  get loading(): boolean {
    return HarmonizationSettingsUnitModule.loading
  }

  get convertRateError(): string | undefined {
    return ApiViolationService.getByPropertyPath("convertRate")
  }

  async submit(): Promise<void> {
    try {
      await HarmonizationSettingsUnitModule.saveCurrent()
    } finally {
      this.close()
    }
  }

  close(): void {
    this.form.reset()
    HarmonizationSettingsUnitModule.setDialogForm(false)
  }
}
