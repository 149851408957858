import DashboardView from "@/views/DashboardView.vue"
import OverviewView from "@/views/dashboard/OverviewView.vue"
import SurveyManagementView from "@/views/dashboard/SurveyManagementView.vue"
import TemplateSummaryView from "@/views/dashboard/TemplateSummaryView.vue"
import WorkInProgress from "@/components/elements/WorkInProgress.vue"
import DataAnalysisView from "@/views/dashboard/DataAnalysisView.vue"
import GlobalIndicatorsView from "@/views/dashboard/GlobalIndicatorsView.vue"

export const routesDashboard = {
  path: "/dashboard",
  component: DashboardView,
  name: "dashboardLayout",
  meta: {
    requiresAuth: true,
    requiresPrivacyNoticyAcceptance: true,
    requiresRoleAdmin: true,
    requiresRoleSupportTeam: true
  },
  children: [
    {
      path: "",
      name: "dashboard",
      redirect: { name: "overview" }
    },
    {
      path: "overview",
      name: "overview",
      component: OverviewView,
      meta: {
        requiresRoleFocalPoint: true
      }
    },
    {
      path: "template-summary",
      name: "template-summary",
      component: TemplateSummaryView,
      meta: {
        requiresRoleFocalPoint: false
      }
    },
    {
      path: "survey-management",
      name: "survey-management",
      component: SurveyManagementView,
      meta: {
        requiresRoleFocalPoint: false
      }
    },
    {
      path: "data-analysis",
      name: "data-analysis",
      component: DataAnalysisView,
      props: { title: "Data Analysis" },
      meta: {
        requiresRoleFocalPoint: false
      }
    },
    {
      path: "global-indicators",
      name: "global-indicators",
      component: GlobalIndicatorsView,
      props: { title: "Global Indicators" },
      meta: {
        requiresRoleFocalPoint: true
      }
    }
  ]
}
