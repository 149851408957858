var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [(_vm.harmonizationSettingsFaoCountry)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-bold justify-center"},[_vm._v(" "+_vm._s(_vm.harmonizationSettingsFaoCountry.faoCountry ? _vm.t("edit") : _vm.t("add"))+" ")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"ma-2 justify-center text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('templateSubmissionCountry'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.t('templateSubmissionCountry'),"error-messages":errors,"success":valid,"disabled":""},model:{value:(_vm.harmonizationSettingsFaoCountry.name),callback:function ($$v) {_vm.$set(_vm.harmonizationSettingsFaoCountry, "name", $$v)},expression:"harmonizationSettingsFaoCountry.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('faoCountryName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('AutoComplete',{attrs:{"label":_vm.t('faoCountryName'),"error-messages":errors,"success":valid,"items":_vm.countryList,"item-text":"name","item-value":"@id","disabled":_vm.loading || (!_vm.isEditable && _vm.isGlobal),"singleLine":false,"flat":false,"hideDetails":false,"solo":false},model:{value:(_vm.harmonizationSettingsFaoCountry.faoCountry),callback:function ($$v) {_vm.$set(_vm.harmonizationSettingsFaoCountry, "faoCountry", $$v)},expression:"harmonizationSettingsFaoCountry.faoCountry"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('SwitchCustom',{attrs:{"label":_vm.$t('templatesubmission.surveydatamanagement.harmonizationSettings.common.global'),"disabled":_vm.isGlobal},model:{value:(_vm.harmonizationSettingsFaoCountry.global),callback:function ($$v) {_vm.$set(_vm.harmonizationSettingsFaoCountry, "global", $$v)},expression:"harmonizationSettingsFaoCountry.global"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"alert","dark":"","small":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":invalid || (!_vm.isEditable && _vm.isGlobal),"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }