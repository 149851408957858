
















import { Component, Vue } from "vue-property-decorator"
import { TranslateResult } from "vue-i18n"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SecurityModule from "@/store/modules/SecurityModule"
import SurveyPlanFormForm from "@/components/surveyPlanForm/Form/SurveyPlanFormForm.vue"
import SurveyPlanFormHeader from "@/components/surveyPlanForm/Header/SurveyPlanFormHeader.vue"

@Component({
  components: { SurveyPlanFormForm, SurveyPlanFormHeader }
})
export default class SurveyPlanFormView extends Vue {
  templateSubmissionId: number | null = null
  success = false
  async created(): Promise<void> {
    const templateSubmissionUuid = this.$route.params.uuid
    await SecurityModule.checkAuthenticated()
    await TemplateSubmissionModule.setFollowUpSubmissionByUuid(templateSubmissionUuid)
  }

  public t(tKey: string): TranslateResult {
    return this.$t("survey_plan_form." + tKey)
  }
}
