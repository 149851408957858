






import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class SwitchCustom extends Vue {
  inheritAttrs = false
  defaultsAttrs = {
    color: "primary"
  }

  @Prop({ default: false })
  value!: boolean
  valueLocal: boolean = this.value

  @Watch("value")
  valueChanged() {
    this.valueLocal = this.value
  }

  @Watch("valueLocal")
  valueLocalChanged() {
    this.$emit("input", this.valueLocal)
  }
}
