import { Action, getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import store from "@/store"

import VuexModuleLoading from "@/store/VuexModuleLoading"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import CanService from "@/services/submission/templateSubmission/CanService"
import { CanContent } from "@/model/interfaces/submission/templateSubmission/CanContent"

@Module({ dynamic: true, store, name: "CanModule", namespaced: true })
class CanModule extends VuexModuleLoading {
  canContent: CanContent | null = null
  canContentHistoryPile: CanContent["html"][] = []
  activeCanContentHistoryItemKey: number | null = null

  @Mutation
  async setCanContent(payload: { submission: TemplateSubmission; reset: boolean }) {
    this.canContentHistoryPile = []
    this.activeCanContentHistoryItemKey = null
    this.canContent = null
    const canContent = payload.reset ? await TemplateSubmissionService.resetCanContent(payload.submission) : await TemplateSubmissionService.getCanContent(payload.submission)

    getModule(CanModule).eraseNextCanContentHistoryItem()
    getModule(CanModule).pushCanContentHistoryPile(canContent.html)
    this.canContent = canContent
  }

  @Mutation
  pushCanContentHistoryPile(canContentHtml: CanContent["html"]) {
    this.canContentHistoryPile.push(canContentHtml)
    getModule(CanModule).setActiveCanContentHistoryItemKey()
  }

  @Mutation
  setActiveCanContentHistoryItemKey(newKey: number | null = null) {
    const key = newKey ?? (this.canContentHistoryPile.length > 0 ? this.canContentHistoryPile.length - 1 : null)
    if (key != null && this.canContentHistoryPile[key] !== undefined) {
      this.activeCanContentHistoryItemKey = key
    }
  }

  @Mutation
  eraseNextCanContentHistoryItem() {
    if (this.activeCanContentHistoryItemKey) {
      this.canContentHistoryPile.length = this.activeCanContentHistoryItemKey + 1
    }
  }

  @Action
  prevCanContentHistoryItem() {
    if (this.activeCanContentHistoryItemKey != null) {
      const prevKey = this.activeCanContentHistoryItemKey - 1
      this.context.commit("setActiveCanContentHistoryItemKey", prevKey)
    }
  }

  @Action
  nextCanContentHistoryItem() {
    if (this.activeCanContentHistoryItemKey != null) {
      const nextKey = this.activeCanContentHistoryItemKey + 1
      this.context.commit("setActiveCanContentHistoryItemKey", nextKey)
    }
  }

  @Action
  async resetCanContentHistoryItem(submission: TemplateSubmission) {
    await getModule(CanModule).setCanContent({ submission, reset: true })
  }

  @MutationAction({ mutate: ["canContent"] })
  async saveCanContent(canContent: CanContent) {
    const patchedCanContent: CanContent = await CanService.patch(canContent)
    return { canContent: patchedCanContent }
  }

  get activeCanContent(): CanContent["html"] | null {
    if (this.activeCanContentHistoryItemKey != null) {
      return this.canContentHistoryPile[this.activeCanContentHistoryItemKey]
    }
    return null
  }

  get hasPrevCanContentItem(): boolean {
    return this.activeCanContentHistoryItemKey != null ? this.activeCanContentHistoryItemKey > 0 : false
  }

  get hasNextCanContentItem(): boolean {
    return this.activeCanContentHistoryItemKey != null ? this.activeCanContentHistoryItemKey < this.canContentHistoryPile.length - 1 : false
  }
}

export default getModule(CanModule)
