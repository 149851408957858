

















import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

import { Component, Vue } from "vue-property-decorator"
import LayoutOverviewColumn from "@/components/layouts/LayoutOverviewColumn.vue"
import OverviewTableUserAccessManagementLine from "./OverviewTableUserAccessManagementLine.vue"

@Component({
  components: {
    LayoutOverviewColumn,
    OverviewTableUserAccessManagementLine
  }
})
export default class extends Vue {
  get submissions(): Array<TemplateSubmission> {
    return TemplateSubmissionModule.submissions
  }
}
