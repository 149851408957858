























import { Component } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { Pagination } from "@/model/interfaces/pagination"
import HarmonizationSettingsFaoCountryModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsFaoCountryModule"
import { HarmonizationSettingSubmissionFaoCountry } from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountry"
import { mixins } from "vue-class-component"
import VuePagination from "@/mixins/VuePagination.vue"
import ConfirmDeletionDialog from "@/components/elements/dialog/ConfirmDeletionDialog.vue"
import FaoCountryForm from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/harmonizationSettings/ham/FaoCountryForm.vue"
import { HarmonizationSettingSubmissionUnit } from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnit"
import CopyUtils from "@/services/utils/CopyUtils"

@Component({
  components: { FaoCountryForm, ConfirmDeletionDialog }
})
export default class FaoHarmonizationTable extends mixins(VueTrans, VuePagination) {
  transKey = "dashboard.dataAnalysis.globalHarmonisationSettings.table.faoHarmonization."

  destroyed(): void {
    HarmonizationSettingsFaoCountryModule.resetPagination()
    HarmonizationSettingsFaoCountryModule.setGlobal(false)
  }

  get loading(): boolean {
    return HarmonizationSettingsFaoCountryModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("headers.templateSubmissionCountry"), value: "name", sortable: false },
      { text: this.t("headers.faoCountryName"), value: "faoCountry.name", sortable: false },
      { text: this.t("headers.actions"), value: "actions", sortable: false }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionFaoCountry[] {
    return HarmonizationSettingsFaoCountryModule.faoCountries
  }

  get pagination(): Pagination {
    return HarmonizationSettingsFaoCountryModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPaginationChanged(pagination, HarmonizationSettingsFaoCountryModule)) {
      HarmonizationSettingsFaoCountryModule.mergePagination(pagination)
      HarmonizationSettingsFaoCountryModule.getGlobal()
    }
  }

  get itemsPerPage(): number {
    return HarmonizationSettingsFaoCountryModule.pagination.itemsPerPage
  }

  get itemCount(): number {
    return HarmonizationSettingsFaoCountryModule.pagination.itemCount
  }

  edit(faoCountry: HarmonizationSettingSubmissionFaoCountry): void {
    HarmonizationSettingsFaoCountryModule.setCurrent((CopyUtils.create((faoCountry as unknown) as Record<string, unknown>) as unknown) as HarmonizationSettingSubmissionUnit)
    HarmonizationSettingsFaoCountryModule.setDialogForm(true)
  }

  async remove(faoCountry: HarmonizationSettingSubmissionFaoCountry): Promise<void> {
    HarmonizationSettingsFaoCountryModule.setLoading(true)
    HarmonizationSettingsFaoCountryModule.delete(faoCountry).finally(() => {
      HarmonizationSettingsFaoCountryModule.setLoading(false)
    })
  }
}
