






import { Component, Vue } from "vue-property-decorator"

import Combobox from "@/components/inputs/Combobox.vue"
import { PAGINATION_DEFAULT_VALUES } from "@/model/constants"

@Component({
  components: {
    Combobox
  }
})
export default class ComboboxPagination extends Vue {
  inheritAttrs = false
  comboPagDefaults = {
    clearable: false,
    items: PAGINATION_DEFAULT_VALUES,
    outlined: false,
    prefix: this.$i18n.t("pagination.paginationRows")
  }
}
