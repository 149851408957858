import { Vue } from "vue-property-decorator"
import { SurveySubmission } from "@/model/interfaces/submission/surveySubmission/surveySubmission"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import SurveySubmissionFilter from "@/model/interfaces/submission/surveySubmission/surveySubmissionFilter"
import SurveySubmissionFilterService from "@/services/submission/surveySubmission/SurveySubmissionFilterService"
import { Template } from "@/model/interfaces/form/template/template"
import DocumentUtils from "@/services/utils/DocumentUtils"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { Pagination } from "@/model/interfaces/pagination"
import { XLSX_FORMAT } from "@/model/constants"
import axios from "axios"

export default class SurveySubmissionService {
  private static URI = "survey_submissions"

  static async getByTemplateSubmission(templateSubmission: TemplateSubmission, filters: SurveySubmissionFilter): Promise<{ submissions: SurveySubmission[]; totalItems: number }> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI, {
      params: {
        ...SurveySubmissionFilterService.normalizeFilters(filters)
      }
    })

    return {
      submissions: response.data["hydra:member"],
      totalItems: response.data["hydra:totalItems"]
    }
  }

  static async exportByTemplateSubmission(templateSubmission: TemplateSubmission, filters: SurveySubmissionFilter, isHarmonizedData = false, password = ""): Promise<void> {
    SurveySubmissionFilterService.setFieldsType(isHarmonizedData, filters)
    const url = new URL(process.env.VUE_APP_API_URL + TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI + XLSX_FORMAT)

    if (password !== "") {
      url.searchParams.append("password", password)
    }

    const response = await Vue.$axios.get(url.href, {
      responseType: "blob",
      params: {
        ...SurveySubmissionFilterService.normalizeFilters(filters)
      }
    })

    DocumentUtils.createXlsx(response.data)
  }

  static async getSampleProfileTable(templateSubmission: TemplateSubmission): Promise<string> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/sample_profile", {})
    return response.data
  }

  static async getByTemplate(template: Template, filters: SurveySubmissionFilter, pagination: Pagination): Promise<{ submissions: SurveySubmission[]; pagination: Pagination }> {
    filters["templateSubmission.template.uid"] = template.uid
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...SurveySubmissionFilterService.normalizeFilters(filters),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      submissions: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static async exportByTemplate(template: Template, filters: SurveySubmissionFilter): Promise<void> {
    SurveySubmissionFilterService.setFieldsType(true, filters)
    delete filters.search
    filters["templateSubmission.template.uid"] = template.uid
    const response = await Vue.$axios.get(this.URI + XLSX_FORMAT, {
      responseType: "blob",
      params: {
        ...SurveySubmissionFilterService.normalizeFilters(filters)
      }
    })

    DocumentUtils.createXlsx(response.data)
  }

  static async patchSubmissions(submissionsToUpdate: Partial<SurveySubmission>[], progress: { (updated: number, total: number): void }): Promise<void> {
    const BATCH_SIZE = 10
    for (let i = 0; i < submissionsToUpdate.length; i += BATCH_SIZE) {
      const batch = submissionsToUpdate.slice(i, i + BATCH_SIZE)
      const requests = batch.map(surveySubmission => {
        return Vue.$axios.patch(this.URI + "/" + surveySubmission.id, surveySubmission)
      })

      try {
        progress(i, submissionsToUpdate.length)
        await axios.all(requests)
      } catch (error) {
        console.error("Error updating batch:", error)
      }
    }
  }
}
