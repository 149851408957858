import { Vue } from "vue-property-decorator"
import { AxiosResponse } from "axios"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { HarmonizationSettingSubmissionFaoCountry } from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountry"
import HarmonizationSettingSubmissionFaoCountryFilter from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountryFilter"
import HarmonizationSettingsFaoCountryFilterService from "@/services/harmonizationSettings/harmonizationSettingsFaoCountry/HarmonizationSettingsFaoCountryFilterService"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { Pagination } from "@/model/interfaces/pagination"

export default class HarmonizationSettingsFaoCountryService {
  private static URI = "harmonization_setting_submission_fao_countries"
  private static URI_ONE = "harmonization_setting_submission_fao_country"

  static async getByTemplateSubmission(templateSubmission: TemplateSubmission): Promise<HarmonizationSettingSubmissionFaoCountry> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI_ONE)

    return response.data
  }

  static async getGlobal(
    filter: HarmonizationSettingSubmissionFaoCountryFilter,
    pagination: Pagination
  ): Promise<{ faoCountries: HarmonizationSettingSubmissionFaoCountry[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...HarmonizationSettingsFaoCountryFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  private static getResultWihPagination(response: AxiosResponse, pagination: Pagination) {
    return {
      faoCountries: response.data["hydra:member"],
      pagination: PaginationUtils.getPaginationResponse(response, pagination)
    }
  }

  static async delete(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry): Promise<AxiosResponse> {
    return await Vue.$axios.delete(this.URI + "/" + harmonizationSettingSubmissionFaoCountry.id)
  }

  static async post(harmonizationSettingSubmissionFaoCountry: HarmonizationSettingSubmissionFaoCountry): Promise<HarmonizationSettingSubmissionFaoCountry> {
    const response = await Vue.$axios.post(this.URI, harmonizationSettingSubmissionFaoCountry)
    return response.data
  }

  static async patch(harmonizationSettingSubmissionFaoCountry: Partial<HarmonizationSettingSubmissionFaoCountry>): Promise<HarmonizationSettingSubmissionFaoCountry> {
    const response = await Vue.$axios.patch(this.URI + "/" + harmonizationSettingSubmissionFaoCountry.id, harmonizationSettingSubmissionFaoCountry)
    return response.data
  }
}
