










































import { Component } from "vue-property-decorator"
import ExchangeRateTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/ExchangeRateTable.vue"
import FaoTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/FaoTable.vue"
import UnitTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/UnitTable.vue"
import HamTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/HamTable.vue"
import CurrencyTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/CurrencyTable.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import DataAnalysisModule from "@/store/modules/dataAnalysis/dataAnalysisModule"
import FaoHarmonizationTable from "@/components/dashboard/dataAnalysis/globalHarmonizationSettings/table/FaoHarmonizationTable.vue"
import FaoCountryModule from "@/store/modules/fao/FaoCountryModule"
import HarmonizationSettingsHamModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsHamModule"
import HarmonizationSettingSubmissionHamSubType from "@/model/enum/harmonizationSetting/ham/harmonizationSettingSubmissionHamSubType"

@Component({
  components: { FaoHarmonizationTable, CurrencyTable, UnitTable, HamTable, FaoTable, ExchangeRateTable }
})
export default class GlobalHarmonizationSettings extends VueTrans {
  transKey = "dashboard.dataAnalysis.globalHarmonisationSettings."

  async mounted(): Promise<void> {
    await FaoCountryModule.getAll()
  }

  get currentMenuKey(): string {
    return DataAnalysisModule.globalHarmonizationSettingsMenuKey
  }

  set currentMenuKey(key: string) {
    DataAnalysisModule.setGlobalHarmonizationSettingsMenuKey(key)
    if (DataAnalysisModule.isHam) {
      HarmonizationSettingsHamModule.setSubTypeFilter(key === this.keyHamAnimal ? HarmonizationSettingSubmissionHamSubType.ANIMAL : HarmonizationSettingSubmissionHamSubType.CROP)
      HarmonizationSettingsHamModule.getGlobal()
    }
  }

  getColor(key: string): string {
    return this.currentMenuKey === key ? "primary" : "primary lighten-2 primary--text"
  }

  get keyExchange(): string {
    return DataAnalysisModule.KEY_EXCHANGE
  }

  get keyFao(): string {
    return DataAnalysisModule.KEY_FAO
  }

  get keyUnit(): string {
    return DataAnalysisModule.KEY_UNIT
  }

  get keyCurrency(): string {
    return DataAnalysisModule.KEY_CURRENCY
  }

  get keyFaoHarmonization(): string {
    return DataAnalysisModule.KEY_FAO_HARMONIZATION
  }

  get keyHamAnimal(): string {
    return DataAnalysisModule.KEY_HAM_ANIMAL
  }

  get keyHamCrop(): string {
    return DataAnalysisModule.KEY_HAM_CROP
  }
}
