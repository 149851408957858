





































































































import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsCategoryBanner from "../reusableComponents/GlobalIndicatorsCategoryBanner.vue"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import { GlobalIndicatorsCategoryMainTrends } from "@/model/interfaces/globalIndicators/globalIndicatorsCategoryMainTrends"
import GlobalIndicatorsFigure from "../reusableComponents/GlobalIndicatorsFigure.vue"
import GlobalIndicatorsChart from "../reusableComponents/GlobalIndicatorsChart.vue"
import { GlobalIndicatorsChartDefinitions } from "@/services/globalIndicators/GlobalIndicatorsChartDefinitions"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import GlobalIndicatorsChartLegend from "../reusableComponents/GlobalIndicatorsChartLegend.vue"
import GlobalIndicatorsUniqueIndicatorBar from "../reusableComponents/UniqueIndicatorBar.vue"
@Component({
  components: {
    GlobalIndicatorsCategoryBanner,
    GlobalIndicatorsFigure,
    GlobalIndicatorsChart,
    GlobalIndicatorsChartLegend,
    GlobalIndicatorsUniqueIndicatorBar
  }
})
export default class GlobalIndicatorsBeneficariesProfile extends Vue {
  get titleStats(): GlobalIndicatorsCategoryMainTrends | null {
    return GlobalIndicatorsModule.benefeciariesSurveyedTitleStats
  }
  get benefeciariesSurveyedChartsConfig() {
    return GlobalIndicatorsModule.surveyedChartsConfig
  }

  get familiesAverageSize() {
    if (!GlobalIndicatorsModule.dataset) return 0
    try {
      return GlobalIndicatorsModule.dataset.familiesAverageSize.toFixed(1)
    } catch (error) {
      return 0
    }
  }

  getChartLegends(chartId: GlobalIndicatorsDatasetKey) {
    return GlobalIndicatorsChartDefinitions.getChartDefinitions()[chartId]?.legend
  }
}
