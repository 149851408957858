import { Vue } from "vue-property-decorator"
import { AxiosResponse } from "axios"
import JwtCookie from "./JwtCookie"

export default class AzureService {
  static async getAuthorizationUrl(): Promise<string> {
    // 1. Generate PKCE key pair and store it in local storage as page will reload
    const keyPair = await this.generatePKCEKeyPair()
    localStorage.setItem("code_verifier", keyPair.codeVerifier)

    // 2. Get Azure authorization url from with code challenge
    const url = new URL(process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_URI + "/authorize")
    url.searchParams.append("client_id", process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_CLIENT_ID)
    url.searchParams.append("response_type", "code")
    url.searchParams.append("redirect_uri", process.env.VUE_APP_BASE_URL + "login/unhcr")
    url.searchParams.append("response_mode", "query")
    url.searchParams.append("prompt", "select_account")
    url.searchParams.append("scope", "openid https://graph.microsoft.com/.default")
    // url.searchParams.append('scope', "https://graph.microsoft.com/User.Read")
    // url.searchParams.append('scope', "api://8c15b580-b3ec-4c88-97cf-e29f7cf09241/LIS.invitation")
    url.searchParams.append("code_challenge", keyPair.codeChallenge)
    url.searchParams.append("code_challenge_method", "S256")

    return url.href
  }

  static async getToken(code: string): Promise<AxiosResponse | any> {
    // 1. Get previously generated code verifier from local storage.
    const codeVerifier = localStorage.getItem("code_verifier")

    // 2. Get the access token, with the given code verifier
    if (codeVerifier !== null) {
      return await Vue.$axios
        .post(
          process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_URI + "/token",
          new URLSearchParams({
            client_id: process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_CLIENT_ID,
            scope: "openid https://graph.microsoft.com/.default",
            code,
            redirect_uri: process.env.VUE_APP_BASE_URL + "login/unhcr",
            grant_type: "authorization_code",
            code_verifier: codeVerifier
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(({ data }) => {
          this.setCookies(data)
          return data.access_token
        })
    }
  }

  static async refreshToken(): Promise<AxiosResponse | any> {
    const refreshToken = AzureService.getAzureRefreshTokenFromCookie()
    if (refreshToken !== undefined) {
      return await Vue.$axios
        .post(
          process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_URI + "/token",
          new URLSearchParams({
            client_id: process.env.VUE_APP_AZURE_MICROSOFT_GRAPH_CLIENT_ID,
            scope: "openid https://graph.microsoft.com/.default",
            refresh_token: refreshToken,
            grant_type: "refresh_token"
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(({ data }) => {
          this.setCookies(data)
          return data.access_token
        })
    } else {
      return null
    }
  }

  static setCookies(data: any) {
    document.cookie = `jwt_azure=${data.access_token}; expires=${JwtCookie.getJwtExpirationDate(data.access_token)}; path=/`
    document.cookie = `jwt_azure_refresh=${data.refresh_token}; path=/`
  }

  static async setToken(code: string | null = null) {
    let token
    if (code != null) {
      token = await AzureService.getToken(code)
    } else {
      token = AzureService.getAzureTokenFromCookie()

      // Refresh token if expired
      if (!JwtCookie.isExpired(token)) {
        token = await AzureService.refreshToken()
      }
    }

    AzureService.setAuthorizationHeader(token)
  }

  static setAuthorizationHeader(token: string) {
    // SecurityModule.logOut()
    Vue.$axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }

  static getAzureTokenFromCookie(): string | undefined {
    return JwtCookie.getCookieValue("jwt_azure")
  }

  static getAzureRefreshTokenFromCookie(): string | undefined {
    return JwtCookie.getCookieValue("jwt_azure_refresh")
  }

  static hasAzureTokenCookie(): boolean {
    return AzureService.getAzureTokenFromCookie() !== undefined
  }

  static hasAuthorizationHeader(): boolean {
    return Vue.$axios.defaults.headers.common["Authorization"] !== undefined
  }

  static removeAuthorizationHeader() {
    delete Vue.$axios.defaults.headers.common["Authorization"]
  }

  // ------------------------------------ //
  // Private Key for Code Exchange (PKCE) //
  // ------------------------------------ //

  static async generatePKCEKeyPair() {
    const codeVerifier = this.generateCodeVerifier()
    return {
      codeVerifier: codeVerifier,
      codeChallenge: await this.generateCodeChallengeFromVerifier(codeVerifier)
    }
  }

  static dec2hex(dec: number) {
    return ("0" + dec.toString(16)).substr(-2)
  }

  static generateCodeVerifier() {
    const array = new Uint32Array(56 / 2)
    window.crypto.getRandomValues(array)
    return Array.from(array, this.dec2hex).join("")
  }

  static sha256(plain: string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return window.crypto.subtle.digest("SHA-256", data)
  }

  static base64urlencode(a: any) {
    let str = ""
    const bytes = new Uint8Array(a)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i])
    }
    return btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "")
  }

  static async generateCodeChallengeFromVerifier(v: string) {
    const hashed = await this.sha256(v)
    const base64encoded = this.base64urlencode(hashed)
    return base64encoded
  }
}
