


























import { Component, Prop, PropSync, Vue } from "vue-property-decorator"
import { ValidationProvider } from "vee-validate"
import TextField from "./TextField.vue"

@Component({
  components: {
    TextField,
    ValidationProvider
  }
})
export default class DateInput extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    clearable: false,
    "prepend-inner-icon": "$mdiCalendar"
  }

  @Prop() label!: string
  @Prop({ default: null }) rules!: string | null
  @Prop({ default: false }) disable!: boolean
  @Prop({ default: null }) min!: string | null
  @Prop({ default: null }) max!: string | null
  @PropSync("date", { default: null }) syncDate!: string | Date | null
  modal = false

  $refs!: {
    dialog: Vue & { save: (value: unknown) => void }
  }

  get modelDate(): string | null {
    if (this.syncDate === null) {
      return null
    }
    if (typeof this.syncDate === "object") {
      return this.syncDate.toISOString().split("T")[0]
    }

    return this.syncDate.split("T")[0]
  }

  set modelDate(value: string | null) {
    this.dialogSave(value)
    this.syncDate = value
  }

  private dialogSave(value: string | null) {
    if (this.$refs.dialog) {
      this.$refs.dialog.save(value)
    }
  }
}
