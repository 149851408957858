









import { Component, Vue } from "vue-property-decorator"

import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button
  }
})
export default class ButtonBack extends Vue {
  inheritAttrs = false
}
