export default class DateUtils {
  static isToday(date: string | Date): boolean {
    date = this.getDate(date)
    const today = new Date()

    return this.isSameDay(date, today)
  }

  static isYesterday(date: string | Date): boolean {
    date = this.getDate(date)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    return this.isSameDay(date, yesterday)
  }

  static isSameDay(date1: Date, date2: Date): boolean {
    return !(date1.getDate() !== date2.getDate() || date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear())
  }

  static getDate(date: string | Date): Date {
    if (typeof date === "string") {
      return new Date(date)
    }

    return date
  }

  static groupByDate = <T, K extends keyof unknown>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    }, {} as Record<K, T[]>);
}
