












import { Component, Prop, Vue } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import DateUtils from "@/services/utils/DateUtils"
import { Notification as NotificationInterface } from "@/model/interfaces/notification/notification"
import NotificationType from "@/model/enum/notification/notificationType"
import NotificationModule from "@/store/modules/NotificationModule"

@Component({
  components: {
    Button
  }
})
export default class NotificationMenuItem extends Vue {
  @Prop()
  notification!: NotificationInterface

  get title(): string {
    return this.$t("notification.type." + this.notification.type).toString()
  }

  get subtitle(): string {
    if (this.notification.content) {
      switch (this.notification.type) {
        case NotificationType.TEMPLATE_SUBMISSION_CREATE:
        case NotificationType.TEMPLATE_SUBMISSION_UPDATE:
        case NotificationType.TEMPLATE_SUBMISSION_DELETE:
        case NotificationType.SURVEY_PLAN_UPDATED:
        case NotificationType.SAMPLE_PROFILE_GENERATED:
        case NotificationType.PARTNER_HARMONIZATION_SUCCESS:
        case NotificationType.PARTNER_HARMONIZATION_FAIL:
        case NotificationType.BENEFICIARY_HARMONIZATION_SUCCESS:
        case NotificationType.BENEFICIARY_HARMONIZATION_FAIL:
        case NotificationType.BENEFICIARY_SURVEY_SUBMISSION_CREATED:
        case NotificationType.PARTNER_SURVEY_SUBMISSION_CREATED:
        case NotificationType.DATA_CLEANING_UPDATED:
          return (
            this.$t("notification.type.subtitle." + this.notification.type, {
              template_id: this.notification.entityId,
              country: this.notification.country,
              duty_station: this.notification.dutyStation,
              year: this.notification.year
            }).toString() + " "
          )
        case NotificationType.UNGRANTED:
        case NotificationType.GRANTED:
          return (
            this.$t("notification.type.subtitle." + this.notification.type, {
              username: this.notification.content.uamUsername,
              status: this.notification.content.uamStatus,
              template_id: this.notification.entityId,
              country: this.notification.country,
              duty_station: this.notification.dutyStation,
              year: this.notification.year
            }).toString() + " "
          )
        case NotificationType.EMAIL_NOTIFICATION_DISABLED:
        case NotificationType.EMAIL_NOTIFICATION_ENABLED:
          return (
            this.$t("notification.type.subtitle." + this.notification.type, {
              username: this.notification.content.uamUsername,
              template_id: this.notification.entityId,
              country: this.notification.country,
              duty_station: this.notification.dutyStation,
              year: this.notification.year
            }).toString() + " "
          )
        case NotificationType.CUSTOMISATION_YEAR_FINALIZED:
        case NotificationType.CUSTOMISATION_PROGRAM_FINALIZED:
          return (
            (this.$t("notification.type.subtitle." + this.notification.type, {
              year: this.notification.year
            }) as string) + " "
          )
        default:
          return ""
      }
    }
    return ""
  }

  get time(): string {
    if (this.notification.createdAt) {
      return DateUtils.getDate(this.notification.createdAt).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })
    }

    return ""
  }

  toRoute(): void {
    if (this.notification.entityId !== undefined) {
      NotificationModule.setSearch(this.notification.entityId.toString())
    }
    this.$router.push({ name: "notification" })
  }
}
