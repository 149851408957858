






import { Component, Vue } from "vue-property-decorator"

@Component
export default class PaginationItem extends Vue {
  inheritAttrs = false
  defaultsAttrs = {
    color: "primary lighten-2"
  }
}
