import { render, staticRenderFns } from "./TemplateView.vue?vue&type=template&id=32fb8622&scoped=true&"
import script from "./TemplateView.vue?vue&type=script&lang=ts&"
export * from "./TemplateView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fb8622",
  null
  
)

export default component.exports