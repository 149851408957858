import Vue from "vue"
import axios, { AxiosError, AxiosRequestConfig, AxiosStatic } from "axios"
import router from "@/router"
import SnackBarModule from "@/store/modules/SnackBarModule"
import VueI18n from "@/plugins/i18n"
import ApiViolationModule from "@/store/modules/ApiViolationModule"

export default {
  install(): void {
    Vue.$axios = <AxiosStatic>axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 60000, // 0 is non-limit second time and (2000 in milliseconds => 2 sec)
      headers: {
        "Content-Type": "application/json"
      }
    })
    const errorInterceptor = (error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          if (router.currentRoute.name !== "login-platform") {
            router.push({ name: "login" })
          }
          break
        case 400:
          SnackBarModule.add({ code: 400, message: VueI18n.t("error.400").toString() })
          break
        case 404:
          SnackBarModule.add({ code: 404, message: VueI18n.t("error.404").toString() })
          break
        case 422: {
          ApiViolationModule.add(error)
          const violations = error.response?.data.violations
          if (violations !== undefined) {
            violations.forEach((violation: any) => {
              SnackBarModule.add({ code: 422, message: violation.message })
            })
          }
          break
        }
        case 423:
          SnackBarModule.add({ code: 423, message: VueI18n.t("error.423").toString() })
          break
        case 500:
        case 502:
        case 503:
        case 504:
          SnackBarModule.add({ code: 500, message: VueI18n.t("error.50x").toString() })
          break
      }

      return Promise.reject(error)
    }

    Vue.$axios.interceptors.request.use(
      config => {
        // 2023-10-06 : PROD and UAT 403 permission denied if IRI is set in put and patch requests
        // Add a request interceptor to remove '@id' and '@context' from patch and put requests
        const attributeNamesToRemove = ["@id", "@context"]
        config = removeAttributesFromParams(config, attributeNamesToRemove)

        // 2023-11-22 : Getting 401 Unauthorized from Mercure if both mercureAuthorization cookie and Authorization
        // header are set. It will take Authorization header by default which conflicts with Azure Authorization header
        return removeAuthorizationHeaderFromMercureRequests(config)
      },
      error => {
        return Promise.reject(error)
      }
    )

    // Function to remove a specific attribute from the request params
    function removeAttributesFromParams(config: AxiosRequestConfig, attributeNamesToRemove: string[]): AxiosRequestConfig {
      if (config.method === "put" || config.method === "patch") {
        if (config.data) {
          attributeNamesToRemove.forEach((attributeNameToRemove: string) => {
            if (config.data[attributeNameToRemove]) {
              delete config.data[attributeNameToRemove]
            }
          })
        }
      }
      return config
    }

    function removeAuthorizationHeaderFromMercureRequests(config: any): AxiosRequestConfig {
      if (config.url && config.url.includes(".well-known/mercure") && config.headers) {
        delete config.headers.common["Authorization"]
      }
      return config
    }

    Vue.$axios.interceptors.response.use(undefined, errorInterceptor)
  }
}
