import HarmonizationSettingSubmissionFaoCountryFilter from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountryFilter"

export default class HarmonizationSettingsFaoCountryFilterService {
  static normalizeSearchFilter(search: string, filter: HarmonizationSettingSubmissionFaoCountryFilter): void {
    filter.name = search
    filter["exchangeCountry.name"] = search
    filter["exchangeCountry.currency"] = search
    const intValue = parseInt(search)
    if (!isNaN(intValue)) {
      filter.year = intValue
    }
  }

  static normalizeFilters(filter: HarmonizationSettingSubmissionFaoCountryFilter): HarmonizationSettingSubmissionFaoCountryFilter {
    const normalizedFilter: HarmonizationSettingSubmissionFaoCountryFilter = {}

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    if (filter.global !== undefined) {
      normalizedFilter.global = filter.global
    }

    return normalizedFilter
  }
}
