import { Module, MutationAction, getModule, Mutation } from "vuex-module-decorators"
import store from "@/store"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"

import TemplateSummary from "@/model/interfaces/submission/templateSubmission/templateSummary"
import { Template } from "@/model/interfaces/form/template/template"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import TemplateService from "@/services/form/TemplateService"
import TemplateSummaryFilter from "@/model/interfaces/submission/templateSubmission/templateSummaryFilter"
import { Pagination } from "@/model/interfaces/pagination"

@Module({ dynamic: true, store, name: "TemplateSummaryModule", namespaced: true })
class TemplateSummaryModule extends VuexModuleSearch {
  activeTemplate: Template = {}
  summary: Array<TemplateSummary> = []
  templates: Array<Template> = []

  filters: TemplateSummaryFilter = {
    "template.uid": "",
    search: ""
  }

  @Mutation
  setActiveTemplate(template: Template) {
    this.activeTemplate = template
  }

  @Mutation
  setYears(years: number[]) {
    this.filters.year = years
  }

  @MutationAction({
    mutate: ["templates", "activeTemplate"]
  })
  async updateTemplates(): Promise<{
    templates: Template[]
    activeTemplate: Template
  }> {
    getModule(TemplateSummaryModule).setLoading(true)
    const templates = TemplateService.sortTemplatesByYear(
      await TemplateService.getSummary().finally(() => {
        getModule(TemplateSummaryModule).setLoading(false)
      })
    )
    return {
      templates: templates,
      activeTemplate: templates.length ? templates[0] : {}
    }
  }

  @MutationAction({
    mutate: ["summary", "pagination"],
    rawError: true
  })
  async updateTemplateSummary(): Promise<{
    summary: TemplateSummary[]
    pagination: Pagination
  }> {
    if (!this.activeTemplate) {
      return {
        summary: [],
        pagination: this.pagination
      }
    }

    this.filters["template.uid"] = this.activeTemplate.uid ?? ""

    getModule(TemplateSummaryModule).setLoading(true)
    const results = await TemplateSubmissionService.getSummary(this.filters, this.pagination).finally(() => {
      getModule(TemplateSummaryModule).setLoading(false)
    })
    return {
      summary: results.summary,
      pagination: results.pagination
    }
  }
}

export default getModule(TemplateSummaryModule)
