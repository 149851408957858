
































import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import { GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"

@Component
export default class GlobalIndicatorsKeyNumbers extends Vue {
  get dataset(): GlobalIndicatorsDataset | null {
    return GlobalIndicatorsModule.dataset
  }
}
