import { Vue } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import SurveySubmissionHistoryFilterService from "@/services/submission/surveySubmissionHistory/SurveySubmissionHistoryFilterService"
import SurveySubmissionHistoryFilter from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistoryFilter"
import { SurveySubmissionHistory } from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistory"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { Pagination } from "@/model/interfaces/pagination"
import QueryString from "qs"

export default class SurveySubmissionHistoryService {
  private static URI = "survey_submission_histories"

  static async getByTemplateSubmission(
    templateSubmission: TemplateSubmission,
    filters: SurveySubmissionHistoryFilter,
    pagination: Pagination
  ): Promise<{ submissions: SurveySubmissionHistory[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI, {
      params: {
        ...SurveySubmissionHistoryFilterService.normalizeFilters(filters),
        ...PaginationUtils.normalizePagination(pagination)
      },
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    })

    return {
      submissions: response.data["hydra:member"],
      pagination: PaginationUtils.getPaginationResponse(response, pagination)
    }
  }
}
