import { ExchangeRateFilter } from "@/model/interfaces/exchange/exchangeRateFilter"

export default class ExchangeRateFilterService {
  static normalizeFilters(filter: ExchangeRateFilter): ExchangeRateFilter {
    const normalizedFilter: ExchangeRateFilter = {}

    if (filter.year) {
      normalizedFilter["calendarYear"] = filter.year
    }

    return normalizedFilter
  }
}
