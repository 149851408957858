











import { Component, Vue } from "vue-property-decorator"

import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"

import TemplateSummaryContent from "@/components/dashboard/TemplateSummary/TemplateSummaryContent.vue"
import TemplateSummaryToolbar from "@/components/dashboard/TemplateSummary/TemplateSummaryToolbar.vue"

import TemplateSummaryModule from "@/store/modules/summary/TemplateSummaryModule"
import { Pagination } from "@/model/interfaces/pagination"

@Component({
  components: {
    LayoutPaginable,
    TemplateSummaryContent,
    TemplateSummaryToolbar
  }
})
export default class DashboardTemplateSummary extends Vue {
  get pagination(): Pagination {
    return TemplateSummaryModule.pagination
  }

  set pagination(pagination: Pagination) {
    TemplateSummaryModule.updateTemplateSummary() // This will update the whole pagination item
  }
}
