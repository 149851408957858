














































import { Component } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"
import LayoutSurveyManagement from "@/components/layouts/LayoutSurveyManagement.vue"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"
import SurveyManagementBeneficiary from "@/components/dashboard/SurveyManagement/SurveyManagementBeneficiary.vue"
import SurveyManagementTemplate from "@/components/dashboard/SurveyManagement/SurveyManagementTemplate.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import TemplateDescriptorType, { getDefaultTemplateDescriptorByType } from "@/model/enum/form/template/templateDescriptorType"

@Component({
  computed: {
    TemplateDescriptorType() {
      return TemplateDescriptorType
    }
  },
  components: {
    Button,
    ButtonGroup,
    LayoutPaginable,
    LayoutSurveyManagement,
    LayoutToolbar,
    SurveyManagementBeneficiary,
    SurveyManagementTemplate
  }
})
export default class SurveyManagementView extends VueTrans {
  readonly KEY_TEMPLATE = "template"
  readonly KEY_BENEFICIARIES = "beneficiaries"

  currentKey = this.KEY_TEMPLATE

  templateDescriptorFileNameByType(type: TemplateDescriptorType): string {
    return getDefaultTemplateDescriptorByType(type).name
  }

  templateDescriptorContentUrlByType(type: TemplateDescriptorType): string {
    return getDefaultTemplateDescriptorByType(type).uri
  }
}
