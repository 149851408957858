











import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class GlobalIndicatorsUniqueIndicatorBar extends Vue {
  @Prop() public text!: string
  @Prop() public indicatorNumber!: number
  @Prop() public showTrend!: boolean
}
