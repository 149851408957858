








import { Component, Vue } from "vue-property-decorator"
import SecurityModule from "@/store/modules/SecurityModule"

@Component
export default class IdleLogout extends Vue {
  timeReference = 0
  timeLeft = 0
  readonly timeLogoutWarningCountdown: number = 60000 * 2 // 2 minutes
  intervalID = 0

  // Events on which to check user activity
  readonly events: Array<string> = ["mousedown", "mousemove", "keypress", "scroll", "touchstart", "touchmove", "click", "keydown", "scroll"]

  mounted(): void {
    // Add event listeners when mounted
    this.events.forEach(event => {
      document.addEventListener(event, this.resetTimeReference, true)
    })

    // Init timeReference  and left.
    this.resetTimeReference()
    this.timeLeft = this.getTimeLeft()

    // Trigger interval
    this.intervalID = setInterval(() => {
      this.timeLeft = this.getTimeLeft()
      if (this.timeLeft < 0) {
        this.logout()
      }
    }, 1000)
  }

  get showWarning(): boolean {
    return this.timeLeft < this.timeLogoutWarningCountdown
  }

  get timeLeftInSeconds(): string {
    return Math.floor(this.timeLeft / 1000) + " s"
  }

  private getTimeLeft(): number {
    return SecurityModule.sessionIdleTimeout - (performance.now() - this.timeReference)
  }

  private resetTimeReference() {
    this.timeReference = performance.now()
  }

  private async logout() {
    this.events.forEach(event => {
      document.removeEventListener(event, this.resetTimeReference, true)
    })
    clearInterval(this.intervalID)
    await SecurityModule.logOut()
    await this.$router.push({ name: "home" })
  }
}
