
















import { Component, Ref, Watch } from "vue-property-decorator"
import { SurveySubmission } from "@/model/interfaces/submission/surveySubmission/surveySubmission"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import "handsontable/dist/handsontable.full.min.css"
import Handsontable from "@/components/elements/Handsontable/Handsontable.vue"
import SurveySubmissionParserService from "@/services/submission/surveySubmission/SurveySubmissionParserService"
import DataCleaningModule from "@/store/modules/DataCleaningModule"
import VueTrans from "@/mixins/VueTrans.vue"
import TemplateModule from "@/store/modules/form/TemplateModule"
import { HandsontableStyle } from "@/model/interfaces/handsontable/handsontableStyle"
import { Template } from "@/model/interfaces/form/template/template"
import HandsontableUtils from "@/services/utils/HandsontableUtils"
import { HandsontableChangeCell } from "@/model/interfaces/handsontable/handsontableChangeCell"

@Component({
  components: {
    Handsontable
  }
})
export default class OriginalData extends VueTrans {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.dataCleaning."
  loading = false

  @Ref("handsontableComponent") handsontableComponent!: Handsontable

  // ///////////////////////////////////////////////////////////////////////////
  // Styles
  // ///////////////////////////////////////////////////////////////////////////
  readonly styles: HandsontableStyle[] = [
    HandsontableUtils.HARMONIZED_STYLE,
    HandsontableUtils.CROP_STYLE,
    HandsontableUtils.OUTLIERS_CROP_STYLE,
    HandsontableUtils.CLEANED_DATA_STYLE
  ]

  readonly fieldsToStyle: Record<string, string> = {
    // Harmonized
    [SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_1_O_11_ST_CROP]: HandsontableUtils.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_CROP_PRODUCTION_2_O_12_ND_CROP]: HandsontableUtils.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV]: HandsontableUtils.HARMONIZED_STYLE.id,
    [SurveySubmissionParserService.HARMONIZED_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_CV_2]: HandsontableUtils.HARMONIZED_STYLE.id,

    // Dependency ratio
    [SurveySubmissionParserService.DEPENDENCY_RATIO_HEADERS_BENEFICIARY]: HandsontableUtils.CROP_STYLE.id,

    // Crops
    [SurveySubmissionParserService.CROP_1_KG]: HandsontableUtils.CROP_STYLE.id,
    [SurveySubmissionParserService.CROP_1_HA]: HandsontableUtils.CROP_STYLE.id,
    [SurveySubmissionParserService.CROP_2_KG]: HandsontableUtils.CROP_STYLE.id,
    [SurveySubmissionParserService.CROP_2_HA]: HandsontableUtils.CROP_STYLE.id,

    // Animals
    [SurveySubmissionParserService.OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED]: HandsontableUtils.CROP_STYLE.id,
    [SurveySubmissionParserService.OUTLIER_GOUTPUT_1_O_1_ANIMAL_PRODUCTION_ANIMAL_OWNED_2]: HandsontableUtils.CROP_STYLE.id,

    // Outlier Crops
    [SurveySubmissionParserService.OUTLIER_CROP_1_KG]: HandsontableUtils.OUTLIERS_CROP_STYLE.id,
    [SurveySubmissionParserService.OUTLIER_CROP_1_HA]: HandsontableUtils.OUTLIERS_CROP_STYLE.id,
    [SurveySubmissionParserService.OUTLIER_CROP_2_KG]: HandsontableUtils.OUTLIERS_CROP_STYLE.id,
    [SurveySubmissionParserService.OUTLIER_CROP_2_HA]: HandsontableUtils.OUTLIERS_CROP_STYLE.id,

    // Data cleaned
    [SurveySubmissionParserService.HARMONIZED_HEADERS_DATA_CLEANED]: HandsontableUtils.CLEANED_DATA_STYLE.id
  }

  readonly columnsOptions: Record<string, unknown> = {
    start: { type: "date", dateFormat: "YYYY-MM-DD" },
    end: { type: "date", dateFormat: "YYYY-MM-DD" },
    _id: { readOnly: true }
  }

  getColumnsOptions(): Record<string, unknown> {
    let columnsOptions = { ...this.columnsOptions }
    for (const columnName of SurveySubmissionParserService.HARMONIZED_HEADERS_BENEFICIARY) {
      columnsOptions[columnName] = { readOnly: true }
    }

    return columnsOptions
  }

  // ///////////////////////////////////////////////////////////////////////////
  // getters
  // ///////////////////////////////////////////////////////////////////////////
  created(): void {
    this.$root.$refs.OriginalData = this
  }

  async mounted(): Promise<void> {
    await this.onSurveySubmissionsChange()
  }

  get search(): string {
    return DataCleaningModule.search
  }

  get isExactMatchSearch(): boolean {
    return DataCleaningModule.isExactMatchSearch
  }

  get filterColumns(): string[] {
    return DataCleaningModule.selectedColumns
  }

  get surveySubmissions(): SurveySubmission[] {
    return SurveySubmissionModule.beneficiarySubmissions
  }

  get template(): Template | null {
    return TemplateModule.template
  }

  get headers(): string[] {
    if (TemplateModule.template) {
      return SurveySubmissionParserService.getBeneficiaryHeaders(TemplateModule.template)
    }
    return []
  }

  get values(): Record<string, unknown>[] {
    return SurveySubmissionParserService.getFieldsValues(this.surveySubmissions)
  }

  setTotalFoundValues(val: number | null): void {
    DataCleaningModule.setTotalFoundValues(val)
  }

  async updatedData(updatedData: HandsontableChangeCell[]): Promise<void> {
    await SurveySubmissionParserService.updateSurveySubmissions(updatedData, this.surveySubmissions)
  }

  @Watch("surveySubmissions")
  async onSurveySubmissionsChange(): Promise<void> {
    await this.handsontableComponent.onChange()
  }

  async onReplace(): Promise<void> {
    await this.handsontableComponent.updateHotByReplace(DataCleaningModule.replace)
  }
}
