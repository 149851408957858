import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import TemplateSubmissionMercureService from "@/services/mercure/TemplateSubmissionMercureService"
import BeneficiaryDatasheetMercureService from "@/services/mercure/BeneficiaryDataSheetMercureService"
import templateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { MercureSubscription } from "@/model/interfaces/mercure/mercureSubscription"

@Module({ dynamic: true, store, name: "BeneficiarySubmissionModule", namespaced: true })
class BeneficiarySubmissionModule extends VuexModule {
  beneficiaryDataSheetEventSource: EventSource | null = null
  beneficiaryDataSheetSubscriptionsEventSource: EventSource | null = null
  beneficiaryDataSheetActiveUsernames: string[] = []

  @Mutation
  setBeneficiaryDataSheetEventSource(eventSource: EventSource) {
    this.beneficiaryDataSheetEventSource = eventSource
  }

  @Mutation
  setBeneficiaryDataSheetSubscriptionsEventSource(eventSource: EventSource | null) {
    this.beneficiaryDataSheetSubscriptionsEventSource = eventSource
  }

  @Mutation
  setBeneficiaryDataSheetActiveUsernames(usernames: string[]) {
    this.beneficiaryDataSheetActiveUsernames = usernames
  }

  @Mutation
  resetEventSources() {
    this.beneficiaryDataSheetEventSource = null
    this.beneficiaryDataSheetSubscriptionsEventSource = null
    this.beneficiaryDataSheetActiveUsernames = []
  }

  @Action
  async updateBeneficiaryDataSheetActiveUsers() {
    if (templateSubmissionModule.currentSubmission != null) {
      const subscriptions = await BeneficiaryDatasheetMercureService.fetchSubscriptions(templateSubmissionModule.currentSubmission)
      const usernames = subscriptions.map((subscription: MercureSubscription) => subscription.payload.user)
      this.setBeneficiaryDataSheetActiveUsernames(usernames)
    }
  }

  @Action
  async closeBeneficiaryDataSheetEventSources() {
    if (this.beneficiaryDataSheetEventSource) {
      await TemplateSubmissionMercureService.closeEventSource(this.beneficiaryDataSheetEventSource)
    }
    if (this.beneficiaryDataSheetSubscriptionsEventSource) {
      await TemplateSubmissionMercureService.closeEventSource(this.beneficiaryDataSheetSubscriptionsEventSource)
    }
    this.resetEventSources()
  }
}

export default getModule(BeneficiarySubmissionModule)
