




import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import { HotTable } from "@handsontable/vue"
import HandsontableLib from "handsontable"
import HandsontableBase from "handsontable/base"
import { registerAllModules } from "handsontable/registry"
import { HotTableData } from "@handsontable/vue/types"
import HandsontableUtils from "@/services/utils/HandsontableUtils"
import { HandsontableStyle } from "@/model/interfaces/handsontable/handsontableStyle"
import { HandsontableStylingCondition } from "@/model/enum/handsontable/handsontableStylingCondition"
import { THEMES } from "@/plugins/vuetify"
import { CellValue } from "handsontable/common"
import { HandsontableSearchResult } from "@/model/interfaces/handsontable/handsontableSearchResult"
import { CellProperties, ColumnSettings } from "handsontable/settings"
import { HandsontableChangeCell } from "@/model/interfaces/handsontable/handsontableChangeCell"

// register Handsontable's modules
registerAllModules()

@Component({
  components: { HotTable }
})
export default class Handsontable extends Vue {
  @Prop() headers!: string[]
  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  fieldsToStyle!: Record<string, string>
  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  columnsOptions!: Record<string, Record<string, string>>
  @Prop({
    type: Array,
    default: () => {
      return []
    }
  })
  styles!: HandsontableStyle[]
  @Prop({ type: String }) search!: string
  @Prop({ type: Array, default: () => [] }) filterColumns!: string[]
  @Prop({ type: Boolean, default: false }) isExactMatchSearch!: boolean
  @Prop({ type: Boolean, default: false }) isReadonly!: boolean
  @Prop() values!: Record<string, unknown>[]
  @Ref("hotTableComponent") hotTableComponent!: HotTableData

  settings: HandsontableBase.GridSettings = {
    data: [],
    hiddenColumns: {
      indicators: true
    },
    hiddenRows: {
      indicators: true
    },
    search: true,
    dropdownMenu: true,
    readOnly: this.isReadonly,
    wordWrap: true,
    colHeaders: this.colHeaders,
    manualColumnResize: true,
    // autoColumnSize: {
    //   useHeaders: true
    // },
    columns: this.columns,
    fixedRowsTop: 0,
    rowHeaders: true,
    // stretchH: "all",
    height: "100%",
    preventOverflow: "vertical",
    multiColumnSorting: true,
    filters: true,
    licenseKey: "non-commercial-and-evaluation",
    afterChange: this.afterChange
  }

  private _searchResults!: HandsontableSearchResult[] | undefined

  beforeDestroy(): void {
    this.hot?.destroy()
  }

  afterChange(changes: HandsontableLib.CellChange[] | null, source: HandsontableLib.ChangeSource): void {
    let updatedData = [] as HandsontableChangeCell[]
    if (this.hot === undefined || this.hot === null) {
      return
    }

    if (source !== "loadData" && changes !== null) {
      for (const change of changes) {
        if (change[2] === change[3] || (change[2] === undefined && change[3] === "")) {
          continue
        }

        const row = this.hot.getDataAtRow(change[0]) as CellValue[]
        const updatedDataCell = {
          id: row[0],
          column: change[1],
          oldValue: change[2],
          newValue: change[3]
        } as HandsontableChangeCell
        updatedData.push(updatedDataCell)
      }

      if (updatedData.length > 0) {
        this.updatedData(updatedData)
      }
    }
  }

  @Emit()
  updatedData(updatedData: HandsontableChangeCell[]): HandsontableChangeCell[] {
    return updatedData
  }

  get hot(): HandsontableBase | null | undefined {
    return this.hotTableComponent.hotInstance
  }

  @Watch("headers")
  @Watch("search")
  @Watch("isExactMatchSearch")
  @Watch("filterColumns")
  async onChange(): Promise<void> {
    await this.hotTableComponent.hotInstance?.updateSettings({
      colHeaders: this.colHeaders,
      colWidths: HandsontableUtils.widthByHeaders(this.headers),
      columns: this.columns
    })

    await this.hotTableComponent.hotInstance?.loadData(this.values)
    await this.updateHotBySearch()
  }

  async updateHotBySearch(): Promise<void> {
    const search = this.hotTableComponent.hotInstance?.getPlugin("search")
    const hiddenRows = this.hotTableComponent.hotInstance?.getPlugin("hiddenRows")
    if (search && hiddenRows) {
      if (this.search && this.search !== "") {
        search.setQueryMethod(this.isExactMatchSearch ? this.onlyExactMatch : this.defaultQueryMethod)
        this._searchResults = search.query(this.search) as HandsontableSearchResult[]
        this.searchResults(this._searchResults.length)
        // hiddenRows.hideRows(this.getRowsToHideBySearch(this._searchResults))
      } else {
        this.searchResults(null)
        hiddenRows.init()
      }
      this.hotTableComponent.hotInstance?.render()
    }
  }

  async updateHotByReplace(replaceValue: string | undefined = undefined): Promise<void> {
    if (replaceValue !== undefined && this._searchResults !== undefined) {
      this._searchResults.forEach(cell => {
        this.hot?.setDataAtCell([[cell.row, cell.col, replaceValue]])
      })
    }
    this.hotTableComponent.hotInstance?.render()
  }

  // @Watch("headers")
  get colHeaders(): string[] {
    return this.headers.map((header, key) => {
      if (header in this.fieldsToStyle) {
        // Hacky way to apply style to the header
        const style = this.styles.find((s: HandsontableStyle) => s.id === this.fieldsToStyle[header])
        return `<div style="background:${style?.mainColor};">${header}</div>`
      } else {
        return header
      }
    })
  }

  private getRowsToHideBySearch(rowsSearchResult: HandsontableSearchResult[]): number[] {
    let rowsToHide = [] as number[]
    if (this.hotTableComponent.hotInstance) {
      const rows = rowsSearchResult.map(({ row }) => {
        return row
      })
      for (let j = 0; j < this.hotTableComponent.hotInstance.getData().length; j++) {
        if (!rows.includes(j)) {
          rowsToHide.push(j)
        }
      }
    }

    return rowsToHide
  }

  get columns(): ColumnSettings[] {
    const columns = []

    for (const header of this.headers) {
      let column: ColumnSettings = { data: header }
      // Set the renderer if needed
      if (this.styles && this.fieldsToStyle) {
        if (header in this.fieldsToStyle) {
          let style = this.styles.find((s: HandsontableStyle) => s.id === this.fieldsToStyle[header])
          if (style) {
            column.renderer = this.getRenderer(style)
          }
        }
      }
      if (this.columnsOptions && header in this.columnsOptions) {
        column = { ...column, ...this.columnsOptions[header] }
      }

      columns.push(column)
    }

    return columns
  }

  getRenderer(
    style: HandsontableStyle
  ): (instance: HandsontableBase, td: HTMLTableCellElement, row: number, col: number, prop: string | number, value: CellValue) => HTMLTableCellElement {
    return (instance: HandsontableBase, td: HTMLTableCellElement, row: number, col: number, prop: string | number, value: CellValue) => {
      td.innerHTML = value

      // apply faded background on cells
      if (style.isCellColored) {
        td.style.background = style.mainColor + "55"
      }

      // apply Conditional formatting if any
      if (style.condition !== undefined) {
        switch (style.condition) {
          case HandsontableStylingCondition.OFFSET_ROW_SHOULD_BE_THE_SAME:
            if (instance.getDataAtCell(row, col - 1) !== value) {
              td.style.background = THEMES.light.red.lighten2
              td.style.color = THEMES.light.red.lighten1
            }
            break
          default:
            break
        }
      }
      return td
    }
  }

  @Emit()
  searchResults(searchResults: number | null): number | null {
    return searchResults
  }

  onlyExactMatch(queryStr: string | null, value: string | null, cellProperties: CellProperties): boolean {
    if (typeof queryStr === "undefined" || queryStr === null || queryStr.length === 0) {
      return false
    }

    if (typeof value === "undefined" || value === null) {
      return false
    }

    if (!this.isFilterColumn(cellProperties)) {
      return false
    }

    return queryStr.toString() === value
  }

  defaultQueryMethod(queryStr: string | null, value: string | null, cellProperties: CellProperties): boolean {
    if (typeof queryStr === "undefined" || queryStr === null || !queryStr.toLocaleLowerCase || queryStr.length === 0) {
      return false
    }
    if (typeof value === "undefined" || value === null) {
      return false
    }

    if (!this.isFilterColumn(cellProperties)) {
      return false
    }

    return (
      value
        .toString()
        .toLocaleLowerCase(cellProperties.locale)
        .indexOf(queryStr.toLocaleLowerCase(cellProperties.locale)) !== -1
    )
  }

  private isFilterColumn(cellProperties: CellProperties) {
    if (this.filterColumns.length === 0) {
      return true
    }

    return this.filterColumns.includes(cellProperties.prop as string)
  }
}
