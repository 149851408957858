











































import { Component, Ref, Vue } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import SecurityModule from "@/store/modules/SecurityModule"
import router from "@/router"

import Button from "@/components/inputs/Button.vue"
import LayoutPublic from "@/components/layouts/LayoutPublic.vue"
import TextFieldLogin from "@/components/inputs/TextFieldLogin.vue"

@Component({
  components: {
    Button,
    LayoutPublic,
    TextFieldLogin,
    ValidationObserver,
    ValidationProvider
  }
})
export default class LoginPlatform extends Vue {
  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>

  protected user: any = {
    email: "",
    password: ""
  }
  protected showError = false
  protected errorMessage = ""
  protected valid = true
  protected showPassword = false

  private async submit() {
    this.observer.validate().then(async (success: any) => {
      if (!success) {
        return
      }
      await SecurityModule.logIn(this.user)
      if (SecurityModule.isAuthenticated) {
        // Redirects to requested path
        if (!Array.isArray(this.$route.query.from)) {
          const redirect = typeof this.$route.query.from !== "undefined" ? { path: this.$route.query.from } : { name: "home" }
          await router.push(redirect)
          this.showError = false
        }
      } else {
        this.showError = true
        this.errorMessage = SecurityModule.errorMessage
      }
    })
  }
}
