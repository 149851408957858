








import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class WorkInProgress extends Vue {
  @Prop({ default: "" })
  title!: string
}
