



































import { Component } from "vue-property-decorator"
import "handsontable/dist/handsontable.full.min.css"
import ReplaceDialog from "@/components/elements/dialog/ReplaceDialog.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import DataCleaningModule from "@/store/modules/DataCleaningModule"
import OriginalData from "@/components/templateSubmission/surveyDataManagement/beneficiaryDataSheet/originalData/OriginalData.vue"
import TemplateModule from "@/store/modules/form/TemplateModule"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

@Component({
  components: {
    ReplaceDialog
  }
})
export default class OriginalDataHeader extends VueTrans {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.dataCleaning."
  loading = false

  mounted(): void {
    DataCleaningModule.setTotalFoundValues(null)
  }

  get totalFoundValues(): number | null {
    return DataCleaningModule.totalFoundValues
  }

  set search(value: string) {
    DataCleaningModule.setSearch(value)
  }

  get search(): string {
    return DataCleaningModule.search
  }

  set isExactMatchSearch(value: boolean) {
    DataCleaningModule.setIsExactMatchSearch(value)
  }

  get isExactMatchSearch(): boolean {
    return DataCleaningModule.isExactMatchSearch
  }

  get headers(): string[] | undefined {
    return TemplateModule.beneficiaryHeadersWithoutHarmonization
  }

  get hasSubmissionsToUpdate(): boolean {
    return SurveySubmissionModule.submissionsToUpdate.length > 0
  }

  get beneficiarySubmissionUpdateProgress(): string {
    return (SurveySubmissionModule.beneficiarySubmissionUpdateProgress ?? 0) + "%"
  }

  replaceAll(replace: string): void {
    DataCleaningModule.setReplace(replace)
    const originalDataComponent = this.$root.$refs.OriginalData as OriginalData
    originalDataComponent.onReplace()
  }

  onSelectedColumnsChange(columns: string[]): void {
    DataCleaningModule.setSelectedColumns(columns)
  }

  async refresh(): Promise<void> {
    if (TemplateSubmissionModule.currentSubmission) {
      this.loading = true
      await SurveySubmissionModule.getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
      this.loading = false
    }
  }

  async updateSubmissions(): Promise<void> {
    if (SurveySubmissionModule.submissionsToUpdate.length > 0) {
      this.loading = true
      await SurveySubmissionModule.updateSubmissions()
      this.loading = false
    }
  }
}
