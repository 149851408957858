import { getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleLoading from "@/store/VuexModuleLoading"
import { DictionaryItemType } from "@/model/interfaces/submission/templateSubmission/DictionaryItemType"
import CanDictionaryService from "@/services/submission/templateSubmission/CanDictionaryService"
import { Dictionary } from "@/model/interfaces/submission/templateSubmission/Dictionary"

async function performDictionaryAction(action: () => Promise<any>) {
  getModule(CanDictionaryModule).setLoading(true)
  await action()
  const dictionary = await CanDictionaryService.getAll()
  getModule(CanDictionaryModule).setLoading(false)
  return {
    itemDictionary: dictionary
  }
}

@Module({ dynamic: true, store, name: "CanDictionaryModule", namespaced: true })
class CanDictionaryModule extends VuexModuleLoading {
  itemDictionary: Dictionary = []

  get items(): Dictionary {
    return this.itemDictionary
  }

  get keys(): Array<string> {
    return this.items.map(item => item.key)
  }

  get size(): number {
    return this.items.length
  }

  @MutationAction({ mutate: ["itemDictionary"] })
  async fetch() {
    getModule(CanDictionaryModule).setLoading(true)
    const response = await CanDictionaryService.getAll()
    getModule(CanDictionaryModule).setLoading(false)
    return {
      itemDictionary: response
    }
  }

  @MutationAction({ mutate: ["itemDictionary"] })
  async patch(entry: DictionaryItemType) {
    return performDictionaryAction(() => CanDictionaryService.patch(entry))
  }

  @MutationAction({ mutate: ["itemDictionary"] })
  async post(entry: DictionaryItemType) {
    return performDictionaryAction(() => CanDictionaryService.patch(entry))
  }

  @MutationAction({ mutate: ["itemDictionary"] })
  async delete(entry: DictionaryItemType) {
    return performDictionaryAction(() => CanDictionaryService.delete(entry))
  }
}

export default getModule(CanDictionaryModule)
