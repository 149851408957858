import { getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import { SnackBar } from "@/model/interfaces/snackBar"
import SecurityModule from "@/store/modules/SecurityModule"

@Module({ dynamic: true, store, name: "SnackBarModule", namespaced: true })
class SnackBarModule extends VuexModule {
  public snackBars: SnackBar[] = []

  @Mutation
  add(snackBar: SnackBar): void {
    if (SecurityModule.isAuthenticated) {
      snackBar.id = SnackBarModule.getRandomNumber()
      this.snackBars.push(snackBar)
    }
  }

  @MutationAction({ mutate: ["snackBars"] })
  async removeSnackBarById(id: string) {
    const index = this.snackBars.findIndex(snackBar => snackBar.id === id)
    if (index !== -1) {
      this.snackBars.splice(index, 1)
    }

    return { snackBars: this.snackBars }
  }

  static getRandomNumber(): string {
    return Math.random()
      .toString(36)
      .substring(2, 9)
  }
}

export default getModule(SnackBarModule)
