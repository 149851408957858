








import { Component, Vue } from "vue-property-decorator"

@Component
export default class Combobox extends Vue {
  inheritAttrs = false
  comboDefaults = {
    clearable: true,
    label: "",
    height: "44",
    outlined: true,
    disabled: false
  }
}
