enum TemplateDescriptorType {
  PDF_REPORT = "pdf_report",
  SUMMARY = "summary"
}

// path to the template descriptor resource on server side
const RESOURCES_DESCRIPTOR_PATH = "/resources/form/template/descriptor"

export function getDefaultTemplateDescriptorByType(type: TemplateDescriptorType): { name: string; uri: string } {
  switch (type) {
    case TemplateDescriptorType.PDF_REPORT:
      return { name: "templateDescription-pdfreport-default.json", uri: `${RESOURCES_DESCRIPTOR_PATH}/templateDescription-pdfreport-default.json` }
    case TemplateDescriptorType.SUMMARY:
      return { name: "templateDescription-summary-default.json", uri: `${RESOURCES_DESCRIPTOR_PATH}/templateDescription-summary-default.json` }
  }
}

export default TemplateDescriptorType
