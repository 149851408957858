




import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TextField from "./TextField.vue"

@Component({
  components: {
    TextField
  }
})
export default class SearchField extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    height: "44"
  }

  // search
  timeOutID = 0

  @Prop({ default: "" })
  value!: string

  _search = this.value

  @Watch("value", { immediate: true })
  updateLocaleSearchValue() {
    if (this._search !== this.value) {
      this._search = this.value
    }
  }

  get search(): string {
    return this._search
  }

  set search(search: string) {
    this._search = search
    clearTimeout(this.timeOutID)
    if (search) {
      // text is typed
      this.timeOutID = setTimeout(() => {
        this.triggerSearch()
      }, 1000)
    } else {
      // test is cleared
      this.triggerSearch()
    }
  }

  setSearchImmediate(): void {
    clearTimeout(this.timeOutID)
    this.triggerSearch()
  }

  triggerSearch(): void {
    this.$emit("input", this._search)
  }
}
