
















import { Component, Vue } from "vue-property-decorator"
import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"
import NotificationModule from "@/store/modules/NotificationModule"
import NotificationToolbar from "@/components/notification/adminPanel/NotificationToolbar.vue"
import { Pagination } from "@/model/interfaces/pagination"
import NotificationTable from "@/components/notification/adminPanel/NotificationTable.vue"

@Component({
  components: {
    NotificationTable,
    LayoutPaginable,
    NotificationToolbar
  }
})
export default class NotificationView extends Vue {
  mounted(): void {
    NotificationModule.get()
  }

  get pagination(): Pagination {
    return NotificationModule.pagination
  }

  set pagination(pagination: Pagination) {
    NotificationModule.mergePagination(pagination)
    NotificationModule.get()
  }
}
