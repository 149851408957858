import { Action, getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import SecurityModule from "@/store/modules/SecurityModule"
import TemplateSubmissionMercureService from "@/services/mercure/TemplateSubmissionMercureService"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionFilter from "@/model/interfaces/submission/templateSubmission/templateSubmissionFilter"

import VuexModuleSearch from "@/store/VuexModuleSearch"
import EmailCandidate from "@/model/interfaces/submission/templateSubmission/EmailCandidate"
import ApiViolationService from "@/services/api/ApiViolationService"
import ApiViolationModule from "@/store/modules/ApiViolationModule"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"

@Module({ dynamic: true, store, name: "TemplateSubmissionModule", namespaced: true })
class TemplateSubmissionModule extends VuexModuleSearch {
  filters: TemplateSubmissionFilter = {
    year: []
  }
  canEdit = true
  submissions: Array<TemplateSubmission> = []
  submissionEventSources: EventSource[] = []
  currentSubmission: TemplateSubmission | null = null
  currentSubmissionAsPDFContent: string | null = null
  currentSubmissionCanContent: string | null = null
  currentSubmissionCanContentHtml: string | null = null
  currentSubmissionCanLoading = false
  followUpSubmission: TemplateSubmission | null = null
  currentEmailsCandidates: EmailCandidate[] = []

  @Action
  async updateSubmissionByIndex(submission: TemplateSubmission) {
    const index = this.submissions.findIndex(item => item.id === submission.id)

    if (index !== undefined) {
      Object.assign(this.submissions[index], submission)
    }
  }

  @Mutation
  async setCurrentSubmission(submission: TemplateSubmission) {
    this.currentSubmission = submission
  }

  @Action
  async getSubmissionByIndex(index: number) {
    return this.submissions[index]
  }

  @Action
  async harmonizeCurrentSubmission(surveyFormType: SurveyFormType) {
    if (this.currentSubmission === null) {
      return
    }

    this.setLoading(true)

    try {
      await TemplateSubmissionService.harmonize(this.currentSubmission, surveyFormType)
    } finally {
      this.setLoading(false)
    }
  }

  @Mutation
  async setCurrentEmailCandidatesByEmails(emails: string[]) {
    await getModule(TemplateSubmissionModule).resetCurrentEmailCandidates()
    for (const email of emails) {
      this.currentEmailsCandidates.push({ email: email, errorMessage: null })
    }
  }

  @Mutation
  async resetCurrentEmailCandidates() {
    this.currentEmailsCandidates = []
  }

  @MutationAction({
    mutate: ["currentSubmission", "currentSubmissionCanContent", "currentSubmissionAsPDFContent"],
    rawError: true
  })
  async setCurrentSubmissionByIndex(submissionId: number) {
    const templateSubmission = await TemplateSubmissionService.getTemplateSubmission(submissionId)
    if (templateSubmission?.surveyplanEmails) {
      await getModule(TemplateSubmissionModule).setCurrentEmailCandidatesByEmails(templateSubmission.surveyplanEmails)
    }

    return {
      currentSubmission: templateSubmission,
      currentSubmissionCanContent: null,
      currentSubmissionAsPDFContent: null
    }
  }

  @MutationAction({
    mutate: ["followUpSubmission"],
    rawError: true
  })
  async setFollowUpSubmissionByUuid(uuid: string) {
    const submission = await TemplateSubmissionService.getTemplateSubmissionFollowUp(uuid)
    if (submission?.baseLine && submission.endLine && !SecurityModule.isAuthenticated) {
      getModule(TemplateSubmissionModule).setCanEdit(false)
    }
    return {
      followUpSubmission: submission
    }
  }

  @MutationAction({
    mutate: ["currentSubmissionCanContent", "currentSubmissionAsPDFContent"],
    rawError: true
  })
  async updateCurrentSubmissionsEmails() {
    if (this.currentSubmission) {
      const oldSurveyPlanEmails = this.currentSubmission.surveyplanEmails
      const emailCandidates = []
      const currentEmailsCandidates = this.currentEmailsCandidates
      for (const currentEmailCandidate of currentEmailsCandidates) {
        emailCandidates.push(currentEmailCandidate.email)
      }
      let currentSubmission = {
        ...this.currentSubmission,
        surveyplanEmails: emailCandidates
      } as TemplateSubmission
      try {
        currentSubmission = await TemplateSubmissionService.putTemplateSubmissionEmails(currentSubmission)
      } catch (e) {
        currentSubmission.surveyplanEmails = oldSurveyPlanEmails

        // Get Property error message if it has
        for (const currentEmailCandidateKey in this.currentEmailsCandidates) {
          const messageError = ApiViolationService.getByPropertyPath("surveyplanEmails[" + currentEmailCandidateKey + "]")
          if (messageError) {
            this.currentEmailsCandidates[currentEmailCandidateKey].errorMessage = messageError
          }
        }

        ApiViolationModule.reset()
      }

      await this.setCurrentSubmission(currentSubmission)
    }

    return {
      currentSubmissionCanContent: null,
      currentSubmissionAsPDFContent: null
    }
  }

  @MutationAction({
    mutate: ["currentSubmissionAsPDFContent"]
  })
  async retrievePDFContent() {
    if (!this.currentSubmission) {
      return {
        currentSubmissionAsPDFContent: null
      }
    }
    const pdfContent = await TemplateSubmissionService.getPdfReport(this.currentSubmission)
    return {
      currentSubmissionAsPDFContent: pdfContent
    }
  }

  @Mutation
  setCanLoading(bool: boolean): void {
    this.currentSubmissionCanLoading = bool
  }

  @MutationAction({
    mutate: ["currentSubmissionCanContent"]
  })
  async retrieveCanContent() {
    if (!this.currentSubmission) {
      return {
        currentSubmissionCanContent: null
      }
    }
    getModule(TemplateSubmissionModule).setCanLoading(true)
    const can = await TemplateSubmissionService.getPdfCountryAnalysisNote(this.currentSubmission).finally(() => {
      getModule(TemplateSubmissionModule).setCanLoading(false)
    })
    return {
      currentSubmissionCanContent: can
    }
  }

  @MutationAction({
    mutate: ["followUpSubmission"],
    rawError: true
  })
  async updateFollowUpSubmission() {
    if (this.followUpSubmission) {
      getModule(TemplateSubmissionModule).setLoading(true)
      const followUpSubmission = await TemplateSubmissionService.patchTemplateSubmissionFollowUp(this.followUpSubmission).finally(() => {
        getModule(TemplateSubmissionModule).setLoading(false)
      })
      return {
        followUpSubmission: followUpSubmission
      }
    }
    return {
      followUpSubmission: null
    }
  }

  @Mutation
  setStatus(status: string[]) {
    this.filters.status = status
  }

  @Mutation
  setYears(years: number[]) {
    this.filters.year = years
  }

  @Mutation
  setCanEdit(bool: boolean) {
    this.canEdit = bool
  }

  @Mutation
  clearCurrentSubmission() {
    this.currentEmailsCandidates = []
    this.currentSubmission = null
    this.currentSubmissionAsPDFContent = null
    this.currentSubmissionCanContent = null
  }

  @MutationAction({
    mutate: ["submissions", "pagination", "submissionEventSources"],
    rawError: true
  })
  async updateTemplateSubmissions() {
    getModule(TemplateSubmissionModule).setLoading(true)
    const results = await TemplateSubmissionService.get(this.filters, this.pagination).finally(() => {
      getModule(TemplateSubmissionModule).setLoading(false)
    })
    await getModule(TemplateSubmissionModule).closeEventSources()
    const eventSources = await TemplateSubmissionMercureService.subscriptions(results.submissions)
    eventSources.push(TemplateSubmissionMercureService.onCreate())
    return {
      submissions: results.submissions,
      pagination: results.pagination,
      submissionEventSources: eventSources
    }
  }

  @Action
  async closeEventSources() {
    await TemplateSubmissionMercureService.closeEventSources(this.submissionEventSources)
  }
}

export default getModule(TemplateSubmissionModule)
