import ApiViolationModule from "@/store/modules/ApiViolationModule"
import Violation from "@/model/interfaces/api/violation"

export default class ApiViolationService {
  static getByPropertyPath(propertyPath: string): string | undefined {
    for (const errorMessage of ApiViolationModule.errorMessages) {
      if (errorMessage.propertyPath === propertyPath) {
        return errorMessage.message
      }
    }
    return undefined
  }

  static removeByPropertyPath(propertyPath: string): void {
    const errorMessages = ApiViolationModule.errorMessages
    const index = errorMessages.findIndex((errorMessage: Violation) => errorMessage.propertyPath === propertyPath)
    if (index !== -1) {
      errorMessages.splice(index, 1)
      ApiViolationModule.set(errorMessages)
    }
  }
}
