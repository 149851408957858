export default class IriUtils {
  static getId(iri: string): number | undefined {
    const id = iri.split("/").pop()
    if (id !== undefined) {
      return parseInt(id)
    }

    return id
  }

  static getIri(object: Partial<{ "@id"?: string }> | string | undefined): string | undefined {
    if (typeof object === "string") {
      return object
    }

    if (object === undefined) {
      return undefined
    }

    if (object["@id"] !== undefined) {
      return object["@id"]
    }
  }
}
