


























































import { Component, Prop, Ref, Watch } from "vue-property-decorator"
import VueTrans from "@/mixins/VueTrans.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { HarmonizationSettingSubmissionCurrency } from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrency"
import HarmonizationSettingsCurrencyModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsCurrencyModule"
import Combobox from "@/components/inputs/Combobox.vue"
import ExchangeCountryService from "@/services/exchange/ExchangeCountryService"
import { ExchangeCountry } from "@/model/interfaces/exchange/exchangeCountry"
import AutoComplete from "@/components/inputs/AutoComplete.vue"
import TextField from "@/components/inputs/TextField.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import Year from "@/model/interfaces/year"
import AvailableYearModule from "@/store/modules/AvailableYearModule"

@Component({
  components: { SwitchCustom, TextField, AutoComplete, Combobox, ValidationObserver, ValidationProvider }
})
export default class CurrencyForm extends VueTrans {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  @Prop({ type: Boolean, default: false }) isGlobal!: boolean
  transKey = "templatesubmission.surveydatamanagement.partnerDatasheet.harmonizationSettings.form.currency."
  currencyList: ExchangeCountry[] = []

  async mounted(): Promise<void> {
    if (this.currency.year) {
      this.currencyList = await ExchangeCountryService.getYear(this.currency.year)
    }
  }

  get years(): Year[] {
    return AvailableYearModule.years
  }

  get currency(): HarmonizationSettingSubmissionCurrency {
    return HarmonizationSettingsCurrencyModule.current
  }

  get year(): number | undefined {
    return HarmonizationSettingsCurrencyModule.current.year
  }

  get dialog(): boolean {
    return HarmonizationSettingsCurrencyModule.dialogForm
  }

  get loading(): boolean {
    return HarmonizationSettingsCurrencyModule.loading
  }

  async submit(): Promise<void> {
    try {
      await HarmonizationSettingsCurrencyModule.saveCurrent(this.isGlobal)
    } finally {
      this.close()
    }
  }

  @Watch("currency.year")
  async updateCountryList(): Promise<void> {
    if (this.year) {
      this.currencyList = await ExchangeCountryService.getYear(this.year)
    }
  }

  getItemText(item: ExchangeCountry): string {
    if (item.name && item.currency) {
      return `${item.name} (${item.currency})`
    }

    return ""
  }

  close(): void {
    this.form.reset()
    HarmonizationSettingsCurrencyModule.setDialogForm(false)
  }
}
