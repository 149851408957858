import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"

@Module({ dynamic: true, store, name: "DataCleaningModule", namespaced: true })
class DataCleaningModule extends VuexModule {
  search = ""
  replace = ""
  isExactMatchSearch = false
  totalFoundValues: number | null = null
  selectedColumns: string[] = []

  @Mutation
  setSelectedColumns(columns: string[]) {
    this.selectedColumns = columns
  }

  @Mutation
  setSearch(search: string) {
    this.search = search
  }

  @Mutation
  setReplace(replace: string) {
    this.replace = replace
  }

  @Mutation
  setIsExactMatchSearch(isExactMatchSearch: boolean) {
    this.isExactMatchSearch = isExactMatchSearch
  }

  @Mutation
  setTotalFoundValues(totalFoundValues: number | null) {
    this.totalFoundValues = totalFoundValues
  }
}

export default getModule(DataCleaningModule)
