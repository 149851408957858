import { Action, getModule, Module, Mutation, MutationAction } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import HarmonizationSettingSubmissionUnitFilter from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnitFilter"
import { HarmonizationSettingSubmissionUnit } from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnit"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import HarmonizationSettingsUnitService from "@/services/harmonizationSettings/harmonizationSettingsUnit/HarmonizationSettingsUnitService"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"

@Module({ dynamic: true, store, name: "HarmonizationSettingsUnitModule", namespaced: true })
class HarmonizationSettingsUnitModule extends VuexModuleSearch {
  units = [] as HarmonizationSettingSubmissionUnit[]
  current: HarmonizationSettingSubmissionUnit = { global: false }
  dialogForm = false

  filters: HarmonizationSettingSubmissionUnitFilter = {
    search: undefined
  }

  @Mutation
  setCurrent(unit: HarmonizationSettingSubmissionUnit) {
    this.current = unit
  }

  @Mutation
  setDialogForm(dialogForm: boolean) {
    this.dialogForm = dialogForm
  }

  @Mutation
  setGlobalFilter(global: boolean) {
    this.filters.global = global
  }

  @MutationAction({
    mutate: ["units"],
    rawError: true
  })
  async getByTemplateSubmission() {
    getModule(HarmonizationSettingsUnitModule).setLoading(true)
    const results = await HarmonizationSettingsUnitService.getByTemplateSubmission(
      this.filters,
      this.pagination,
      TemplateSubmissionModule.currentSubmission as TemplateSubmission
    ).finally(() => {
      getModule(HarmonizationSettingsUnitModule).setLoading(false)
    })

    getModule(HarmonizationSettingsUnitModule).mergePagination(results.pagination)

    return {
      units: results.units
    }
  }

  @MutationAction({
    mutate: ["units"],
    rawError: true
  })
  async getGlobal() {
    getModule(HarmonizationSettingsUnitModule).setLoading(true)
    getModule(HarmonizationSettingsUnitModule).setGlobalFilter(true)
    const results = await HarmonizationSettingsUnitService.get(this.filters, this.pagination).finally(() => {
      getModule(HarmonizationSettingsUnitModule).setLoading(false)
    })

    getModule(HarmonizationSettingsUnitModule).mergePagination(results.pagination)

    return {
      units: results.units
    }
  }

  @Action
  async delete(unit: HarmonizationSettingSubmissionUnit) {
    try {
      getModule(HarmonizationSettingsUnitModule).setLoading(true)
      await HarmonizationSettingsUnitService.delete(unit)
      const unitIndex = getModule(HarmonizationSettingsUnitModule).units.findIndex(item => item.id === unit.id)
      if (unitIndex !== -1) {
        getModule(HarmonizationSettingsUnitModule).units.splice(unitIndex, 1)
      }
    } finally {
      // await SurveySubmissionModule.reloadByCurrentSubmission()
      getModule(HarmonizationSettingsUnitModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async saveCurrent() {
    getModule(HarmonizationSettingsUnitModule).setLoading(true)
    try {
      this.current.convertRate = parseFloat(this.current.convertRate as string)
      if (this.current["@id"] === undefined) {
        if (TemplateSubmissionModule.currentSubmission) {
          this.current.templateSubmission = TemplateSubmissionModule.currentSubmission["@id"]
        } else {
          this.current.global = true
        }
        getModule(HarmonizationSettingsUnitModule).setCurrent(await HarmonizationSettingsUnitService.post(this.current))
      } else {
        getModule(HarmonizationSettingsUnitModule).setCurrent(await HarmonizationSettingsUnitService.patch(this.current))
      }
    } finally {
      if (TemplateSubmissionModule.currentSubmission) {
        await getModule(HarmonizationSettingsUnitModule).reloadByTemplateSubmission()
      } else {
        await this.getGlobal()
      }

      getModule(HarmonizationSettingsUnitModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async reloadByTemplateSubmission() {
    await this.getByTemplateSubmission()
    await SurveySubmissionModule.reloadByCurrentSubmission()
  }
}

export default getModule(HarmonizationSettingsUnitModule)
