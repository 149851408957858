var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"380"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""},on:{"click":function($event){_vm.dialog = true}}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$mdiDownload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"PasswordProtectionDialog"},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"large"}},[_vm._v("$mdiAlert")]),_vm._v(" "+_vm._s(_vm.$t("export.modal.title"))+" ")],1),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('export.modal.question'))}}),_c('p',{staticClass:"primary--text",domProps:{"innerHTML":_vm._s(_vm.$t('export.modal.passwordInfo'))}}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('span',{staticClass:"d-flex align-center"},[_c('TextFieldLogin',{staticClass:"font-weight-bold flex-grow-1",attrs:{"id":"password","name":"password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? '$mdiEye' : '$mdiEyeOff',"error-messages":errors,"success":valid,"clearable":false,"autocomplete":"new-password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.copyPasswordToClipboard}},[_vm._v(_vm._s(!_vm.copying ? "$mdiContentCopy" : "$mdiCheck"))])],1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"tertiary","small":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("export.modal.answer.cancel")))]),_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","disabled":invalid},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.$t("export.modal.answer.export")))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }