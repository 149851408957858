







import { Component, Prop, Vue } from "vue-property-decorator"
import { Pagination } from "@/model/interfaces/pagination"

@Component
export default class PaginationLabel extends Vue {
  @Prop()
  pagination!: Pagination

  get intervalMin(): number {
    return Math.max(1, this.pagination.itemsPerPage * (this.pagination.current - 1))
  }

  get intervalMax(): number {
    return Math.min(this.pagination.itemCount, this.intervalMin + this.pagination.itemsPerPage - 1)
  }
}
