import HarmonizationSettingSubmissionHamFilter from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHamFilter"

export default class HarmonizationSettingsHamFilterService {
  static normalizeSearchFilter(search: string, filter: HarmonizationSettingSubmissionHamFilter): void {
    filter.name = search
    filter.convertValue = search
  }

  static normalizeFilters(filter: HarmonizationSettingSubmissionHamFilter): HarmonizationSettingSubmissionHamFilter {
    const normalizedFilter: HarmonizationSettingSubmissionHamFilter = {}

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    if (filter.global !== undefined) {
      normalizedFilter.global = filter.global
    }

    if (filter.subType !== undefined) {
      normalizedFilter.subType = filter.subType
    }

    return normalizedFilter
  }
}
