







import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class TextHeader extends Vue {
  @Prop() title!: string
  @Prop({ default: undefined, type: String }) subTitle!: string
}
