



































import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class TemplateSummaryTable extends Vue {
  @Prop({ default: "" })
  title!: string

  @Prop({ default: [""] })
  header!: Array<string>

  @Prop({ default: 0 })
  index!: number

  @Prop({ default: [] })
  content!: []

  // eslint-disable-next-line
  normalize(item: any): string {
    if (typeof item === "string") {
      return item
    }
    if (Array.isArray(item)) {
      return item.filter(subitem => subitem !== "").join(", ")
    }

    return item.toString()
  }
}
