import { extend, localize, setInteractionMode } from "vee-validate"
import { alpha, alpha_dash, alpha_spaces, double, email, max, min, min_value, numeric, required } from "vee-validate/dist/rules"
import en from "vee-validate/dist/locale/en"

setInteractionMode("eager")

extend("required", required)
extend("email", email)
extend("min", min)
extend("max", max)
extend("numeric", numeric)
extend("double", double)
extend("min_value", min_value)
extend("alpha_spaces", alpha_spaces)
extend("alpha_dash", alpha_dash)
extend("alpha", alpha)

// Custom rule: check if value is not in array
extend("value_not_in_array", {
  message: "The key already exists",
  validate(value: string, keys: Array<string>) {
    return !keys.includes(value)
  }
})

localize("en", en)
