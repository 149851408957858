import {Vue} from "vue-property-decorator"
import {Notification as NotificationInterface, Notification} from "@/model/interfaces/notification/notification"
import DateUtils from "@/services/utils/DateUtils";
import {Pagination} from "@/model/interfaces/pagination";
import PaginationUtils from "@/services/utils/PaginationUtils";
import {NotificationFilter} from "@/model/interfaces/notification/notificationFilter";
import NotificationFilterService from "@/services/notification/NotificationFilterService";
import SecurityModule from "@/store/modules/SecurityModule";
import NotificationType from "@/model/enum/notification/notificationType";

export default class NotificationService {
  private static URI = "notifications"
  private static URI_LAST_10 = NotificationService.URI + "/last10"

  static async get(filter: NotificationFilter, pagination: Pagination): Promise<{ notifications: Notification[], pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...NotificationFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      notifications: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static async getLast10(): Promise<Notification[]> {
    const response = await Vue.$axios.get(this.URI_LAST_10)

    return response.data["hydra:member"]
  }

  static groupByDate(notifications: Notification[]): Record<string, NotificationInterface[]> {
    return notifications.reduce((groups, notification) => {
      // eslint-disable-next-line prettier/prettier
      (groups[DateUtils.getDate(notification.createdAt as string).toDateString()] ||= []).push(notification)
      return groups
    }, {} as Record<string, NotificationInterface[]>)
  }

  static async canSeeNotificationCountUpdated(notification: Notification): Promise<boolean> {
    switch (notification.type) {
      case NotificationType.FAO_IMPORT_FAIL:
      case NotificationType.FAO_IMPORT_SUCCESS:
      case NotificationType.UNIT_HARMONIZATION_SUCCESS:
      case NotificationType.UNIT_HARMONIZATION_FAIL:
      case NotificationType.HAM_HARMONIZATION_SUCCESS:
      case NotificationType.HAM_HARMONIZATION_FAIL:
      case NotificationType.PARTNER_HARMONIZATION_SUCCESS:
      case NotificationType.PARTNER_HARMONIZATION_FAIL:
      case NotificationType.PARTNER_SURVEY_SUBMISSION_CREATED:
      case NotificationType.BENEFICIARY_SURVEY_SUBMISSION_CREATED:
        return true
    }

    return (notification.createdBy !== undefined && !(await SecurityModule.isMeByIri(notification.createdBy["@id"])))
  }
}
