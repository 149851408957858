






import { Component } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { HarmonizationSettingSubmissionOutlier } from "@/model/interfaces/harmonizationSettings/outlier/harmonizationSettingSubmissionOutlier"
import HarmonizationSettingsOutlierModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsOutlierModule"
import { mixins } from "vue-class-component"
import VuePagination from "@/mixins/VuePagination.vue"

@Component
export default class OutlierTable extends mixins(VueTrans, VuePagination) {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.table.outlier."

  async mounted(): Promise<void> {
    await HarmonizationSettingsOutlierModule.get()
  }

  get loading(): boolean {
    return HarmonizationSettingsOutlierModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("headers.variable"), value: "name", sortable: true },
      { text: this.t("headers.line"), value: "line", sortable: true },
      { text: this.t("headers.bottom"), value: "bottomValue", sortable: true },
      { text: this.t("headers.top"), value: "topValue", sortable: true },
      { text: this.t("headers.count"), value: "countData", sortable: true }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionOutlier[] {
    return HarmonizationSettingsOutlierModule.outliers
  }
}
