import { Vue } from "vue-property-decorator"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { FaoCountry } from "@/model/interfaces/fao/faoCountry"

export default class FaoCountryService {
  private static URI = "fao_countries"

  static async get(pagination: Pagination): Promise<{ faoCountries: FaoCountry[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      faoCountries: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }
}
