import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import { SurveySubmissionHistory } from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistory"
import SurveySubmissionHistoryFilter from "@/model/interfaces/submission/surveySubmissionHistory/surveySubmissionHistoryFilter"
import SurveySubmissionHistoryService from "@/services/submission/surveySubmissionHistory/SurveySubmissionHistoryService"

@Module({ dynamic: true, store, name: "SurveySubmissionHistoryModule", namespaced: true })
class SurveySubmissionHistoryModule extends VuexModuleSearch {
  surveySubmissionHistories: SurveySubmissionHistory[] = []
  filters: SurveySubmissionHistoryFilter = {}

  @Mutation
  setFormType(formType: SurveyFormType) {
    this.filters["objectId.survey.formType"] = formType
  }

  @Mutation
  clear() {
    this.surveySubmissionHistories = []
    this.filters = {}
  }

  @Mutation
  setSurveySubmissionHistories(surveySubmissionHistories: SurveySubmissionHistory[]) {
    this.surveySubmissionHistories = surveySubmissionHistories
  }
  @Mutation
  setDateBefore(date: string | null) {
    if (date) {
      this.filters.loggedAt = { ...this.filters.loggedAt, before: date }
    } else if (this.filters?.loggedAt?.before) {
      delete this.filters.loggedAt["before"]
    }
  }

  @Mutation
  setDateAfter(date: string | null) {
    if (date) {
      this.filters.loggedAt = { ...this.filters.loggedAt, after: date }
    } else if (this.filters?.loggedAt?.after) {
      delete this.filters.loggedAt["after"]
    }
  }

  @Action
  async getByTemplateSubmission(templateSubmission: TemplateSubmission) {
    getModule(SurveySubmissionHistoryModule).setLoading(true)
    try {
      const results = await SurveySubmissionHistoryService.getByTemplateSubmission(templateSubmission, this.filters, this.pagination)
      this.mergePagination(results.pagination)
      this.setSurveySubmissionHistories(results.submissions)
    } finally {
      getModule(SurveySubmissionHistoryModule).setLoading(false)
    }
  }

  @Action
  async getByCurrentTemplateSubmission() {
    if (TemplateSubmissionModule.currentSubmission) {
      await getModule(SurveySubmissionHistoryModule).getByTemplateSubmission(TemplateSubmissionModule.currentSubmission)
    }
  }
}

export default getModule(SurveySubmissionHistoryModule)
