import HarmonizationSettingSubmissionUnitFilter from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnitFilter"

export default class HarmonizationSettingsUnitFilterService {
  static normalizeSearchFilter(search: string, filter: HarmonizationSettingSubmissionUnitFilter): void {
    filter.name = search
  }

  static normalizeFilters(filter: HarmonizationSettingSubmissionUnitFilter): HarmonizationSettingSubmissionUnitFilter {
    const normalizedFilter: HarmonizationSettingSubmissionUnitFilter = {}

    if (filter.search) {
      this.normalizeSearchFilter(filter.search, normalizedFilter)
    }

    if (filter.global !== undefined) {
      normalizedFilter.global = filter.global
    }

    return normalizedFilter
  }
}
