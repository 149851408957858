import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import AvailableYearService from "@/services/AvailableYearService"
import Year from "@/model/interfaces/year"

@Module({ dynamic: true, store, name: "AvailableYearModule", namespaced: true })
class AvailableYearModule extends VuexModule {
  years: Year[] = []

  @Action
  async get() {
    const years = await AvailableYearService.get()
    this.setYears(years)
  }

  @Mutation
  setYears(years: Year[]) {
    this.years = years
  }
}

export default getModule(AvailableYearModule)
