









import { Component, Vue } from "vue-property-decorator"
import SecurityModule from "@/store/modules/SecurityModule"
import router from "@/router"

import Button from "@/components/inputs/Button.vue"

@Component({
  components: {
    Button
  }
})
export default class ButtonLogout extends Vue {
  inheritAttrs = false
  defaultsAttrs = {
    color: "alert primaryText--text",
    height: "44"
  }

  async logout(): Promise<void> {
    await SecurityModule.logOut()
    await router.push({ name: "home" })
  }
}
