






import { Component, Vue } from "vue-property-decorator"

@Component
export default class AutoComplete extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    clearable: true,
    label: "",
    height: "44",
    outlined: true,
    dense: true,
    flat: true,
    hideDetails: true,
    singleLine: true,
    solo: true
  }
}
