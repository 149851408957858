import LoginView from "@/views/LoginView.vue"
import LoginSelectView from "@/views/login/LoginSelectView.vue"
import LoginUnhcrView from "@/views/login/LoginUnhcrView.vue"
import LoginPlatformView from "@/views/login/LoginPlatformView.vue"

export const routesLogin = {
  path: "/login",
  component: LoginView,
  meta: {
    requiresAuth: false,
    requiresPrivacyNoticyAcceptance: false
  },
  children: [
    {
      path: "",
      name: "login",
      redirect: { name: "select" } // default child pat
    },
    {
      path: "select",
      name: "select",
      component: LoginSelectView
    },
    {
      path: "unhcr",
      name: "login-unhcr",
      component: LoginUnhcrView
    },
    {
      path: "platform",
      name: "login-platform",
      component: LoginPlatformView
    }
  ]
}
