import { Vue } from "vue-property-decorator"
import { ExchangeCountry } from "@/model/interfaces/exchange/exchangeCountry"

export default class ExchangeCountryService {
  private static URI = "exchange_countries"

  static async get(): Promise<ExchangeCountry[]> {
    const response = await Vue.$axios.get(this.URI)

    return response.data["hydra:member"]
  }

  static async getYear(year: number): Promise<ExchangeCountry[]> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        "exchangeRates.calendarYear": year
      }
    })

    return response.data["hydra:member"]
  }
}
