import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import { HarmonizationSettingSubmissionCurrency } from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrency"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import HarmonizationSettingsCurrencyService from "@/services/harmonizationSettings/harmonizationSettingsCurrency/HarmonizationSettingsCurrencyService"
import IriUtils from "@/services/utils/IriUtils"
import HarmonizationSettingSubmissionCurrencyFilter from "@/model/interfaces/harmonizationSettings/currency/harmonizationSettingSubmissionCurrencyFilter"
import VuexModuleSearch from "@/store/VuexModuleSearch"

@Module({ dynamic: true, store, name: "HarmonizationSettingsCurrencyModule", namespaced: true })
class HarmonizationSettingsCurrencyModule extends VuexModuleSearch {
  currencies: HarmonizationSettingSubmissionCurrency[] = []
  current: HarmonizationSettingSubmissionCurrency = { global: false }
  filters: HarmonizationSettingSubmissionCurrencyFilter = {
    search: undefined
  }
  dialogForm = false

  @Mutation
  setCurrent(currency: HarmonizationSettingSubmissionCurrency): void {
    this.current = currency
  }

  @Mutation
  setDialogForm(dialogForm: boolean): void {
    this.dialogForm = dialogForm
  }

  @Mutation
  setCurrencies(currencies: HarmonizationSettingSubmissionCurrency[]): void {
    this.currencies = currencies
  }

  @Mutation
  setGlobalFilter(global: boolean): void {
    this.filters.global = global
  }

  @Action
  async getByTemplateSubmission(): Promise<void> {
    this.setLoading(true)
    const results = await HarmonizationSettingsCurrencyService.getByTemplateSubmission(TemplateSubmissionModule.currentSubmission as TemplateSubmission).finally(() => {
      this.setLoading(false)
    })

    this.setCurrencies(results.currencies)
  }

  @Action
  async delete(currency: HarmonizationSettingSubmissionCurrency): Promise<void> {
    try {
      this.setLoading(true)
      await HarmonizationSettingsCurrencyService.delete(currency)
      const hamIndex = getModule(HarmonizationSettingsCurrencyModule).currencies.findIndex(item => item.id === currency.id)
      if (hamIndex !== -1) {
        getModule(HarmonizationSettingsCurrencyModule).currencies.splice(hamIndex, 1)
      }
    } finally {
      await this.getGlobal()
      getModule(HarmonizationSettingsCurrencyModule).setLoading(false)
    }
  }

  @Action({ rawError: true })
  async saveCurrent(fromDataAnalysis = false): Promise<void> {
    this.setLoading(true)
    this.current.exchangeCountry = IriUtils.getIri(this.current.exchangeCountry)
    try {
      if (this.current["@id"] === undefined) {
        this.current.templateSubmission = TemplateSubmissionModule.currentSubmission?.["@id"]
        this.setCurrent(await HarmonizationSettingsCurrencyService.post(this.current))
      } else {
        this.setCurrent(await HarmonizationSettingsCurrencyService.patch(this.current))
      }
    } finally {
      if (fromDataAnalysis) {
        await this.getGlobal()
      } else {
        await this.reloadByTemplateSubmission()
      }
      this.setLoading(false)
    }
  }

  @Action({ rawError: true })
  async reloadByTemplateSubmission(): Promise<void> {
    await this.getByTemplateSubmission()
    await SurveySubmissionModule.reloadByCurrentSubmission()
  }

  @Action({ rawError: true })
  async getGlobal(): Promise<void> {
    this.setLoading(true)
    this.setGlobalFilter(true)
    if (TemplateSubmissionModule.currentSubmission) {
      await this.getByTemplateSubmission()
    }
    const results = await HarmonizationSettingsCurrencyService.get(this.filters, this.pagination).finally(() => {
      this.setLoading(false)
    })

    this.mergePagination(results.pagination)
    this.setCurrencies(results.currencies)
  }
}

export default getModule(HarmonizationSettingsCurrencyModule)
