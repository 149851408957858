









import { Component, Prop, Ref, Vue } from "vue-property-decorator"
import TextField from "@/components/inputs/TextField.vue"
import Button from "@/components/inputs/Button.vue"
import { Template } from "@/model/interfaces/form/template/template"
import TemplateModule from "@/store/modules/form/TemplateModule"
import { ValidationObserver, ValidationProvider } from "vee-validate"

@Component({
  components: { Button, TextField, ValidationProvider, ValidationObserver }
})
export default class TemplateYearForm extends Vue {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  @Prop({ default: {} }) template!: Template

  async update(template: Template): Promise<void> {
    if (this.form) {
      this.form.validate().then(async (success: boolean) => {
        if (!success) {
          return
        }
        await TemplateModule.update(template)
      })
    }
  }
}
