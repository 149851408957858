




import { Component, Vue } from "vue-property-decorator"
import NotificationTable from "@/components/notification/adminPanel/NotificationTable.vue"
import NotificationModule from "@/store/modules/NotificationModule"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

@Component({
  components: { NotificationTable }
})
export default class Notification extends Vue {
  async mounted(): Promise<void> {
    NotificationModule.resetFilters()
    NotificationModule.filters.search = TemplateSubmissionModule.currentSubmission?.id?.toString()
  }

  beforeDestroy(): void {
    NotificationModule.resetFilters()
  }
}
