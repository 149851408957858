import Vue from "vue"
import DOMPurify from "dompurify"

import App from "@/App.vue"
import router from "@/router"
import store from "@/store"

import axiosPlugin from "@/plugins/axios"
import i18n from "@/plugins/i18n"
import vuetify from "@/plugins/vuetify"

import "@/plugins/sentry"
import "@/plugins/vee-validate"

Vue.config.productionTip = false

Vue.use(axiosPlugin)

Vue.directive("san-html", {
  update: (el: any, binding: any) => {
    if (el != null) {
      const whitelistedAttr = ["key", "parent-key"]
      el.innerHTML = DOMPurify.sanitize(binding.value, { ADD_ATTR: whitelistedAttr })
    }
  }
})

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app")
