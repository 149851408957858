var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"UserManagement"},[_c('div',{staticClass:"UserManagement__title d-flex flex-row justify-space-between mt-6"},[_c('h2',[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.navigation")))]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.showCreatePopup()}}},[_vm._v(" "+_vm._s(_vm.$t("admin.page.userManagement.label.addUser"))+" ")])],1),_c('LayoutPaginable',{staticClass:"UserManagement__content",scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('UserManagementToolbar')]},proxy:true},{key:"content",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"d-flex flex-column UserManagementViewForm"},[_c('ValidationProvider',{attrs:{"vid":"azure"}},[_c('SwitchCustom',{attrs:{"label":_vm.$t('admin.page.userManagement.table.headers.azure')},model:{value:(_vm.editedUser.azure),callback:function ($$v) {_vm.$set(_vm.editedUser, "azure", $$v)},expression:"editedUser.azure"}})],1),_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextField',{attrs:{"label":_vm.$t('admin.page.userManagement.table.headers.username'),"single-line":false,"solo":false,"error":errors.length > 0},model:{value:(_vm.editedUser.username),callback:function ($$v) {_vm.$set(_vm.editedUser, "username", $$v)},expression:"editedUser.username"}}),_vm._l((errors.concat( [_vm.usernameError])),function(error,key){return _c('span',{key:key,staticClass:"error--text text-caption"},[_vm._v(_vm._s(error))])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextField',{attrs:{"label":_vm.$t('admin.page.userManagement.table.headers.email'),"placeholder":_vm.$t('admin.page.userManagement.table.headers.email'),"single-line":false,"solo":false,"error":errors.length > 0},model:{value:(_vm.editedUser.email),callback:function ($$v) {_vm.$set(_vm.editedUser, "email", $$v)},expression:"editedUser.email"}}),_vm._l((errors.concat( [_vm.emailError])),function(error,key){return _c('span',{key:key,staticClass:"error--text text-caption"},[_vm._v(_vm._s(error))])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"digit|capital|min:8|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextField',{attrs:{"disabled":_vm.editedUser.azure,"filled":_vm.editedUser.azure,"hide-details":"auto","single-line":false,"solo":false,"label":_vm.$t('admin.page.userManagement.form.password'),"append-icon":_vm.showPassword ? '$mdiEye' : '$mdiEyeOff',"type":_vm.showPassword ? 'text' : 'password',"error":errors.length > 0},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.editedUser.password),callback:function ($$v) {_vm.$set(_vm.editedUser, "password", $$v)},expression:"editedUser.password"}}),_vm._l((errors),function(error,key){return _c('span',{key:key,staticClass:"error--text text-caption"},[_vm._v(_vm._s(error))])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ComboboxMulti',{staticClass:"w-100",attrs:{"error":errors.length > 0,"items":_vm.roles,"item-text":_vm.$t('admin.page.userManagement.table.headers.roles'),"item-value":_vm.$t('admin.page.userManagement.table.headers.roles'),"label":_vm.$t('admin.page.userManagement.table.headers.roles')},model:{value:(_vm.editedUser.roles),callback:function ($$v) {_vm.$set(_vm.editedUser, "roles", $$v)},expression:"editedUser.roles"}}),_vm._l((errors),function(error,key){return _c('span',{key:key,staticClass:"error--text text-caption"},[_vm._v(_vm._s(error))])})]}}],null,true)}),(_vm.hasFocalPointRole)?_c('ValidationProvider',{attrs:{"name":"templates","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AutoComplete',{staticClass:"w-100 UserManagement__templatePicker",attrs:{"multiple":"","outlined":"","dense":"","chips":"","deletable-chips":"","small-chips":"","error":errors.length > 0,"items":_vm.templateSubmissions,"item-text":"id","item-value":"@id","label":_vm.$t('admin.page.userManagement.table.headers.templates')},model:{value:(_vm.editedUser.templateSubmissions),callback:function ($$v) {_vm.$set(_vm.editedUser, "templateSubmissions", $$v)},expression:"editedUser.templateSubmissions"}}),_vm._l((errors),function(error,key){return _c('span',{key:key,staticClass:"error--text text-caption"},[_vm._v(_vm._s(error))])})]}}],null,true)}):_vm._e(),_c('SwitchCustom',{attrs:{"label":_vm.$t('admin.page.userManagement.table.headers.enabled')},model:{value:(_vm.editedUser.enabled),callback:function ($$v) {_vm.$set(_vm.editedUser, "enabled", $$v)},expression:"editedUser.enabled"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"","variant":"text"},on:{"click":function($event){reset()
                  _vm.close()}}},[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.label.cancel")))]),_c('v-btn',{attrs:{"color":"primary","variant":"text","disabled":invalid},on:{"click":function($event){handleSubmit(_vm.save)
                  reset()}}},[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.label.save")))])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.label.popupDeleteTitle")))]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"","variant":"text"},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.label.cancel")))]),_c('v-btn',{attrs:{"color":"primary","variant":"text"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("admin.page.userManagement.label.ok")))])],1)],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.users,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"fixed-header":"","options":_vm.pagination,"disable-pagination":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.enabled),"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("admin.page.userManagement.table.enabled." + (item.enabled ? "yes" : "no")))+" ")])]}},{key:"item.azure",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.azure),"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("admin.page.userManagement.table.enabled." + (item.azure ? "yes" : "no")))+" ")])]}},{key:"item.roles",fn:function(ref){
                  var item = ref.item;
return _vm._l((_vm.getRoles(item.roles)),function(role,key){return _c('v-chip',{key:key,staticClass:"mr-1",attrs:{"color":"primary","small":"","outlined":""}},[_vm._v(" "+_vm._s(role)+" ")])})}},{key:"item.templateSubmissions",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.templateSubmissions),function(templateSubmission,key){return _c('v-chip',{key:key,staticClass:"mr-1",attrs:{"color":"primary","small":"","outlined":""}},[_vm._v(" "+_vm._s(templateSubmission.split("/").pop())+" ")])})}},{key:"item.lastAccessAt",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(new Date(item.lastAccessAt).toLocaleString()))]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"me-2",attrs:{"size":"small"},on:{"click":function($event){return _vm.showEditPopup(item)}}},[_vm._v("$mdiPencil")]),_c('v-icon',{attrs:{"size":"small"},on:{"click":function($event){return _vm.showDeletePopup(item)}}},[_vm._v("$mdiDelete")])]}}])})]},proxy:true}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }