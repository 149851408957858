





























import { Component, Vue } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import DateUtils from "@/services/utils/DateUtils"
import { Notification as NotificationInterface } from "@/model/interfaces/notification/notification"
import NotificationModule from "@/store/modules/NotificationModule"
import NotificationService from "@/services/notification/NotificationService"
import NotificationMenuItem from "./NotificationMenuItem.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    Button,
    NotificationMenuItem
  }
})
export default class Notification extends Vue {
  async mounted(): Promise<void> {
    await NotificationModule.getLast10()
  }

  get notificationsGroupByDate(): Record<string, NotificationInterface[]> {
    return NotificationService.groupByDate(NotificationModule.notificationsLast10)
  }

  get countUnWatchNotification(): number {
    if (SecurityModule.user) {
      return SecurityModule.user?.nbUnseenNotifications ?? 0
    }

    return 0
  }

  getDateToDisplay(date: string | Date): string {
    if (DateUtils.isToday(date)) {
      return this.$t("notification.today") as string
    }
    if (DateUtils.isYesterday(date)) {
      return this.$t("notification.yesterday") as string
    }

    date = DateUtils.getDate(date)

    return date.toLocaleDateString()
  }

  async updateLastSeen(): Promise<void> {
    let [notification] = NotificationModule.notificationsLast10
    await SecurityModule.setLastNotification(notification)
  }
}
