var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutOverviewColumn',{staticClass:"OverviewColumn--sdm",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('th',[_vm._v(" "+_vm._s(_vm.t("title"))+" ")])]},proxy:true},{key:"content",fn:function(){return _vm._l((_vm.submissions),function(submission,key){return _c('tr',{key:key},[_c('td',[_c('v-row',{staticClass:"flex-nowrap"},[_c('div',{staticClass:"OverviewColumn__vCtn"},[_c('Button',{attrs:{"outlined":"","small":"","to":{
                name: 'survey-data-management-sample-profile',
                params: { id: submission.id }
              }}},[_vm._v(" "+_vm._s(_vm.t("sampleprofile.button"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnRow"},[_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("sampleprofile.baseline"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnText"},[_vm._v(" "+_vm._s(submission.baseLine ? submission.baseLine : _vm.t("sampleprofile.lineDefault"))+" ")])]),_c('div',{staticClass:"OverviewColumn__vCtnRow--spacer"}),_c('div',{staticClass:"OverviewColumn__vCtnRow"},[_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("sampleprofile.endline"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnText"},[_vm._v(" "+_vm._s(submission.endLine ? submission.endLine : _vm.t("sampleprofile.lineDefault"))+" ")])])],1),_c('div',{staticClass:"OverviewColumn__vCtn"},[_c('Button',{attrs:{"outlined":"","small":"","to":{
                name: 'survey-data-management',
                params: { id: submission.id }
              }}},[_vm._v(" "+_vm._s(_vm.t("beneficiary.beneficiaryButton"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnRow"},[_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("beneficiary.beneficiaryBaseline"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnText"},[_vm._v(" "+_vm._s(_vm.tc("beneficiary.beneficiaryCount", submission.countBaseline))+" ")])]),_c('div',{staticClass:"OverviewColumn__vCtnRow--spacer"}),_c('div',{staticClass:"OverviewColumn__vCtnRow"},[_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("beneficiary.beneficiaryEndline"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnText"},[_vm._v(" "+_vm._s(_vm.tc("beneficiary.beneficiaryCount", submission.countEndline))+" ")])])],1),_c('div',{staticClass:"OverviewColumn__vCtn"},[_c('v-progress-circular',{attrs:{"value":submission.percentageDataCleaned,"rotate":-90,"color":"primary","size":76,"width":"6"}},[_c('b',[_vm._v(_vm._s(submission.percentageDataCleaned)+"%")])]),_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("dataCleaned"))+" ")])],1),_c('div',{staticClass:"OverviewColumn__vCtn"},[_c('Button',{attrs:{"outlined":"","small":"","color":submission.partnerDataCleaned ? 'primary' : 'error',"to":{
                name: 'survey-data-management-partner-datasheet',
                params: { id: submission.id }
              }}},[_vm._v(" "+_vm._s(_vm.t("partner.button"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnRow"},[_c('div',{staticClass:"OverviewColumn__vCtnTitle"},[_vm._v(" "+_vm._s(_vm.t("partner.partnerSurvey"))+" ")]),_c('div',{staticClass:"OverviewColumn__vCtnText"},[_vm._v(" "+_vm._s(_vm.tc("partner.count", submission.countPartnerSubmission))+" ")])])],1)])],1)])})},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }