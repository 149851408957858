import { Vue } from "vue-property-decorator"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { HarmonizationSettingSubmissionHam } from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHam"
import HarmonizationSettingsHamFilterService from "@/services/harmonizationSettings/harmonizationSettingsHam/HarmonizationSettingsHamFilterService"
import HarmonizationSettingSubmissionHamFilter from "@/model/interfaces/harmonizationSettings/ham/harmonizationSettingSubmissionHamFilter"
import { AxiosResponse } from "axios"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import HarmonizationSettingSubmissionUnitFilter from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnitFilter"

export default class HarmonizationSettingsHamService {
  private static URI = "harmonization_setting_submission_hams"

  static async getByTemplateSubmission(
    filter: HarmonizationSettingSubmissionHamFilter,
    pagination: Pagination,
    templateSubmission: TemplateSubmission
  ): Promise<{ hams: HarmonizationSettingSubmissionHam[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(TemplateSubmissionService.URI + "/" + templateSubmission.id + "/" + this.URI, {
      params: {
        ...HarmonizationSettingsHamFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  static async get(filter: HarmonizationSettingSubmissionUnitFilter, pagination: Pagination): Promise<{ hams: HarmonizationSettingSubmissionHam[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...HarmonizationSettingsHamFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    return this.getResultWihPagination(response, pagination)
  }

  private static getResultWihPagination(response: AxiosResponse, pagination: Pagination) {
    return {
      hams: response.data["hydra:member"],
      pagination: PaginationUtils.getPaginationResponse(response, pagination)
    }
  }

  static async delete(ham: HarmonizationSettingSubmissionHam): Promise<AxiosResponse> {
    return await Vue.$axios.delete(this.URI + "/" + ham.id)
  }

  static async post(ham: HarmonizationSettingSubmissionHam): Promise<HarmonizationSettingSubmissionHam> {
    const response = await Vue.$axios.post(this.URI, ham)
    return response.data
  }

  static async patch(ham: HarmonizationSettingSubmissionHam): Promise<HarmonizationSettingSubmissionHam> {
    const response = await Vue.$axios.patch(this.URI + "/" + ham.id, ham)
    return response.data
  }
}
