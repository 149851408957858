import MercureService from "@/services/mercure/MercureService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import { Vue } from "vue-property-decorator"
import { MercureSubscriptions } from "@/model/interfaces/mercure/mercureSubscriptions"
import MercureMessageType from "@/model/enum/mercureMessageType"
import router from "@/router"
import BeneficiarySubmissionModule from "@/store/modules/submission/BeneficiaySubmissionModule"
import { MercureSubscription } from "@/model/interfaces/mercure/mercureSubscription"

export default class BeneficiaryDatasheetMercureService extends MercureService {
  static URI = "template_submission/"

  static async fetchSubscriptions(templateSubmission: TemplateSubmission): Promise<MercureSubscription[]> {
    return await Vue.$axios.get(this.getSubscriptionsUrl(this.getTopicUrl(templateSubmission))).then(response => {
      return response.data["subscriptions"]
    })
  }

  static getTopicUrl(templateSubmission: TemplateSubmission): string {
    if (templateSubmission.id !== undefined) {
      return router.resolve({
        name: "survey-data-management-beneficiary-datasheet",
        params: { id: templateSubmission.id.toString() }
      }).href
    }
    throw new Error("No template submission provided")
  }

  static async createEventSource(templateSubmission: TemplateSubmission): Promise<void> {
    if (BeneficiarySubmissionModule.beneficiaryDataSheetEventSource === null) {
      const topic = BeneficiaryDatasheetMercureService.getTopicUrl(templateSubmission)
      const eventSource = this.newEventSource(topic)
      BeneficiarySubmissionModule.setBeneficiaryDataSheetEventSource(eventSource)
    }
  }

  static onMessage(eventSource: EventSource, type?: MercureMessageType): EventSource {
    eventSource.onmessage = async ({ data }) => {
      await BeneficiarySubmissionModule.updateBeneficiaryDataSheetActiveUsers()
    }

    return eventSource
  }

  static async listenSubscriptions(templateSubmission: TemplateSubmission): Promise<void> {
    if (BeneficiarySubmissionModule.beneficiaryDataSheetSubscriptionsEventSource === null) {
      const { lastEventId, id }: MercureSubscriptions = await this.getSubscriptionsLastEventId(templateSubmission)
      const eventSource = this.newEventSource(id + `{/subscriber}`, lastEventId)
      BeneficiarySubmissionModule.setBeneficiaryDataSheetSubscriptionsEventSource(eventSource)
      this.onMessage(eventSource)
    }
  }

  static async getSubscriptionsLastEventId(templateSubmission: TemplateSubmission): Promise<MercureSubscriptions> {
    return await Vue.$axios.get(this.getSubscriptionsUrl(this.getTopicUrl(templateSubmission))).then(response => {
      return { lastEventId: response.data["lastEventID"], id: response.data["id"] }
    })
  }
  static getSubscriptionsUrl(topic: string, fullUrl = true, encodeUri = true): string {
    const topicUrl = new URL(this.URI_SUBSCRIPTION + (encodeUri ? encodeURIComponent(topic) : topic))
    return fullUrl ? topicUrl.href : topicUrl.pathname
  }
}
