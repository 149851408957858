import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import { Template } from "@/model/interfaces/form/template/template"
import TemplateService from "@/services/form/TemplateService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import IriUtils from "@/services/utils/IriUtils"
import VuexModuleLoading from "@/store/VuexModuleLoading"
import TemplateParserService from "@/services/form/TemplateParserService"
import TemplateDescriptorService from "@/services/form/TemplateDescriptorService"
import TemplateDescriptorType from "@/model/enum/form/template/templateDescriptorType"
import StringUtils from "@/services/utils/StringUtils"

@Module({ dynamic: true, store, name: "TemplateModule", namespaced: true })
class TemplateModule extends VuexModuleLoading {
  templates: Template[] = []
  template: Template | null = null
  beneficiaryHeaders: string[] = []
  partnerHeaders: string[] = []
  file: File | null = null
  fileError: string | null = null
  fileLoading = false
  fileDialogForm = false
  templateDialogForm: Template | null = null
  templateDescriptorTypeDialogForm: TemplateDescriptorType | null = null

  get beneficiaryHeadersWithoutHarmonization(): string[] {
    return this.template?.beneficiaryHeaders ?? []
  }

  @Mutation
  setFile(file: File | null) {
    this.file = file
  }

  @Mutation
  setFileDialogForm(form: boolean) {
    this.fileDialogForm = form
  }

  @Mutation
  setFileError(error: string | null) {
    this.fileError = error
  }

  @Mutation
  setFileLoading(loading: boolean) {
    this.fileLoading = loading
  }

  @Mutation
  setTemplateDialogForm(template: Template | null) {
    this.templateDialogForm = template
  }

  @Mutation
  setTemplateDescriptorTypeDialogForm(templateDescriptorType: TemplateDescriptorType | null) {
    this.templateDescriptorTypeDialogForm = templateDescriptorType
  }

  @Mutation
  setTemplate(template: Template | null) {
    this.template = template
  }

  @Mutation
  setBeneficiaryHeaders(headers: string[]) {
    this.beneficiaryHeaders = headers
  }

  @Mutation
  setPartnerHeaders(headers: string[]) {
    this.partnerHeaders = headers
  }

  @Mutation
  setTemplates(templates: Template[]) {
    this.templates = templates
  }

  @Mutation
  async clearTemplate() {
    this.template = null
    this.beneficiaryHeaders = []
    this.partnerHeaders = []
  }

  @Action
  async getTemplates() {
    getModule(TemplateModule).setLoading(true)
    const templates = await TemplateService.get().finally(() => {
      getModule(TemplateModule).setLoading(false)
    })
    this.setTemplates(templates)
    if (this.template === null && this.templates.length > 0) {
      this.setTemplate(this.templates[this.templates.length - 1])
    }
  }

  @Action
  async getByTemplateSubmission(templateSubmission: TemplateSubmission | null) {
    if (templateSubmission?.template) {
      const templateId = IriUtils.getId(templateSubmission.template)
      if (templateId) {
        const template = await TemplateService.getOne(templateId)
        this.setTemplate(template)
        if (template) {
          this.setBeneficiaryHeaders(TemplateParserService.getBeneficiaryHeaders(template))
          this.setPartnerHeaders(TemplateParserService.getPartnerHeaders(template))
        }
      }
    }
  }

  @Action
  async update(template: Template) {
    this.setLoading(true)
    if (template.year) {
      template.year = StringUtils.toNumber(template.year as string)
    }
    const { id, year } = template
    await TemplateService.update({ id, year })
    this.setLoading(false)
  }

  @Action({ rawError: true })
  async postFileDescriptor(): Promise<boolean> {
    if (this.templateDialogForm?.["@id"] && this.templateDescriptorTypeDialogForm) {
      this.setFileLoading(true)
      this.setFileError(null)

      await this.deleteFileDescriptor(this.templateDialogForm)

      const form = new FormData()
      form.append("file", this.file as Blob)
      form.append("template", this.templateDialogForm["@id"])
      form.append("type", this.templateDescriptorTypeDialogForm.toString())
      const response = await TemplateDescriptorService.postFile(form)
      if ("error" in response) {
        this.setFileError(response.error)
        this.setFileLoading(false)

        return false
      }
    }

    await this.getTemplates()

    this.setFile(null)
    this.setFileLoading(false)

    return true
  }

  @Action
  async deleteFileDescriptor(template: Template) {
    if (this.templateDescriptorTypeDialogForm) {
      const templateDescriptor = TemplateService.getTemplateDescriptorByType(template, this.templateDescriptorTypeDialogForm)
      if (templateDescriptor) {
        await TemplateDescriptorService.delete(templateDescriptor)
      }
    }
  }
}

export default getModule(TemplateModule)
