












import { Component, Prop } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import VueTrans from "@/mixins/VueTrans.vue"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    SwitchCustom,
    Button
  }
})
export default class OverviewTableReportDataPublication extends VueTrans {
  transKey = "dashboard.overview.reportDataPublication."
  isLoading = false

  @Prop() templateSubmission!: TemplateSubmission

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }

  async setPublishable(templateSubmission: TemplateSubmission): Promise<void> {
    this.isLoading = true
    await TemplateSubmissionService.patchTemplateSubmission(templateSubmission)
    this.isLoading = false
  }
}
