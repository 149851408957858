










import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class GlobalIndicatorsFigure extends Vue {
  @Prop() public figureNumber!: string
  @Prop() public title!: string
  @Prop() public itemPerLine!: number

  get itemWidth() {
    switch (this.itemPerLine) {
      case 1:
        return "100%"
      case 2:
        return "49%"
      case 3:
        return "32%"
      default:
        return "100%"
    }
  }
}
