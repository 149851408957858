









import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"

import Loading from "@/components/elements/Loading.vue"

@Component({
  components: {
    Loading
  }
})
export default class LayoutPublic extends Vue {
  @Prop({ default: false })
  loading!: boolean
}
