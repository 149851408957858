





















































import { Component, Prop, Vue } from "vue-property-decorator"
import HeaderTopUser from "./HeaderTopUser.vue"
import GitInfoModule from "@/store/modules/GitInfoModule"
import GitInfo from "@/model/interfaces/gitInfo"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    HeaderTopUser
  }
})
export default class HeaderTopLogo extends Vue {
  @Prop({ default: "title.title" }) title!: string
  @Prop({ default: "title.subtitle" }) subtitle!: string

  openGitInfoDetail = false

  async created(): Promise<void> {
    await GitInfoModule.get()
  }

  get isAdmin(): boolean {
    return SecurityModule.isAdmin
  }

  get gitInfo(): GitInfo {
    return GitInfoModule.gitInfo
  }
}
