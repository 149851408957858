







import { Component, Vue } from "vue-property-decorator"
import GlobalIndicatorsModule from "@/store/modules/GlobalIndicatorsModule"
import GlobalIndicatorsTitle from "@/components/dashboard/GlobalIndicators/GlobalIndicatorsTitle.vue"
import GlobalIndicatorsContent from "@/components/dashboard/GlobalIndicators/GlobalIndicatorsContent.vue"

@Component({
  components: { GlobalIndicatorsTitle, GlobalIndicatorsContent }
})
export default class GlobalIndicatorsView extends Vue {
  async created() {
    await GlobalIndicatorsModule.getDataset()
  }
}
