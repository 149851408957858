import SecurityModule from "@/store/modules/SecurityModule"
import { Vue } from "vue-property-decorator"
import { User } from "@/model/interfaces/user/user"
import { Notification } from "@/model/interfaces/notification/notification"
import { AxiosResponse } from "axios"

export default class UserService {
  private static USERS_URI = "users"

  static async fetchUsers(): Promise<User[]> {
    return Vue.$axios.get(this.USERS_URI).then(response => {
      return response.data["hydra:member"] as User[]
    })
  }

  static async getMe(): Promise<AxiosResponse> {
    return Vue.$axios.get(this.USERS_URI + "/me")
  }

  static async acceptPrivacyNotice(): Promise<AxiosResponse | void> {
    if (SecurityModule.isAuthenticated && SecurityModule.user) {
      return Vue.$axios.post("/users/" + SecurityModule.user.id + "/accept-privacy-notice", {})
    }
  }

  static updateLastNotification(user: User, notification: Notification): Promise<AxiosResponse> {
    return Vue.$axios.patch("/users/" + user.id, { lastNotification: notification["@id"] })
  }

  static async registerUser(user: User) {
    return Vue.$axios.post("/users", user).then(response => {
      return response.data as User
    })
  }

  static async editUser(user: User) {
    return Vue.$axios.patch("/users/" + user.id, user).then(response => {
      return response.data as User
    })
  }

  static async deleteUser(user: User) {
    return Vue.$axios.delete("/users/" + user.id).then(response => {
      return response.data
    })
  }
  //
  // async getUser({ user }: { user: any }) {
  //   return axios
  //     .get("api/users/", {"id": user.id})
  //     .then(response => {
  //      return response.data
  //     })
  // }
}
