







import { Component, Vue } from "vue-property-decorator"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TemplateSubmissionInfo from "@/views/TemplateSubmission/TemplateSubmissionInfo.vue"
import EmailTextAreaCopy from "@/views/TemplateSubmission/EmailTextAreaCopy.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

@Component({
  components: { TemplateSubmissionInfo, EmailTextAreaCopy }
})
export default class TemplateViewEmail extends Vue {
  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }
  get mail(): string {
    return `
      Dear ${this.submission?.focalPointName},

      Thank you very much for submitting the Livelihoods Monitoring Template.

      We confirm the receipt and please find below the link to updated Monitoring Template for your kind attention.

      Please kindly let us know in case you find any information missing, or parts not reflecting correctly what you submitted.

      We are in process of developing customized mobile survey tools for your operation based on the information provided in the Template, as well as a user training on the tools.We shall come back to you as soon as they are ready and accesible.

      Thank you and best regards

      PDF Template URL: (https://......)
    `
  }
}
