


















import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import ButtonScrollbar from "@/components/inputs/ButtonScrollbar.vue"
import PaginationItem from "@/components/elements/PaginationItem.vue"
import { Pagination } from "@/model/interfaces/pagination"

@Component({
  components: {
    ButtonScrollbar,
    PaginationItem
  }
})
export default class LayoutPaginable extends Vue {
  @Prop({})
  value!: Pagination
  valueLocal: Pagination = this.value

  @Watch("value")
  handleValueChange() {
    this.valueLocal = this.value
  }

  paginationChanged() {
    this.$emit("input", this.valueLocal)
  }
}
