
















































































































































































































import { Component } from "vue-property-decorator"
import Vue from "vue"

import Button from "@/components/inputs/Button.vue"
import ButtonBack from "@/components/inputs/ButtonBack.vue"
import ButtonClose from "@/components/inputs/ButtonClose.vue"
import ButtonGroup from "@/components/inputs/ButtonGroup.vue"
import ButtonLogout from "@/components/inputs/ButtonLogout.vue"
import Combobox from "@/components/inputs/Combobox.vue"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import ComboboxPagination from "@/components/inputs/ComboboxPagination.vue"
import DateInput from "@/components/inputs/DateInput.vue"
import LayoutBackToOverview from "@/components/layouts/LayoutBackToOverview.vue"
import LayoutLoadable from "../components/layouts/LayoutLoadable.vue"
import LayoutNavbarHorizontal from "@/components/layouts/LayoutNavbarHorizontal.vue"
import LayoutNavbarHorizontalItem from "@/components/layouts/LayoutNavbarHorizontalItem.vue"
import LayoutNavbarHorizontalItemMultiple from "@/components/layouts/LayoutNavbarHorizontalItemMultiple.vue"
import LayoutPaginable from "../components/layouts/LayoutPaginable.vue"
import LayoutSubmissionView from "@/components/layouts/LayoutSubmissionView.vue"
import LayoutToolbar from "../components/layouts/LayoutToolbar.vue"
import { Notification } from "@/model/interfaces/notification/notification"
import NotificationMenu from "@/components/notification/NotificationMenu.vue"
import NotificationMenuItem from "@/components/notification/NotificationMenuItem.vue"
import Loading from "@/components/elements/Loading.vue"
import PaginationItem from "@/components/elements/PaginationItem.vue"
import PaginationLabel from "@/components/elements/PaginationLabel.vue"
import SearchField from "@/components/inputs/SearchField.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import TextField from "@/components/inputs/TextField.vue"
import TextFieldLogin from "@/components/inputs/TextFieldLogin.vue"
import WorkInProgress from "@/components/elements/WorkInProgress.vue"
import { Pagination } from "@/model/interfaces/pagination"
import NotificationType from "@/model/enum/notification/notificationType"
import TextHeader from "@/components/elements/text/TextHeader.vue"

@Component({
  components: {
    TextHeader,
    Button,
    ButtonBack,
    ButtonClose,
    ButtonGroup,
    ButtonLogout,
    Combobox,
    ComboboxMulti,
    ComboboxPagination,
    DateInput,
    LayoutBackToOverview,
    LayoutLoadable,
    LayoutNavbarHorizontal,
    LayoutNavbarHorizontalItem,
    LayoutNavbarHorizontalItemMultiple,
    LayoutPaginable,
    LayoutSubmissionView,
    LayoutToolbar,
    Loading,
    NotificationMenu,
    NotificationMenuItem,
    PaginationItem,
    PaginationLabel,
    SearchField,
    SwitchCustom,
    TextField,
    TextFieldLogin,
    WorkInProgress
  }
})
export default class UIComponentsDemo extends Vue {
  // Date input
  dateValue = new Date("2022-05-20")
  readonly dateMin = new Date("2022-05-10").toISOString().split("T")[0]
  readonly dateMax = new Date("2022-05-30").toISOString().split("T")[0]

  // Button group model
  buttonGroupModel = "item1"

  // Layout Loadable
  isLoadableLoaded = false

  // Notification
  notification: Notification = {
    content: {
      createdAt: "2022-10-20T09:04:10+00:00",
      createdBy: null,
      isEmailNotificationEnabled: true,
      uamIsAccessToTemplateEnabled: false,
      uamStatus: "not_found",
      uamUsername: "angolatestds"
    },
    country: "Angola",
    createdAt: "2022-10-20T16:10:52+00:00",
    dutyStation: "test-DS",
    entity: "App\\Entity\\Submission\\TemplateSubmission",
    entityId: 24623779,
    id: "137",
    type: NotificationType.EMAIL_NOTIFICATION_ENABLED,
    year: 2022
  }

  // Layout paginable
  pagination: Pagination = {
    current: 1,
    last: 5,
    itemsPerPage: 5,
    itemCount: 200
  }

  // Layout Horizontal
  routeMutiples = [
    {
      route: "Nav31",
      name: "Nav3 - 1"
    },
    {
      route: "Nav32",
      name: "Nav3 - 2"
    }
  ]
}
