import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"

@Module({ dynamic: true, store, name: "DataAnalysisModule", namespaced: true })
class DataAnalysisModule extends VuexModule {
  public KEY_CAN_DICTIONARY = "CAN_DICTIONARY"
  public KEY_DATA_BRIDGE = "DATA_BRIDGE"
  public KEY_GLOBAL_HARMONIZATION_SETTING = "GLOBAL_HARMONIZATION_SETTING"

  public KEY_EXCHANGE = "KEY_EXCHANGE"
  public KEY_FAO = "KEY_FAO"
  public KEY_UNIT = "KEY_UNIT"
  public KEY_CURRENCY = "KEY_CURRENCY"
  public KEY_FAO_HARMONIZATION = "KEY_FAO_HARMONIZATION"
  public KEY_HAM_ANIMAL = "KEY_HAM_ANIMAL"
  public KEY_HAM_CROP = "KEY_HAM_CROP"

  public KEY_BENEFICIARY: SurveyFormType = SurveyFormType.Beneficiary
  public KEY_PARTNER: SurveyFormType = SurveyFormType.Partner
  menuKey: string = this.KEY_GLOBAL_HARMONIZATION_SETTING
  globalHarmonizationSettingsMenuKey: string = this.KEY_EXCHANGE
  dataBridgeMenuKey: SurveyFormType = this.KEY_BENEFICIARY

  get isHam(): boolean {
    return (
      this.menuKey === this.KEY_GLOBAL_HARMONIZATION_SETTING &&
      (this.globalHarmonizationSettingsMenuKey === this.KEY_HAM_ANIMAL || this.globalHarmonizationSettingsMenuKey === this.KEY_HAM_CROP)
    )
  }

  get isHamAnimal(): boolean {
    return this.menuKey === this.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === this.KEY_HAM_ANIMAL
  }

  get isHamCrop(): boolean {
    return this.menuKey === this.KEY_GLOBAL_HARMONIZATION_SETTING && this.globalHarmonizationSettingsMenuKey === this.KEY_HAM_CROP
  }

  @Mutation
  setMenuKey(key: string) {
    this.menuKey = key
  }

  @Mutation
  setGlobalHarmonizationSettingsMenuKey(key: string) {
    this.globalHarmonizationSettingsMenuKey = key
  }
  @Mutation
  setDataBridgeMenuKey(key: SurveyFormType) {
    this.dataBridgeMenuKey = key
  }
}

export default getModule(DataAnalysisModule)
