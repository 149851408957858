







import { Component, Vue, Watch } from "vue-property-decorator"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import TemplateSubmissionInfo from "@/views/TemplateSubmission/TemplateSubmissionInfo.vue"
import EmailTextAreaCopy from "@/views/TemplateSubmission/EmailTextAreaCopy.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import SurveySubmissionService from "@/services/submission/surveySubmission/SurveySubmissionService"
import { TranslateResult } from "vue-i18n"

@Component({
  components: { TemplateSubmissionInfo, EmailTextAreaCopy }
})
export default class SampleProfileView extends Vue {
  table = "Loading..."

  async mounted(): Promise<void> {
    await this.getSampleProfileTable()
  }

  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  get mail(): string {
    return `
      Dear ${this.submission?.focalPointName},

      Please note that new data has been submitted from your operation. On ${this.todayDate}, the summary profile of all the samples submitted so far is the following.
      Please kindly confirm when all the data collection and submission are completed, and that all the submitted data have been received correctly at the server.

      SAMPLE PROFILE - ${this.submission?.year ?? ""} ${this.submission?.country ?? ""}

      ${this.table}
    `
  }

  get todayDate(): TranslateResult {
    return this.$d(new Date(), "digit")
  }

  @Watch("submission")
  async getSampleProfileTable(): Promise<void> {
    if (this.submission) {
      this.table = await SurveySubmissionService.getSampleProfileTable(this.submission)
    }
  }
}
