import Vue from "vue"
import router from "@/router"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

if (process.env.NODE_ENV !== "development" && process.env.VUE_APP_SENTRY_ENVIRONMENT !== "production") {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_BASE_DOMAIN, /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}
