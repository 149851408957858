import { Vue } from "vue-property-decorator"
import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import { FaoValue } from "@/model/interfaces/fao/faoValue"
import FaoValueFilterService from "@/services/fao/FaoValueFilterService"
import FaoValueFilter from "@/model/interfaces/fao/faoValueFilter"
import { AxiosError } from "axios"
import VueI18n from "@/plugins/i18n"

export default class FaoValueService {
  private static URI = "fao_values"

  static async get(filter: FaoValueFilter, pagination: Pagination): Promise<{ faoValues: FaoValue[]; pagination: Pagination }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...FaoValueFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      faoValues: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static async postFile(form: FormData): Promise<{ error: string } | { message: string }> {
    try {
      const response = await Vue.$axios.post(this.URI, form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      return response.data
    } catch (error) {
      const err = error as AxiosError
      if (err.response && err.response.data["hydra:description"]) {
        return { error: err.response.data["hydra:description"] }
      }

      return { error: VueI18n.t("error.server").toString() }
    }
  }
}
