import { Vue } from "vue-property-decorator"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

import { Pagination } from "@/model/interfaces/pagination"
import PaginationUtils from "@/services/utils/PaginationUtils"
import TemplateSubmissionFilterService from "@/services/submission/templateSubmission/TemplateSubmissionFilterService"
import TemplateSubmissionFilter from "@/model/interfaces/submission/templateSubmission/templateSubmissionFilter"
import TemplateSummary from "@/model/interfaces/submission/templateSubmission/templateSummary"
import TemplateSummaryFilter from "@/model/interfaces/submission/templateSubmission/templateSummaryFilter"
import TemplateSummaryFilterService from "./TemplateSummaryFilterService"
import RequestUtils from "@/services/utils/RequestUtils"
import { CanContent } from "@/model/interfaces/submission/templateSubmission/CanContent"
import SurveyFormType from "@/model/enum/form/survey/surveyFormType"

export default class TemplateSubmissionService {
  public static URI = "template_submissions"

  // ////////////////////////////////////////////////////////////////////////////
  // Overview context
  // ////////////////////////////////////////////////////////////////////////////

  static async get(
    filter: TemplateSubmissionFilterService,
    pagination: Pagination
  ): Promise<{
    submissions: TemplateSubmission[]
    pagination: Pagination
  }> {
    const response = await Vue.$axios.get(this.URI, {
      params: {
        ...TemplateSubmissionFilterService.normalizeFilters(filter),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)
    return {
      submissions: response.data["hydra:member"],
      pagination: {
        current: current,
        itemsPerPage: pagination.itemsPerPage,
        last: last,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static async getExport(filter: TemplateSubmissionFilter): Promise<string> {
    try {
      const response = await Vue.$axios.get(this.URI + "/export", {
        params: {
          ...TemplateSubmissionFilterService.normalizeFilters(filter)
        },
        responseType: "blob" // important
      })
      return window.URL.createObjectURL(new Blob([response.data]))
    } catch (e) {
      return ""
    }
  }

  static async getEditLink(submission: TemplateSubmission): Promise<string | boolean> {
    try {
      const response = await Vue.$axios.get(this.URI + "/edit_link/" + submission.id)
      return response.data["url"]
    } catch (e) {
      return false
    }
  }

  static async getTemplateSubmission(submissionId: number): Promise<TemplateSubmission | null> {
    try {
      const response = await Vue.$axios.get(this.URI + "/" + submissionId)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async getTemplateSubmissionFollowUp(uuid: string): Promise<TemplateSubmission | null> {
    try {
      const response = await Vue.$axios.get(this.URI + "/followup/" + uuid)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async patchTemplateSubmissionFollowUp(submission: TemplateSubmission): Promise<TemplateSubmission | null> {
    try {
      delete submission.id
      const response = await Vue.$axios.patch(this.URI + "/followup/" + submission.uuid, submission)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async patchTemplateSubmission(submission: TemplateSubmission): Promise<TemplateSubmission | null> {
    try {
      const response = await Vue.$axios.patch(this.URI + "/" + submission.id, submission)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async putTemplateSubmissionEmails(submission: TemplateSubmission): Promise<TemplateSubmission> {
    const response = await Vue.$axios.put(this.URI + "/emails/" + submission.id, submission)
    return response.data
  }

  static async setEmailNotification(templateSubmission: TemplateSubmission): Promise<TemplateSubmission | null> {
    try {
      const response = await Vue.$axios.patch(`${this.URI}/${templateSubmission.id}/email_notification`, templateSubmission)
      return response.data
    } catch (e) {
      return null
    }
  }

  static async patchTemplateSubmissionUAM(submission: Partial<TemplateSubmission>): Promise<TemplateSubmission | null> {
    try {
      const response = await Vue.$axios.patch(this.URI + "/" + submission.id + "/uam", submission)
      return response.data
    } catch (e) {
      return null
    }
  }

  // ////////////////////////////////////////////////////////////////////////////
  // Summary
  // ////////////////////////////////////////////////////////////////////////////

  static async getSummary(
    filters: TemplateSummaryFilter,
    pagination: Pagination
  ): Promise<{
    summary: TemplateSummary[]
    pagination: Pagination
  }> {
    const response = await Vue.$axios.get(this.URI + "/summary", {
      params: {
        ...TemplateSummaryFilterService.normalizeFilters(filters),
        ...PaginationUtils.normalizePagination(pagination)
      }
    })

    const { current, last } = PaginationUtils.parseCurrentLast(response)

    return {
      summary: response.data["hydra:member"],
      pagination: {
        current: current,
        last: last,
        itemsPerPage: pagination.itemsPerPage,
        itemCount: response.data["hydra:totalItems"]
      }
    }
  }

  static async getSummaryExport(filters: TemplateSummaryFilter): Promise<string> {
    try {
      const response = await Vue.$axios.get(this.URI + "/summary-export", {
        params: {
          ...TemplateSummaryFilterService.normalizeFilters(filters)
        },
        responseType: "blob" // important
      })
      return window.URL.createObjectURL(new Blob([response.data]))
    } catch (e) {
      return ""
    }
  }

  // ////////////////////////////////////////////////////////////////////////////
  // PDF
  // ////////////////////////////////////////////////////////////////////////////

  static async getPdfReport(submission: TemplateSubmission): Promise<string> {
    return RequestUtils.getPDFasString(this.URI + "/pdf/report/" + submission.id)
  }

  // ////////////////////////////////////////////////////////////////////////////
  // CAN
  // ////////////////////////////////////////////////////////////////////////////

  static async getCanContent(submission: TemplateSubmission): Promise<CanContent> {
    const response = await Vue.$axios.get(this.URI + "/" + submission.id + "/can/content")
    return response.data
  }

  static async getLatestCanContent(submission: TemplateSubmission): Promise<string> {
    const response = await Vue.$axios.get(this.URI + "/" + submission.id + "/can/content/latest")
    return response.data
  }

  static async resetCanContent(submission: TemplateSubmission): Promise<CanContent> {
    const response = await Vue.$axios.get(this.URI + "/" + submission.id + "/can/content/reset")
    return response.data
  }

  static async getPdfCountryAnalysisNote(submission: TemplateSubmission): Promise<string> {
    return RequestUtils.getPDFasString(this.URI + "/pdf/can/" + submission.id)
  }

  static async getAll(): Promise<TemplateSubmission[]> {
    const response = await Vue.$axios.get(this.URI + "/all")
    return response.data["hydra:member"]
  }

  static async harmonize(submission: TemplateSubmission, surveyFormType: SurveyFormType): Promise<void> {
    await Vue.$axios.get(this.URI + "/" + submission.id + "/harmonization", {
      params: {
        "harmonization.type": surveyFormType
      }
    })
  }
}
