






import { Component, Vue, Watch } from "vue-property-decorator"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

@Component
export default class TemplatePdf extends Vue {
  pdfContent: string | null = null

  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  @Watch("submission")
  async retrievePDFContent(): Promise<void> {
    if (this.submission != null) this.pdfContent = await TemplateSubmissionService.getPdfReport(this.submission)
  }
}
