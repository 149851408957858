







import { Component, Vue } from "vue-property-decorator"
import HeaderTopLogo from "@/components/dashboard/Header/HeaderTopLogo.vue"

@Component({
  components: {
    HeaderTopLogo
  }
})
export default class SurveyPlanFormHeader extends Vue {}
