var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px","persistent":""},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-bold justify-center"},[_vm._v(" "+_vm._s(_vm.unit["@id"] ? _vm.t("edit") : _vm.t("create"))+" ")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"ma-2 justify-center text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('TextField',{attrs:{"color":"primary","label":_vm.t('unit'),"error-messages":errors,"success":valid,"disabled":!_vm.isNew,"singleLine":false,"solo":false,"flat":false,"hideDetails":false},model:{value:(_vm.unit.name),callback:function ($$v) {_vm.$set(_vm.unit, "name", $$v)},expression:"unit.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":_vm.t('convertRate'),"rules":"required|double|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('TextField',{attrs:{"label":_vm.t('convertRate'),"color":"primary","error-messages":_vm.convertRateError || errors,"success":valid,"singleLine":false,"solo":false,"flat":false,"hideDetails":false,"autofocus":""},model:{value:(_vm.unit.convertRate),callback:function ($$v) {_vm.$set(_vm.unit, "convertRate", $$v)},expression:"unit.convertRate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('SwitchCustom',{attrs:{"label":_vm.$t('templatesubmission.surveydatamanagement.harmonizationSettings.common.global'),"disabled":_vm.isGlobal},model:{value:(_vm.unit.global),callback:function ($$v) {_vm.$set(_vm.unit, "global", $$v)},expression:"unit.global"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"alert","dark":"","small":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }