








import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class LayoutNavbarHorizontalItem extends Vue {
  @Prop({ default: "" })
  to!: string
}
