










import { Component, Vue } from "vue-property-decorator"
import Header from "@/components/dashboard/Header/Header.vue"
import IdleLogout from "@/components/IdleLogout.vue"

@Component({
  components: {
    Header,
    IdleLogout
  }
})
export default class DashboardView extends Vue {}
