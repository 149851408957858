



















import { Component } from "vue-property-decorator"
import { DataTableHeader } from "vuetify"
import VueTrans from "@/mixins/VueTrans.vue"
import { Pagination } from "@/model/interfaces/pagination"
import CopyUtils from "@/services/utils/CopyUtils"
import HarmonizationSettingsUnitModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsUnitModule"
import { HarmonizationSettingSubmissionUnit } from "@/model/interfaces/harmonizationSettings/unit/harmonizationSettingSubmissionUnit"
import { mixins } from "vue-class-component"
import VuePagination from "@/mixins/VuePagination.vue"

@Component
export default class UnitTable extends mixins(VueTrans, VuePagination) {
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.table.unit."

  destroyed(): void {
    HarmonizationSettingsUnitModule.resetPagination()
  }

  get loading(): boolean {
    return HarmonizationSettingsUnitModule.loading
  }

  get headers(): DataTableHeader[] {
    return [
      { text: this.t("headers.unit"), value: "name", sortable: true },
      { text: this.t("headers.convertRate"), value: "convertRate", sortable: true },
      { text: this.t("headers.actions"), value: "actions", sortable: false }
    ] as DataTableHeader[]
  }

  get items(): HarmonizationSettingSubmissionUnit[] {
    return HarmonizationSettingsUnitModule.units
  }

  get pagination(): Pagination {
    return HarmonizationSettingsUnitModule.pagination
  }

  set pagination(pagination: Pagination) {
    if (this.hasPaginationChanged(pagination, HarmonizationSettingsUnitModule)) {
      HarmonizationSettingsUnitModule.mergePagination(pagination)
      HarmonizationSettingsUnitModule.getByTemplateSubmission()
    }
  }

  get itemCount(): number {
    return HarmonizationSettingsUnitModule.pagination.itemCount
  }

  edit(unit: HarmonizationSettingSubmissionUnit): void {
    HarmonizationSettingsUnitModule.setCurrent((CopyUtils.create((unit as unknown) as Record<string, unknown>) as unknown) as HarmonizationSettingSubmissionUnit)
    HarmonizationSettingsUnitModule.setDialogForm(true)
  }

  async remove(unit: HarmonizationSettingSubmissionUnit): Promise<void> {
    HarmonizationSettingsUnitModule.setLoading(true)
    HarmonizationSettingsUnitModule.delete(unit).finally(() => {
      HarmonizationSettingsUnitModule.setLoading(false)
    })
  }
}
