
















import { Component } from "vue-property-decorator"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import SurveySubmissionService from "@/services/submission/surveySubmission/SurveySubmissionService"
import SurveySubmissionModule from "@/store/modules/submission/SurveySubmissionModule"
import PasswordProtectionDialog from "@/components/templateSubmission/surveyDataManagement/PasswordProtectionDialog.vue"
import VueTrans from "@/mixins/VueTrans.vue"

@Component({
  components: { PasswordProtectionDialog }
})
export default class ExportList extends VueTrans {
  transKey = "templatesubmission.surveydatamanagement."
  exportXlsx(isHarmonizedData = false, password = ""): void {
    const templateSubmission = TemplateSubmissionModule.currentSubmission
    if (templateSubmission) {
      SurveySubmissionService.exportByTemplateSubmission(templateSubmission, SurveySubmissionModule.filters, isHarmonizedData, password)
    }
  }
}
