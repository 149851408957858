export enum GlobalIndicatorsDatasetKey {
  REGIONS_NAMES = "regionsNames",
  REGIONS_LIST = "regionsList",
  COUNTRIES_LIST = "countriesList",
  TEMPLATES_LIST = "templatesList",
  COUNTRIES_COUNT = "countriesCount",
  SITES_COUNT = "sitesCount",
  PARTNERS_COUNT = "partnersCount",
  BASELINE_BENEFICIARIES_COUNT = "baselineBeneficiariesCount",
  ENDLINE_BENEFICIARIES_COUNT = "endlineBeneficiariesCount",
  BENEFICIARIES_LEGAL_STATUS_COUNT = "beneficiariesLegalStatusCount",
  FAMILIES_SIZE_COUNT = "familiesSizeCount",
  FAMILIES_AVERAGE_SIZE = "familiesAverageSize",
  ORIGIN_COUNTRIES_COUNT = "originCountriesCount",
  LAND_LEGAL_ACCESS = "landLegalAccess",
  EMPLOYMENT_LEGAL_ACCESS = "employmentLegalAccess",
  FINANCIAL_LEGAL_ACCESS = "financialLegalAccess",
  AOW_REPARTITION = "aowRepartition",
  AGRICULTURAL_ACTIVITIES_INVOLVEMENT = "agriculturalActivitiesInvolvement",
  BENEFICIARIES_EMPLOYMENT_TYPE = "beneficiariesEmploymentType",
  BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE = "beneficiariesAgePyramidByAOW_Agriculture",
  BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF = "beneficiariesAgePyramidByAOW_Self",
  BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE = "beneficiariesAgePyramidByAOW_Wage",
  BASELINE_ENDLINE_EMPLOYMENT_COMPARISON = "baselineEndlineEmploymentComparison",
  DISPLACED_BENEFICIARIES_EMPLOYMENT_BY_LINE = "displacedBeneficiariesEmploymentByLine",
  HOST_COMMUNITY_BENEFICIARIES_EMPLOYMENT_BY_LINE = "hostCommunityBeneficiariesEmploymentByLine",
  FEMALE_BENEFICIARIES_EMPLOYMENT_BY_LINE = "femaleBeneficiariesEmploymentByLine",
  MALE_BENEFICIARIES_EMPLOYMENT_BY_LINE = "maleBeneficiariesEmploymentByLine",
  SELF_EMPLOYMENT_IN_AND_OUT_AGRICULTURE = "selfEmploymentInAndOutAgriculture",
  WAGE_EMPLOYMENT_IN_AND_OUT_AGRICULTURE = "wageEmploymentInAndOutAgriculture",
  EMPLOYMENT_SUSTAINABILITY_IN_AGRICULTURE = "employmentSustainabilityInAgriculture",
  EMPLOYMENT_SUSTAINABILITY_OTHERS = "employmentSustainabilityOthers",
  SECONDARY_EMPLOYMENT_MULTIPLIER = "secondaryEmploymentMultiplier",
  SECONDARY_EMPLOYMENT_TYPES = "secondaryEmploymentTypes",
  INCOME_CHANGE_BY_LINE = "incomeChangeByLine",
  SAVING_CHANGE_BY_LINE = "savingChangeByLine",
  SAVING_SERVICE_ACCESS = "savingServiceAccess",
  LOAN_SERVICE_ACCESS = "loanServiceAccess",
  MARKET_ACCESS = "marketAccess"
}
