






























































































































































import { Component, Vue } from "vue-property-decorator"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"

import LayoutPaginable from "@/components/layouts/LayoutPaginable.vue"
import UserManagementToolbar from "@/views/AdminPanel/UserManagement/UserManagementToolbar.vue"
import { Pagination } from "@/model/interfaces/pagination"
import UserModule from "@/store/modules/UserModule"
import { User } from "@/model/interfaces/user/user"
import TextField from "@/components/inputs/TextField.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import AutoComplete from "@/components/inputs/AutoComplete.vue"
import ComboboxMulti from "@/components/inputs/ComboboxMulti.vue"
import UserRole from "@/model/enum/security/userRole"
import ApiViolationService from "@/services/api/ApiViolationService"
import ApiViolationModule from "@/store/modules/ApiViolationModule"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"

@Component({
  components: {
    LayoutPaginable,
    UserManagementToolbar,
    TextField,
    SwitchCustom,
    AutoComplete,
    ComboboxMulti,
    ValidationObserver,
    ValidationProvider
  }
})
export default class UserManagement extends Vue {
  inheritAttrs = false
  defaultsAttr = {
    solo: false,
    singleLine: false
  }
  showPassword = false
  dialog = false
  dialogDelete = false
  editedIndex = -1
  editedUser: User = {
    username: "",
    email: "",
    roles: [],
    templateSubmissions: [],
    azure: true,
    enabled: true
  }

  defaultUser: User = {
    username: "",
    email: "",
    roles: [],
    templateSubmissions: [],
    azure: true,
    enabled: true
  }

  templateSubmissions: TemplateSubmission[] = []

  created() {
    extend("digit", (v: any) => (v && /\d/.test(v)) || this.$t("admin.page.userManagement.constraint.digit"))
    extend("capital", (v: any) => (v && /[A-Z]{1}/.test(v)) || this.$t("admin.page.userManagement.constraint.capital"))
    extend("special", (v: any) => (v && /[^A-Za-z0-9]/.test(v)) || this.$t("admin.page.userManagement.constraint.special"))
  }

  get isEditing() {
    return this.editedIndex !== -1
  }

  showCreatePopup() {
    this.editedUser = Object.assign({}, this.defaultUser)
    this.dialog = true
  }

  showEditPopup(user: User) {
    this.editedIndex = this.users.indexOf(user)
    this.editedUser = Object.assign({}, user)
    this.dialog = true
  }

  showDeletePopup(user: User) {
    this.editedIndex = this.users.indexOf(user)
    this.editedUser = Object.assign({}, user)
    this.dialogDelete = true
  }

  close() {
    this.dialog = false
    this.$nextTick(() => {
      this.editedUser = Object.assign({}, this.defaultUser)
      this.editedIndex = -1
    })
    ApiViolationModule.reset()
  }

  closeDelete() {
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedUser = Object.assign({}, this.defaultUser)
      this.editedIndex = -1
    })
  }

  async save() {
    ApiViolationModule.reset()
    if (this.isEditing) {
      await this.editUser(this.editedUser)
    } else {
      await this.createUser(this.editedUser)
    }
    if (ApiViolationModule.errorMessages.length === 0) {
      this.close()
    }
  }

  async createUser(user: User) {
    await UserModule.registerUser(user)
  }

  async editUser(user: User) {
    await UserModule.editUser(user)
  }

  async deleteItemConfirm() {
    await UserModule.deleteUser(this.editedUser)
    this.closeDelete()
  }

  async mounted(): Promise<void> {
    await UserModule.getUsers()
    await this.setTemplateSubmissions()
  }

  async setTemplateSubmissions() {
    this.templateSubmissions = await TemplateSubmissionService.getAll()
  }

  get roles() {
    return Object.values(UserRole)
  }

  get hasFocalPointRole(): boolean {
    return this.editedUser.roles.includes(UserRole.FOCAL_POINT)
  }

  get formTitle() {
    return this.isEditing ? "Edit user" : "Create user"
  }

  get passwordError(): string | undefined {
    return ApiViolationService.getByPropertyPath("password")
  }

  get usernameError(): string | undefined {
    return ApiViolationService.getByPropertyPath("username")
  }

  get emailError(): string | undefined {
    return ApiViolationService.getByPropertyPath("email")
  }

  get headers(): Record<string, unknown>[] {
    return [
      { text: this.$t("admin.page.userManagement.table.headers.email"), value: "email", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.username"), value: "username", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.roles"), value: "roles", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.templates"), value: "templateSubmissions", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.lastAccessAt"), value: "lastAccessAt", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.enabled"), value: "enabled", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.azure"), value: "azure", sortable: true },
      { text: this.$t("admin.page.userManagement.table.headers.actions"), value: "actions", sortable: true }
    ]
  }

  get users(): User[] {
    return UserModule.users
  }

  getColor(enabled: boolean) {
    return enabled === true ? "primary" : "grey-lighten3"
  }

  getRoles(roles: any) {
    return roles.filter((role: string) => role !== "ROLE_USER")
  }

  get itemsPerPage(): number {
    return UserModule.pagination.itemsPerPage
  }

  get pagination(): Pagination {
    return UserModule.pagination
  }

  set pagination(pagination: Pagination) {
    UserModule.mergePagination(pagination)
  }
}
