

















import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"

import { Component, Vue } from "vue-property-decorator"
import OverviewTableTemplateSubmissionLine from "./OverviewTableTemplateSubmissionLine.vue"
import LayoutOverviewColumn from "@/components/layouts/LayoutOverviewColumn.vue"

@Component({
  components: { LayoutOverviewColumn, OverviewTableTemplateSubmissionLine }
})
export default class OverviewTableTemplateSubmission extends Vue {
  get submissions(): Array<TemplateSubmission> {
    return TemplateSubmissionModule.submissions
  }
}
