









import { LVColors } from "@/plugins/vuetify"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class GlobalIndicatorsChartLegend extends Vue {
  @Prop({ default: () => [] }) readonly legendItems!: {
    color: LVColors
    value: string
  }[]
  @Prop({ default: () => "column" }) readonly orientation!: string
}
