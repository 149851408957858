































import { Component, Ref, Vue, Watch } from "vue-property-decorator"
import Button from "@/components/inputs/Button.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import ConfirmDialog from "@/components/elements/dialog/ConfirmDialog.vue"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionModule from "@/store/modules/submission/TemplateSubmissionModule"
import CanModule from "@/store/modules/submission/CanModule"
import { ValidationObserver } from "vee-validate"
import Loading from "@/components/elements/Loading.vue"
import { CanContent } from "@/model/interfaces/submission/templateSubmission/CanContent"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import SnackBarModule from "@/store/modules/SnackBarModule"

@Component({
  components: { Button, Loading, SwitchCustom, ConfirmDialog }
})
export default class CanEditor extends Vue {
  isSaving = false
  figuresReseting = false
  dynamicFieldsDisplayed = false
  latestCanContent: string | null = ""
  @Ref("canEditor") canEditor!: InstanceType<typeof ValidationObserver>

  mounted() {
    this.$nextTick(async () => {
      if (this.submission) {
        await this.updateCanContent()
      }
    })
  }

  get submission(): TemplateSubmission | null {
    return TemplateSubmissionModule.currentSubmission
  }

  get activeCanContentHtml(): CanContent["html"] | null {
    return CanModule.activeCanContent
  }

  get canContent(): CanContent | null {
    return CanModule.canContent
  }

  get hasNextCanContentItem(): boolean {
    return CanModule.hasNextCanContentItem
  }
  get hasPrevCanContentItem(): boolean {
    return CanModule.hasPrevCanContentItem
  }

  async getLatestCanContent(): Promise<string | null> {
    if (this.submission) {
      return await TemplateSubmissionService.getLatestCanContent(this.submission)
    }
    return null
  }

  async resyncAll() {
    this.figuresReseting = true

    const latestCanContent = await this.getLatestCanContent()
    if (latestCanContent != null) {
      const parsedLatestCanContent = this.createElementFromHTML(latestCanContent)
      const allKeysArborescence = this.getAllKeysArborescence(parsedLatestCanContent)
      Object.keys(allKeysArborescence).forEach(selector => {
        const elems = this.canEditor.querySelector(selector)
        if (elems != null) {
          elems.innerHTML = allKeysArborescence[selector]
        }
      })
      CanModule.pushCanContentHistoryPile(this.canEditor.innerHTML)
    }

    // Timeout so that it doesn't go too fast so that the user knows it was updated
    setTimeout(() => {
      this.figuresReseting = false
    }, 1000)
  }

  createElementFromHTML(htmlString: string): any {
    var div = document.createElement("div")
    div.innerHTML = htmlString.trim()
    return div.firstChild
  }

  getFullSelector(key: HTMLElement, keyAttribute: string): string {
    const parentSelectors = this.getAllParentKeys(key, "parent-key")
      .map((parentKeyAttr: string) => {
        return `[parent-key='${parentKeyAttr}']`
      })
      .reverse()
    parentSelectors.push(`[key='${keyAttribute}']`)
    const fullSelector = parentSelectors.join(" ")
    return fullSelector
  }

  getAllKeysArborescence(html: any) {
    const keysTree: any = {}
    let duplicatedSelectors: string[] = []
    if (html) {
      const keys = html.querySelectorAll("[key]")
      keys.forEach((key: HTMLElement) => {
        const keyAttribute = key.getAttribute("key")
        if (keyAttribute) {
          const fullSelector = this.getFullSelector(key, keyAttribute)
          if (keysTree[fullSelector] !== undefined) {
            duplicatedSelectors.push(fullSelector)
          }
          keysTree[fullSelector] = keyAttribute.startsWith("chart_") ? key.innerHTML : key.innerText.replace(/[\n\r]+|[\s]{2,}/g, " ").trim()
        }
      })
      this.checkDuplicatedKeys(duplicatedSelectors, keys.length)
    }

    return keysTree
  }

  checkDuplicatedKeys(duplicatedSelectors: string[], dynamicFiguresCount: number) {
    const whiteListedSelector = "1_livelihoods_and_economic_inclusion_programme_and_monitoring_scope"
    duplicatedSelectors = duplicatedSelectors.filter(selector => !selector.includes(whiteListedSelector))
    if (duplicatedSelectors.length > 0) {
      SnackBarModule.add({
        code: 500,
        color: "warning",
        timeout: 8000,
        message: `${duplicatedSelectors.length} out of ${dynamicFiguresCount} dynamic figures synchronisation may not have worked properly because some figures keys are not unique. Check console logs for more details.`
      })
      // Display duplicated selectors :
      console.error("Following selectors are not unique, try to fix them :")
      console.table(duplicatedSelectors)
    }
  }

  getAllParentKeys(element: HTMLElement, attributeName: string): string[] {
    let parents: string[] = []
    let currentElement: Node | null = element.parentNode

    while (currentElement !== null && currentElement.nodeType === Node.ELEMENT_NODE) {
      if ((currentElement as Element).hasAttribute(attributeName)) {
        parents.push((currentElement as Element).getAttribute(attributeName)!)
      }
      currentElement = currentElement.parentNode
    }

    return parents
  }

  @Watch("activeCanContentHtml")
  async canContentWatcher(): Promise<void> {
    // TODO : find a cleaner way to make sure everything is properly init
    setTimeout(() => {
      if (this.activeCanContentHtml && this.canEditor != undefined) {
        this.setContentEditable()
      }
    }, 1000)
  }

  @Watch("submission")
  async submissionWatcher() {
    if (this.submission != null && this.canContent == null) {
      await this.updateCanContent()
    }
  }

  async updateCanContent(): Promise<void> {
    if (this.submission != null) {
      await CanModule.setCanContent({ submission: this.submission, reset: false })
    }
  }

  undo() {
    CanModule.prevCanContentHistoryItem()
  }

  redo() {
    CanModule.nextCanContentHistoryItem()
  }

  async reset() {
    if (this.submission != null) {
      await CanModule.resetCanContentHistoryItem(this.submission)
    }
  }

  async save() {
    if (this.canContent && this.activeCanContentHtml) {
      const canContent: CanContent = {
        ...this.canContent,
        html: this.activeCanContentHtml
      }
      this.isSaving = true
      await CanModule.saveCanContent(canContent)
      this.isSaving = false
    }
  }

  pushNewCanContentVersion() {
    if (this.canEditor !== undefined) {
      const canContentHtml: CanContent["html"] = this.canEditor.innerHTML
      CanModule.pushCanContentHistoryPile(canContentHtml)
    }
  }

  setContentEditable() {
    if (this.canEditor != undefined) {
      // Get deepest leaves
      const elements = this.canEditor.querySelectorAll("*")

      elements.forEach((element: HTMLElement) => {
        let initialValue = ""
        element.addEventListener("click", (e: any) => {
          initialValue = e.target.innerText
          makeEditable(e.target)
        })
        element.addEventListener("blur", (e: any) => {
          removeEditable(e.target)
          if (initialValue !== e.target.innerText) {
            this.pushNewCanContentVersion()
          }
        })
      })
    }
    // Function to make the element editable
    function makeEditable(elem: HTMLElement) {
      if (!elem.hasAttribute("not-editable")) {
        elem.setAttribute("contenteditable", "true")
        elem.addEventListener("input", function() {
          // updateHtml()
        })
        elem.focus()
      }
    }

    // Function to remove the contenteditable attribute
    function removeEditable(elem: HTMLElement) {
      elem.removeAttribute("contenteditable")
    }
  }
}
