




























import { Component, Vue } from "vue-property-decorator"
import SurveysModule from "@/store/modules/surveys/SurveysModule"

import LayoutLoadable from "@/components/layouts/LayoutLoadable.vue"
import { Survey } from "@/model/interfaces/form/survey/survey"

@Component({
  components: {
    LayoutLoadable
  }
})
export default class SurveyManagementBeneficiaryView extends Vue {
  readonly HEADER = [
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.year"),
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.koboId"),
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.title"),
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.created"),
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.lastUpdate"),
    this.$root.$i18n.t("dashboard.surveymanagement.beneficiary.columns.ons")
  ]

  async mounted(): Promise<void> {
    await SurveysModule.updateSurveys()
  }

  get surveys(): Array<Survey> {
    return SurveysModule.surveys
  }
  get loading(): boolean {
    return SurveysModule.loading
  }
}
