import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

import FocalPointView from "@/views/FocalPointView.vue"
import Page404View from "@/views/Page404View.vue"
import PrivacyNotice from "@/views/PrivacyNotice.vue"

import UIComponentsDemoView from "@/views/UIComponentsDemoView.vue"

import SecurityModule from "@/store/modules/SecurityModule"

import SurveyPlanFormView from "@/views/SurveyPlanForm/SurveyPlanFormView.vue"

import { routesAdmin } from "@/router/admin"
import { routesDashboard } from "@/router/dashboard"
import { routesLogin } from "@/router/login"
import { routesTemplateSubmission } from "@/router/templateSubmission"

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  // A redirection is applied in Home beforeCreate -> dashboard or login
  {
    path: "/",
    name: "home"
  },
  routesLogin,
  routesDashboard,
  routesTemplateSubmission,
  routesAdmin,
  {
    path: "/focalpoint",
    name: "focalpoint",
    component: FocalPointView,
    meta: {
      requiresAuth: true,
      requiresPrivacyNoticyAcceptance: true,
      requiresRoleFocalPoint: true
    }
  },
  {
    path: "/404",
    name: "404",
    component: Page404View,
    meta: { requiresAuth: false }
  },
  {
    path: "/survey-plan-form/:uuid",
    name: "survey-plan-form",
    component: SurveyPlanFormView
  },
  {
    path: "/privacy-notice",
    name: "privacy-notice",
    component: PrivacyNotice
  },
  {
    path: "/ui",
    name: "ui",
    component: UIComponentsDemoView
  },
  {
    path: "/logout",
    beforeEnter: async () => {
      await SecurityModule.logOut()
    },
    redirect: { name: "login" }
  },
  {
    path: "*",
    redirect: { name: "404" }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === "home") {
    await SecurityModule.checkAuthenticated()
    if (SecurityModule.isAdmin) {
      next({ name: "usermanagement" })
    } else if (SecurityModule.isSupportTeam || SecurityModule.isFocalPoint) {
      next({ name: "dashboard" })
    } else {
      next({ name: "login" })
    }
  } else {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth) {
      // If route requires Authentification
      await SecurityModule.checkAuthenticated()
      if (SecurityModule.isAuthenticated) {
        // If route requires Privacy notice acceptance
        const requiresPrivacy = to.matched.some(record => record.meta.requiresPrivacyNoticyAcceptance)
        if (requiresPrivacy) {
          if (SecurityModule.hasAcceptedPrivacyNotice) {
            // If route requires specific role
            const requiresRoleSupportTeam = to.matched.some(record => record.meta.requiresRoleSupportTeam)
            const requiresRoleFocalPoint = to.matched.some(record => record.meta.requiresRoleFocalPoint)
            const requiresRoleAdmin = to.matched.some(record => record.meta.requiresRoleAdmin)
            if (
              (requiresRoleFocalPoint && SecurityModule.isFocalPoint) ||
              ((requiresRoleSupportTeam || requiresRoleFocalPoint) && SecurityModule.isSupportTeam) ||
              ((requiresRoleAdmin || requiresRoleSupportTeam || requiresRoleFocalPoint) && SecurityModule.isAdmin)
            ) {
              next()
            } else {
              next({ name: "login" })
            }
          } else {
            next({ name: "privacy-notice" })
          }
        } else {
          next()
        }
      } else {
        const redirectpath = window.location.pathname
        next({ name: "login", query: { from: redirectpath } })
      }
    } else {
      next()
    }
  }
})

export default router
