import { Action, getModule, Module, Mutation } from "vuex-module-decorators"
import store from "@/store"
import VuexModuleSearch from "@/store/VuexModuleSearch"
import { FaoCountry } from "@/model/interfaces/fao/faoCountry"
import FaoCountryService from "@/services/fao/FaoCountryService"

@Module({ dynamic: true, store, name: "FaoCountryModule", namespaced: true })
class FaoCountryModule extends VuexModuleSearch {
  faoCountries: FaoCountry[] = []

  @Mutation
  setFaoCountries(faoCountries: FaoCountry[]): void {
    this.faoCountries = faoCountries
  }

  @Action
  async getAll(): Promise<void> {
    getModule(FaoCountryModule).setLoading(true)
    const results = await FaoCountryService.get(this.pagination).finally(() => {
      getModule(FaoCountryModule).setLoading(false)
    })

    getModule(FaoCountryModule).mergePagination(results.pagination)

    this.setFaoCountries(results.faoCountries)
  }
}

export default getModule(FaoCountryModule)
