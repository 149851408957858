import { Module, VuexModule, MutationAction, getModule, Mutation } from "vuex-module-decorators"
import store from "@/store"
import SurveysService from "@/services/surveys/SurveysService"
import { Survey } from "@/model/interfaces/form/survey/survey"

@Module({ dynamic: true, store, name: "SurveysModule", namespaced: true })
class SurveysModule extends VuexModule {
  surveys: Array<Survey> = []
  loading = false

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }

  @MutationAction({
    mutate: ["surveys"]
  })
  async updateSurveys() {
    getModule(SurveysModule).setLoading(true)
    const surveys = await SurveysService.get().finally(() => {
      getModule(SurveysModule).setLoading(false)
    })

    return {
      surveys: surveys
    }
  }
}

export default getModule(SurveysModule)
