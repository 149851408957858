



























































import { Component, Prop, Ref, Watch } from "vue-property-decorator"
import FaoCountryModule from "@/store/modules/fao/FaoCountryModule"
import VueTrans from "@/mixins/VueTrans.vue"
import AutoComplete from "@/components/inputs/AutoComplete.vue"
import { FaoCountry } from "@/model/interfaces/fao/faoCountry"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import HarmonizationSettingsFaoCountryModule from "@/store/modules/harmonizationSettings/HarmonizationSettingsFaoCountryModule"
import { HarmonizationSettingSubmissionFaoCountry } from "@/model/interfaces/harmonizationSettings/FaoCountry/harmonizationSettingSubmissionFaoCountry"
import CopyUtils from "@/services/utils/CopyUtils"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import FaoItemModule from "@/store/modules/fao/FaoItemModule"

@Component({
  components: { SwitchCustom, AutoComplete, ValidationObserver, ValidationProvider }
})
export default class FaoCountryForm extends VueTrans {
  @Ref("form") readonly form!: InstanceType<typeof ValidationObserver>
  @Prop({ type: Boolean, default: false }) isEditable!: boolean
  transKey = "templatesubmission.surveydatamanagement.beneficiaryDatasheet.harmonizationSettings.form.faoCountry."
  harmonizationSettingsFaoCountry: HarmonizationSettingSubmissionFaoCountry | null = null
  isGlobal = false

  get dialog(): boolean {
    return HarmonizationSettingsFaoCountryModule.dialogForm
  }

  get loading(): boolean {
    return HarmonizationSettingsFaoCountryModule.loading
  }

  get countryList(): FaoCountry[] {
    return FaoCountryModule.faoCountries
  }

  async submit(): Promise<void> {
    try {
      if (this.harmonizationSettingsFaoCountry) {
        await HarmonizationSettingsFaoCountryModule.save(this.harmonizationSettingsFaoCountry)
        if (HarmonizationSettingsFaoCountryModule.current.faoCountry) {
          await FaoItemModule.getAllByHarmonizationSettingsFaoCountry(HarmonizationSettingsFaoCountryModule.current)
        }
      }
    } finally {
      this.close()
    }
  }

  close(): void {
    this.form.reset()
    HarmonizationSettingsFaoCountryModule.setDialogForm(false)
  }

  @Watch("dialog", { immediate: true })
  onDialogChange(value: boolean): void {
    if (value && HarmonizationSettingsFaoCountryModule.current) {
      this.harmonizationSettingsFaoCountry = (CopyUtils.create(
        (HarmonizationSettingsFaoCountryModule.current as unknown) as Record<string, unknown>
      ) as unknown) as HarmonizationSettingSubmissionFaoCountry
      this.isGlobal = HarmonizationSettingsFaoCountryModule.current.global
    }
  }
}
