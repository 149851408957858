





































import { Prop, Vue } from "vue-property-decorator"
import Component from "vue-class-component"
import { TemplateSubmission } from "@/model/interfaces/submission/templateSubmission/templateSubmission"
import TemplateSubmissionService from "@/services/submission/templateSubmission/TemplateSubmissionService"
import Button from "@/components/inputs/Button.vue"
import SwitchCustom from "@/components/inputs/SwitchCustom.vue"
import SecurityModule from "@/store/modules/SecurityModule"

@Component({
  components: {
    Button,
    SwitchCustom
  }
})
export default class OverviewTableTemplateSubmissionLine extends Vue {
  @Prop() templateSubmission!: TemplateSubmission

  async setEmailNotification(): Promise<void> {
    if (this.templateSubmission.isEmailNotificationEnabled !== undefined) {
      await TemplateSubmissionService.setEmailNotification(this.templateSubmission)
    }
  }

  async openEnketoEditLink(): Promise<void> {
    const link = await TemplateSubmissionService.getEditLink(this.templateSubmission)
    if (typeof link === "string") {
      try {
        const url = new URL(link)
        window.open(url.toString(), "_blank")
      } catch {
        console.error(`Couldn't parse the url: ${link}`)
      }
    }
  }

  get isFocalPoint(): boolean {
    return SecurityModule.isFocalPoint
  }
}
