




import { Component, Emit, Prop, Vue } from "vue-property-decorator"
import ConfirmDialog from "@/components/elements/dialog/ConfirmDialog.vue"

@Component({
  components: { ConfirmDialog }
})
export default class ConfirmDeletionDialog extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean

  @Emit()
  remove(): void {
    console.log("removal")
  }
}
