var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPublic',{staticClass:"LoginPlatformView",attrs:{"title":_vm.$t('login.title'),"subtitle":_vm.$t('login.platform.title')}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
var validated = ref.validated;
return [_c('v-form',{attrs:{"id":"form-login"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-row',{staticClass:"LoginPlatformView__row"},[_c('v-col',{attrs:{"cols":"12 pb-0 pt-0"}},[_c('div',{staticClass:"LoginPlatformView__fieldLabel"},[_vm._v(_vm._s(_vm.$t("login.platform.email")))]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('TextFieldLogin',{attrs:{"error-messages":errors,"success":valid,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12 pb-0 pt-0"}},[_c('div',{staticClass:"LoginPlatformView__fieldLabel"},[_vm._v(_vm._s(_vm.$t("login.platform.password")))]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required","mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('TextFieldLogin',{attrs:{"id":"password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? '$mdiEye' : '$mdiEyeOff',"error-messages":errors,"success":valid,"name":"password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12 pb-0 pt-0"}},[_c('Button',{attrs:{"large":"","rounded":"","color":"navy primaryText--text","id":"submitButton","disabled":invalid || !validated,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("login.platform.button"))+" ")])],1)],1)],1)]}}])}),(_vm.showError)?_c('div',[_c('v-alert',{staticClass:"mt-4",attrs:{"color":"red lighten-2","dismissible":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }