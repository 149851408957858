






import { Component, Vue } from "vue-property-decorator"
import LayoutToolbar from "@/components/layouts/LayoutToolbar.vue"

@Component({
  components: { LayoutToolbar }
})
export default class DataAnalysisTitle extends Vue {}
